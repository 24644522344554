@keyframes hideNav {
	from {
		opacity: 1;
	}
	to {
		opacity: 0.1;
	}
}

@keyframes appearNav {
	from {
		opacity: 0.1;
	}
	to {
		opacity: 1;
	}
}

@keyframes appear {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes disappear {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes taller {
	from {
		max-height: auto;
	}
	to {
		max-height: auto;
	}
}

@keyframes shorter {
	from {
		height: auto;
	}
	to {
		height: auto;
	}
}
