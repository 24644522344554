//
//	Home component stylesheet
//
.c-f-o-dropdown-btn {
	min-width: 4.5rem;
	background: none;
	color: $dark-gray;
	padding: 2px 6px;
	font-size: 1.4rem;
	font-weight: 400;
	border: none;
	// cursor: pointer;
	display: flex;
	justify-content: space-between;
}

.c-f-o-dropdown-btn:after {
	padding-left: 10px;
}

/* The container <div> - needed to position the dropdown content */
.c-f-o-dropdown {
	position: relative;
	display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.c-f-o-dropdown-content {
	display: none;
	position: absolute;
	z-index: 8;
	background: #f9f9f9;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

/* Links inside the dropdown */
.c-f-o-dropdown-content a {
	cursor: pointer;
	font-size: 1.2rem;
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

/* Change color of dropdown links on hover */
// .c-f-o-dropdown-content a:hover {
// 	background: #f1f1f1;
// }

/* Show the dropdown menu on hover */
.c-f-o-dropdown:hover .c-f-o-dropdown-content {
	display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
// .c-f-o-dropdown:hover .c-f-o-dropdown-btn {
// 	background: #f1f1f1;
// }

.shortcut {
	@include container-default($flex-direction: column, $align-items: center);
	padding: 2rem;
	&__title {
		margin-bottom: 2rem;
		font-size: 1.8rem;
	}
	&__description {
		margin-bottom: 3rem;
	}
	&__button {
		@include container-default(
			$flex-direction: row,
			$justify-content: space-evenly
		);
		width: 100%;
		margin-bottom: 1rem;
		&__item {
			@include container-default(
				$flex-direction: row,
				$justify-content: space-between
			);
			padding: 1.5rem;
			border-radius: 12px;
			background-color: #c2d6ff;
			min-width: 15rem;
			cursor: pointer;
			&--icon {
				height: 2.5rem;
				> * {
					height: 100%;
					font-size: 2.5rem;
					color: $blue;
				}
				margin-right: 2rem;
			}
			&--text {
				margin-right: auto;
				&.selected {
					color: $white;
				}
			}
			&.selected {
				@include selected-item();
			}
			&.disabled {
				cursor: not-allowed;
				opacity: 0.4;
			}
		}
	}
}

.dashboard-custom-modal {
	@include container-default();
	&__text {
	}
	&__button {
		@include container-default($flex-direction: row);
	}
}
.dashboard-page {
	font-family: $lato;
	overflow: auto;
	background-color: $background-gray;
	width: 100%;
	height: 100%;
	padding: $padding-small;

	&__header {
		@include container-default(
			$flex-direction: row,
			$justify-content: flex-start,
			$align-items: stretch
		);
		min-height: calc(15 * #{$normalize-size} + 12vh);
		max-height: calc(15 * #{$normalize-size} + 15vh);
		@include breakpoint(desktop-large) {
			max-height: calc(15 * #{$normalize-size} + 24vh);
		}
		@include breakpoint(desktop) {
			min-height: calc(15 * #{$normalize-size} + 12vh);
			max-height: calc(15 * #{$normalize-size} + 15vh);
		}
		border-radius: $small-border;

		&-empty-chart {
			min-width: 100%;
			&.normal-chart {
				background-image: url('../../assets/images/chart-example.png');
			}
			&.pie-chart {
				background-image: url('../../assets/images/pie-chart-example.png');
			}
			&.tracking-chart {
				background-image: url('../../assets/images/tracking-chart-example.png');
				min-height: 25rem;
				min-width: 90%;
				max-width: 40rem;
				max-height: 25rem;
				background-size: contain;
				background-repeat: no-repeat;
				margin: 0 auto;
			}
			background-size: cover;
			flex: 1;
			max-width: 200px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: $lato;
			cursor: pointer;
			text-align: center;
		}

		&-chart {
			flex-basis: 33%;
			@include box-shadow-light;
			background-color: $white;
			max-width: 350px;
			@include breakpoint(desktop) {
				max-width: 350px;
			}
			@include breakpoint(desktop-large) {
				flex-basis: 60%;
				max-width: 450px;
			}
			@include container-default(
				$flex-direction: column,
				$justify-content: space-around,
				$align-items: center
			);

			padding: 2rem $padding-small;
			margin-right: $padding-small;
			border-radius: $small-border;
			&-head {
				z-index: 1;
				width: 100%;
				padding: $padding-small;
				display: flex;
				justify-content: space-between;
				h5 {
					font-weight: 700;
					font-size: 1.4rem;
				}
			}

			&-container {
				height: 100%;
				padding-bottom: 0;
				background-color: $background-gray;
				border-bottom-left-radius: $small-border;
				border-bottom-right-radius: $small-border;
				display: flex;
				// justify-content: space-between;

				&-empty {
					text-align: center;
					position: absolute;
					right: 0;
					left: 0;
					top: 50%;
				}
			}
		}
		&-tab {
			@include box-shadow-default;
			font-family: $lato;
			padding: $padding-small $padding-medium;
			background-color: $white;
			border-top-left-radius: $small-border;
			border-top-right-radius: $small-border;
			min-width: 15%;
			text-align: center;
			cursor: pointer;
			user-select: none;
			&.selected {
				background-color: $light-primary;
				box-shadow: -0.1rem -0.3rem 0.5rem 0rem rgba(0, 0, 0, 0.1);
				z-index: 2;
			}
			&-container {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				display: flex;
				height: 4rem;
			}
		}
	}
	&__overview-body {
		display: flex;
		flex-direction: row;
		margin-bottom: 2rem;
		max-height: 50vh;
		&__tasks {
			@include container-default();

			background-color: $white;
			// width: 100%;
			&--table {
				max-height: 90%;
				overflow-y: scroll;
				max-height: 50vh;
			}
			&--bottom-text {
				width: 100%;
				text-align: center;
				color: #2f006b;
				font-weight: 700;
				cursor: pointer;
				margin-top: auto;
				padding-top: 1rem;
				border-top: 0.5px solid $light-gray-border;
				font-size: $font-size-medium;
			}
		}
	}
	&__home-body {
		@include container-default($flex-direction: row, $align-items: stretch);
		align-self: center;
		max-width: 120rem;
		width: 100%;
		//max-height: 60rem;
		&__left {
			//flex-basis: 70%;
			@include container-default(
				$flex-direction: column,
				$justify-content: space-between
			);
			&__info-wrapper {
				@include container-default(
					$flex-direction: row,
					$justify-content: flex-start
				);
				width: 100%;
				flex-basis: 70%;
				&__top-task {
					background-color: $dark-blue;
					border-radius: 2rem;
					flex-basis: 60%;
					height: 100%;
					box-shadow: -1rem 1rem #3a4368, -2rem 2rem #8c92a7;
					max-width: 41rem;
					.top-task {
						height: 30rem;
					}
					.top-task-item {
						@include container-default($align-items: normal);
						height: 100%;
						&__info {
							margin-top: 0.5rem;
							@include container-default(
								$flex-direction: row,
								$justify-content: space-between
							);
							.dropdown-with-icon .MuiButtonBase-root span {
								font-size: 1.2rem;
								.MuiButton-iconSizeMedium > *:first-child {
									font-size: 1.6rem;
								}
							}
							&__icon {
								position: relative;
								top: -1.5rem;
								right: 0.4rem;
								background-color: white;
								border-radius: 50%;
								padding: 1rem;
								display: flex;
								&.some {
									padding: 0.2rem;
								}
								img {
									width: 4.5rem;
									height: 4.5rem;
								}
								.some-icon {
									width: 5.3rem;
									height: 5.3rem;
								}
								.MuiSvgIcon-root {
									background-color: white;
									border-radius: 50%;
									font-size: 6rem;
								}
							}
							&__priority {
								background-color: white;
								border-radius: 4px;
								margin-right: auto;
								margin-left: 1rem;
							}
							&__due-date {
								margin-right: 2rem;
								margin-left: auto;
								.dropdown-with-icon .MuiButtonBase-root {
									background-color: #f3e9d1;
								}
							}
							&__assignee {
								.MuiAvatarGroup-root
									.MuiAvatar-root:not(:only-child):first-child {
									position: relative;
									top: -5px;
									left: -5px;
								}
								margin-right: 2rem;
							}
						}
						&__title {
							color: white;
							margin: 0 3rem 2rem 3rem;
							font-size: 2rem;
							font-weight: bold;
							// word-break: break-all;
						}
						&__description {
							flex: 1;
							color: white;
							margin: 0 3rem 0 3rem;
							font-size: 1.8rem;
							font-weight: bold;
							white-space: pre-wrap;
							overflow-y: scroll;
							-ms-overflow-style: none; /* Internet Explorer 10+ */
							scrollbar-width: none; /* Firefox */
							li {
								color: white;
								font-size: 1.4rem;
								word-break: break-word;
								margin-bottom: 1rem;
							}
						}
						&__description::-webkit-scrollbar {
							display: none; /* Chrome */
						}
					}
				}
				.top-task-button {
					display: flex;
					height: 100%;
					justify-content: space-between;
					flex-direction: column;
					cursor: pointer;
					.MuiSvgIcon-root {
						font-size: 2.5rem;
						color: white;
						background-color: black;
						border-radius: 50%;
					}
					.top {
						position: relative;
						top: -2rem;
						left: -0.3rem;
						//transform: rotate(180deg);
					}
					.bottom {
						position: relative;
						left: -0.3rem;
						top: 6.5rem;
					}
				}
				&__info {
					flex: 1;
					margin: 1rem 0rem 1rem 2rem;
					flex-basis: 40%;
					height: 100%;
					@include container-default(
						$flex-direction: column,
						$justify-content: space-between,
						$align-items: stretch
					);
					&__reccomendation {
						background-color: white;
						border-radius: 2rem;
						@include container-default($flex-direction: row);
						height: 100%;
						&__text {
							flex-basis: 60%;
							padding: 2rem 0rem 2rem 2rem;
							@include container-default(
								$flex-direction: column,
								$align-items: flex-start,
								$justify-content: space-between
							);
							height: 100%;
							&--content {
								text-wrap: balance;
								font-size: 1.8rem;
							}
						}
						&__img {
							flex-basis: 40%;
							width: 100%;
							height: 100%;
							img {
								width: 100%;
								height: 100%;
								border-radius: 0rem 2rem 2rem 0rem;
							}
						}
					}
					&__performance {
						position: relative;
						top: 2rem;
						@include container-default($flex-direction: row);
						.report__page-body-conversion-box {
							border: 0px;
							border-radius: 2rem;
							background-color: white;
						}
						.report__page-body-conversion-container:first-child {
							margin-right: 0.5rem;
						}
						.report__page-body-conversion-container:last-child {
							margin-left: 0.5rem;
							background-color: $background-gray;
						}
						.report__page-body-conversion-box-numbers-value {
							color: #0062e0;
							font-weight: 500;
							font-size: 3rem;
							line-height: 3rem;
						}
						.report__page-body-conversion-box-numbers-difference {
							color: #34a853;
							font-weight: 500;
							font-size: 2rem;
							line-height: 3rem;
						}
						.report__page-body-conversion-box-percent {
							font-weight: 500;
						}
						.report__page-body-conversion-box-title {
							color: $dark-blue;
							font-size: 1.2rem;
						}
					}
				}
			}
			&__shortcut-wrapper {
				background-color: white;
				border: 1px solid white;
				border-radius: 2rem;
				margin-top: 6rem;
				width: 100%;
				flex-basis: 30%;
			}
		}
		&__right {
			flex-basis: 30%;
		}
	}

	&__body {
		margin-top: $padding-medium-small;
		padding: 4rem;
		display: flex;
		flex-direction: column;
		&-calendar {
			max-width: 120rem;
			align-self: center;
			width: 100%;
			.calendar {
				padding: 0rem;
				margin-top: 3rem;
				height: 100%;
				.rbc-month-row {
					display: inline-table !important;
					flex: 0 0 0 !important;
					min-height: 15rem !important;
				}
			}
		}
		&-table {
			// MUI style moved from dashboard-page__body to dashboard-page__body-table
			// to avoid targeting unnecessary component
			.MuiTable-root {
				display: flex;
				flex-direction: column;
			}
			.MuiTableCell-head span p {
				font-size: $font-size-base;
				font-family: $lato;
				text-transform: capitalize;
				margin-bottom: $padding-tiny;
				font-weight: $font-bold;
			}
			.MuiTableCell-root {
				font-family: $lato;
				border-bottom: none;
				line-break: anywhere;
			}
			.MuiTableCell-body {
				font-size: $font-size-base;
				font-family: $lato;
			}
			.MuiTableSortLabel-root {
				flex-direction: row;
				align-items: flex-end;
				margin: 0 auto;
				p {
					margin-right: $padding-tiny;
				}
			}
			.MuiTableRow-root {
				display: flex;
				.MuiTableCell-root {
					font-family: $lato;
					flex: 1;
				}
				.MuiTableCell-head {
					flex: 1;
					font-size: $small-font-size;
					font-family: $lato;
					font-weight: 600;
					color: #5d5d5d;
					position: relative;
					display: flex;
					align-items: center;
				}
			}
			.MuiTableSortLabel-icon {
				font-size: $font-size-small;
				position: absolute;
				left: -1.75rem;
				top: 50%;
				transform: translateY(-50%);
			}
			&.google-analytics,
			&.google-search {
				.MuiTableRow-root {
					.MuiTableCell-root:first-child {
						flex: 5;
						.MuiTableSortLabel-root {
							margin: 0;
							margin-right: auto;
						}
					}
				}
			}
			&--google-ads {
				.MuiTableRow-root {
					.MuiTableCell-root:first-child {
						flex: 4;
					}
					.MuiTableCell-root:nth-child(2) {
						flex: 1.5;
					}
				}
			}
			&-heading {
				margin-top: $padding-medium;
				margin-bottom: $padding-medium-small;
				cursor: pointer;
			}
		}
		.MuiTableBody-root {
			.MuiTableRow-root {
				&:nth-child(odd) {
					background-color: $background-gray;
				}
			}
		}
		&-table--overview {
			position: relative;
			border: $border-size $light-gray solid;
			border-radius: $small-border;

			.dashboard-page__overview-table {
				&-tab {
					font-family: $lato;
					font-size: $font-size-base;
					cursor: pointer;
					margin-right: $padding-medium-small;
					padding: 0 $padding-small;
					text-align: center;
					position: relative;

					&-container {
						padding: $padding-medium-small;
						display: flex;

						.button-container {
							padding: $padding-tiny;
						}
					}
					&.selected {
						&::before {
							position: absolute;
							height: $border-size;
							width: 100%;
							content: '';
							bottom: -$padding-smaller;
							left: 0;
							background-color: $primary;
						}
					}
				}
				&-datasource-filter {
					&-container {
						animation: appear $appear;
						opacity: 1;
						display: flex;
						position: absolute;
						padding: $padding-medium-small;
						right: 0;
						top: 0;
						line-height: $font-size-base;
					}
					.checkbox-label {
						font-family: $lato;
						font-size: $font-size-base;
						cursor: pointer;
					}
					.MuiCheckbox-root {
						padding: $padding-tiny;
					}
					&.checkbox__container {
						margin-left: unset;
						&:not(:last-child) {
							margin-right: $padding-medium-small;
						}
						.MuiSvgIcon-root {
							width: 1.5rem;
							height: 1.5rem;
						}
					}
				}

				&-suggestion {
					cursor: pointer;
					border: $border-size $light-gray solid;
					border-radius: $small-border;
					margin: $padding-smaller $padding-medium-small;
					display: flex;
					&-no-suggestions {
						padding: $padding-medium-small;
						text-align: center;
					}
					.suggestion-icon {
						height: $icon-small;
						width: $icon-small;
						color: $blue;
						margin: $padding-small;
						font-size: $icon-small;
					}
					&-estimation p {
						font-family: $lato;
						font-size: $small-font-size;
					}
					&-estimation,
					&-action {
						padding: $padding-medium-small;
						font-family: $lato;
					}
					&-estimation,
					&-action {
						flex-basis: $suggestion-estimate-width;
						background-color: rgba($primary, 0.15);
					}
					&-estimation {
						text-align: center;
						font-size: $font-size-base;
						border-top-left-radius: $small-border;
						border-bottom-left-radius: $small-border;
					}
					&-action {
						border-top-right-radius: $small-border;
						border-bottom-right-radius: $small-border;
						.button-container {
							margin: $padding-smaller 0;
						}
						.button-small {
							background-color: rgba($primary, 0.8);
							&.button-warning {
								background-color: rgba($red, 0.3);
							}
						}
						.button-text {
							padding: $padding-smaller;
							font-size: $font-size-small;
						}
					}
					.viewing {
						animation: taller $taller;
						height: auto;
					}
					&-information {
						animation: appear $appear;
						animation: shorter $shorter;
						flex: 1;
						display: flex;
						&-benefit-detail {
							font-family: $lato;
							font-size: $font-size-base;
						}
						&-description,
						&-benefit {
							animation: appear $appear;
							font-family: $lato;
							flex: 1;
							font-size: $font-size-base;
							margin: $padding-medium-small;

							&-header {
								margin-bottom: $padding-tiny;
								display: flex;
								justify-content: space-between;
								&-title,
								&-time {
									font-size: $font-size-base;
									color: $placeholder-gray;
									font-weight: $font-light;
									margin: 0;
								}
							}
						}
					}
				}
				&-suggestion:hover {
					background-color: $highlight;
				}
			}
			.dashboard-page__overview-table-tab-container div {
				opacity: 1;
				animation: appear $appear;
			}
		}
	}
	&__suggestion-detail {
		&-group-container {
			display: flex;
		}
		&-group {
			flex: 1;
			margin-bottom: $padding-small;
			p {
				margin-bottom: $padding-tiny;
			}
			h6 {
				margin: $padding-small 0 $padding-tiny;
				color: $primary;
				text-align: center;
			}
			&-values {
				display: flex;
			}
			&-values li {
				padding: $padding-small;
			}
			&-detail p {
				text-align: center;
			}
			&-detail ul {
				text-align: center;
				display: flex;
				flex-flow: wrap;
			}
			&-detail li {
				padding: $padding-small;
				display: block;
			}
			&-detail div {
				padding: $padding-medium;
			}
		}
	}
}
