.chat-stream {
	display: flex;
	flex-direction: row;
	background-color: white;
	border-radius: 2rem;
	height: 100%;
	margin-left: 2rem;
	&__system-input {
		flex-basis: 25%;
		width: 100%;
		&--text {
			height: 100%;
			width: 90%;
			resize: vertical;
			max-height: 40rem;
		}
	}
	&__wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
		position: relative;
		&__chat-list {
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			scrollbar-width: none; /* Firefox */
			overflow-y: scroll;
			flex: 1;
			margin-top: 2rem;
			&--item {
				@include container-default($flex-direction: row);
				&.user {
					justify-content: flex-end;
					p {
						color: white;
					}
				}
				&.assistant {
					justify-content: flex-start;
				}
				&--content {
					max-width: 80%;
					margin: 0rem 2rem 1.5rem 2rem;
					white-space: pre-line;
					border-radius: 2rem;
					padding: 1rem;
					&.user {
						background-color: #4e7fe6;
					}
					&.assistant {
						background-color: $light-white-gray;
					}
					p {
						margin-bottom: 0rem;
					}
				}
			}
			&--item:first-child {
				margin-top: 2rem;
			}
		}
		&__chat-list::before {
			content: '';
			position: absolute;
			top: 0;
			width: 100%;
			height: 3.5rem;
			background: linear-gradient(white, rgba(255, 255, 255, 0.001));
			border-radius: 2rem;
		}
		&__chat-list::-webkit-scrollbar {
			display: none; /* Chrome */
		}
		&__input {
			.input__container {
				width: 94%;
				.input__row {
					border: 0.25rem solid rgba(0, 25, 116, 0.19);
					border-radius: 1rem;
					input {
						border-radius: 1rem;
						margin-right: 2.5rem;
					}
				}
			}
		}
		&__redirect-text {
			text-align: center;
			text-decoration: underline;
			cursor: pointer;
		}
		&__refresh-button {
			margin-bottom: 2rem;
			display: flex;
			justify-content: center;
		}
	}
}
