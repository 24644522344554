.custom-report-data-connector {
	&__style-switch {
		@include container-default(
			$flex-direction: row,
			$justify-content: space-between,
			$align-items: center
		);
		&__item {
			background-color: $light-gray;
			cursor: pointer;
			flex-basis: 50%;
			padding: 1rem;
			text-align: center;
			&.border-left {
				border-left: 1px solid $divider-color;
			}
			&.selected {
				background-color: $white;
			}
			p {
				margin-bottom: 0;
			}
		}
		&__divider {
			@include divider();
		}
	}
	&__selector {
		margin: 1rem;
		&--item {
			border: 1px solid black;
			padding: 0.5rem;
			margin-bottom: 1rem;
		}
		&__selected-items {
			@include container-default(
				$flex-direction: row,
				$justify-content: flex-start
			);
			flex-wrap: wrap;
		}
		&--chip {
			margin: 0 1rem 1rem 0;
			max-width: 100%;
		}
		.input__container {
			width: 90%;
		}
	}
	&__date-pickers {
		padding: 1rem;

		.ColorPicker-MuiInputBase-root {
			font-size: 1.6rem;
		}
		.MuiFormLabel-root {
			font-size: 1.4rem;
		}
		&__period-text {
			font-size: 1.2rem;
		}
		&__period-selector {
			margin-bottom: 1rem;
		}
		&__comparison-button {
			margin: 1rem 0;
			@include container-default(
				$flex-direction: row,
				$justify-content: space-between
			);
			h5 {
				font-size: 1.6rem;
			}
		}
	}
	&__divider {
		@include divider($width: '100%');
	}
	&__styles-selector {
		@include container-default($align-items: flex-start);
		padding: 1rem;
		h3 {
			margin-bottom: 2rem;
		}
		.input__container {
			width: 80%;
		}
		.ColorPicker-MuiInputBase-input {
			font-size: 1.3rem;
			padding-top: 1rem;
		}
		&__item {
			margin: 1rem 0;
			&__layout {
				margin: 1rem;
				&-selection {
				}
				&-custom {
					width: 90%;
					@include container-default(
						$flex-direction: row,
						$justify-content: space-between
					);
					.button-container {
						margin: 0rem;
					}
					&__input {
						.input__container {
							width: 100%;
							margin: 1rem 0rem;
							.input__row input {
								font-size: 1.2rem;
							}
						}
						max-width: 7rem;
					}
				}
			}
		}
	}
}
