//
// Variables
//
// Font size
$header-font-size: 2rem;
$big-font-size: 1.8rem;
$normal-font-size: 1.6rem;
$small-font-size: 1.4rem;

// Colors
$red: red;
$gray: gray;
$black: black;

$report-primary-color: var(--report-primary-color);
$report-secondary-color: var(--report-secondary-color);
