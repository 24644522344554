.keyword-planner-modal {
	&__close-button {
		max-width: 20rem;
	}
	&--container {
		width: 80vw;
		justify-content: center;
		overflow-y: scroll;
		&.content-creator {
			width: 60vw;
		}
	}
	&--content-container {
		&--chart-title {
			@extend .report__page-body-chart-group-title;
			align-self: flex-start;
			margin-left: 5%;
		}
		&--chart-group {
			width: 95%;
			// padding: 0 5%;
			// @extend .report__page-body-chart-group-subtitle;
		}
		@extend .ads-modal__content--container__campaigns--items__container;
		margin-top: 0;
		justify-content: center;
		align-items: center;

		&--title {
			align-self: flex-start;
			margin-left: 2.5%;
			p {
				font-weight: bold;
				font-size: 1.6rem;
			}
		}
		&__details-container {
			@include container-default(
				$flex-direction: row,
				$justify-content: space-around,
				$align-items: center
			);
			width: 100%;
			&--content {
				width: 45%;
				margin: 1rem 0;
				border: 2px solid $light-gray-border;
				&--title {
					margin: 1.5rem;
					p {
						font-weight: bold;
						color: $black-blue;
					}
				}
				&--item-container {
					@include container-default(
						$flex-direction: row,
						$align-items: center,
						$justify-content: space-between
					);
					width: 95%;
					margin: 1rem 0 1rem 1.5rem;
					padding-bottom: 0.5rem;
					border-bottom: 1px solid $divider-color;
				}
				&--item {
					font-size: 1.2rem;
					font-weight: 500;
					margin-bottom: 0;
				}

				&--item-container:last-child {
					border-bottom: none;
				}
			}
		}
		&__content-idea {
			@include container-default($align-items: flex-start);
			width: 100%;
			//max-width: 620px;
			margin-right: auto;
			&--button {
				margin-left: 2rem;
				align-self: flex-start;
				margin-top: 2rem;
				margin-bottom: 3rem;
				&.copy {
					margin-bottom: 0;
				}
			}
			&--content {
				width: 95%;
				border: 2px solid $light-gray-border;
				padding: 2rem;
				margin-bottom: 4rem;
			}
		}
	}
}

.keyword-planner-page {
	height: 100%;
	width: 100%;

	&__content {
		@include container-default($flex-direction: column);
		background-color: $background-gray;
		min-height: 85vh;
		max-height: 90vh;
		overflow-y: scroll;
		&--container {
			@include box-shadow-light();
			margin: 2rem 1.5rem;
			padding: $padding-medium $padding-medium-small;
			height: 100%;
			width: 95%;
			background-color: $white;
			margin-bottom: auto;
			border-radius: $border-size;
			overflow-y: scroll;

			&--top-title {
				@include container-default(
					$flex-direction: row,
					$justify-content: space-between
				);
				&--toggle-button {
					@include container-default(
						$flex-direction: row,
						$justify-content: space-between
					);
					h5 {
						margin-right: 1rem;
					}
				}
			}

			&--search {
				@include container-default(
					$flex-direction: row,
					$justify-content: space-between
				);
				flex-wrap: wrap;
				&--search-button {
					align-self: flex-end;
					.button-text {
						font-size: $small-font-size;
					}
					.button-blue {
						font-size: $small-font-size;
						width: 100px;
						background-color: $turqoise;
					}
				}
				&--search-input {
					align-self: flex-start;
					justify-self: flex-end;
					width: 35%;
				}
			}
			&__country-selection {
				@include container-default(
					$flex-direction: row,
					$justify-content: flex-start,
					$align-items: flex-start
				);
				margin: $padding-medium 0 $padding-small;
				// flex-wrap: wrap;
				// max-width: 70%;
				&--container {
					@include container-default(
						$flex-direction: row,
						$align-items: flex-end
					);
					flex-basis: 35%;
				}
				&--item {
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					width: 100%;
					margin: 1rem 0;
					> p {
						font-size: $small-font-size;
					}
					&--buttons {
						@include container-default(
							$flex-direction: row,
							$justify-content: space-between
						);
						width: 100%;
						.button-text {
							font-size: $small-font-size;
						}
						.button-blue {
							width: 160px;
							background-color: $turqoise;
						}
						.button-container {
							margin: 1rem;
						}
					}
				}
			}
			&__table {
				&--item-icon {
					color: $dark-blue;
				}
				.custom-table {
					&__headers {
						&.first {
							flex-basis: 60px;
							color: $dark-blue;
						}
						:nth-child(2) {
							flex-basis: 25%;
							margin-left: 3rem;
							margin-right: 1rem;
						}
						:nth-child(3) {
							flex-basis: 15%;
						}
						:nth-child(4) {
							flex-basis: 10%;
						}
						:nth-child(5) {
							flex-basis: 10%;
						}
						:last-child {
							flex-basis: 20%;
							text-align: center;
						}
					}
					&__keyword-table {
						&__row {
							&--expanded {
								:nth-child(even) {
									background-color: $background-gray;
								}
								&--date {
									flex-basis: 5%;
									margin-left: 0;
								}
								&--container {
									&.first {
										flex-basis: 60px;
									}

									width: 100%;
									&--divider {
										margin-left: auto;
										width: 85%;
										color: #034079;
									}
								}
								&--items {
									margin-left: 2rem;
									:nth-child(even) {
										background-color: inherit;
									}
									p {
										font-size: 1.4rem;
										margin-bottom: 0.25rem;
									}
									margin-top: 0.5rem;
									align-items: flex-start;

									:nth-child(3) {
										flex-basis: 15%;
									}
									:nth-child(4) {
										flex-basis: 10%;
									}
									:nth-child(5) {
										flex-basis: 10%;
									}
									&.last {
										flex-basis: 25%;
										text-align: center;
										font-size: 1.6rem;
										margin-right: 2rem;
										margin-left: 5rem;
										:nth-child(even) {
											color: $dark-blue;
										}
									}
									&--item {
										margin-left: 10rem;
										&.first {
											flex-basis: 60px;
											margin-left: 1rem;
										}
										&.second {
											flex-basis: 25%;
											font-size: 1.4rem;
											margin-left: 3rem;
											margin-right: 4rem;
										}
										:nth-child(3) {
											flex-basis: 15%;
										}
										:nth-child(4) {
											flex-basis: 10%;
										}
										:nth-child(5) {
											flex-basis: 10%;
										}
										&.last {
											flex-basis: 30%;
											text-align: center;
											font-size: 1.6rem;
											margin-right: 2rem;
											margin-left: 3rem;
											:nth-child(even) {
												color: $dark-blue;
											}
										}
										&--title {
											padding-bottom: 1rem;
											font-weight: lighter;
										}
										&--term {
											text-align: left;
										}
										&--term:last-child {
											padding-bottom: 0.5rem;
										}
										&--keywords {
											@include container-default();
											&[aria-expanded='false'] {
												// max-height: 0px;
												transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
											}
											&[aria-expanded='true'] {
												max-height: 400px;
												transition: max-height 0.5s cubic-bezier(1, 0, 1, 0);
												overflow: scroll;
											}
											p {
												font-size: 1.1rem;
												margin-bottom: 0;
											}
										}
									}
								}
							}
							&--headers {
								&.first {
									flex-basis: 60px;
								}
								&.second {
									flex-basis: 25%;
									font-size: 1.6rem;
									font-weight: 500;
									margin-left: 3rem;
									margin-right: 1rem;
									color: $dark-blue;
								}
								&.second {
									flex-basis: 25%;
									color: $dark-blue;
									font-size: 1.7rem;
									margin-left: 3rem;
									margin-right: 1rem;
								}
								:nth-child(3) {
									flex-basis: 15%;
								}
								:nth-child(4) {
									flex-basis: 10%;
								}
								:nth-child(5) {
									flex-basis: 10%;
								}
								&.last {
									flex-basis: 30%;
									text-align: center;
								}

								&--item {
									&.first {
										flex-basis: 5%;
										margin-left: 0;
									}
									&.second {
										flex-basis: 25%;
										font-size: 1.6rem;
										font-weight: 500;
										margin-left: 3rem;
										margin-right: 1rem;
										color: $dark-blue;
									}
									&.last {
										flex-basis: 20%;
										text-align: center;
									}
								}
							}
						}
					}
				}
			}
			&__page-explorer-table {
				&--item-icon {
					color: $dark-blue;
				}
				.custom-table {
					&__headers {
						&--item {
							min-width: 5%;
							max-width: 10%;
							margin-left: 0rem;
							&.first {
								max-width: 60px;
								margin-left: 0;
							}
						}

						:nth-child(2) {
							min-width: 20%;
							margin-left: 3rem;
							margin-right: 1rem;
						}
						:nth-child(3) {
							min-width: 3%;
							max-width: 5%;
						}
						:nth-child(4) {
							min-width: 3%;
							max-width: 5%;
						}
						:nth-child(5) {
							min-width: 3%;
							max-width: 5%;
						}
						:last-child {
							min-width: 20%;
							text-align: center;
						}
					}
					&__keyword-table {
						&__row {
							padding: 1rem 0;

							&--divider {
								width: 98%;
							}
							&--headers {
								:nth-child(2) {
									min-width: 20%;
									font-size: 1.6rem;
									font-weight: 500;
									margin-left: 3rem;
									margin-right: 1rem;
									color: $dark-blue;
								}
								:nth-child(3) {
									min-width: 3%;
									max-width: 5%;
								}
								:nth-child(4) {
									min-width: 3%;
									max-width: 5%;
								}
								:nth-child(5) {
									min-width: 3%;
									max-width: 5%;
								}

								&.last {
									flex-basis: 30%;
									text-align: left;
								}

								&--item {
									margin-left: 0rem;
									min-width: 5%;
									max-width: 10%;

									&.first {
										max-width: 60px;
										margin-left: 0rem;
										.MuiCheckbox-root {
											.MuiSvgIcon-root {
												font-size: 2.5rem;
												color: '#21CCFF';
											}
										}
									}

									&.keyword {
										min-width: 20%;
										font-size: 1.6rem;
										font-weight: 500;
										margin-left: 3rem;
										margin-right: 1rem;
										color: $dark-blue;
									}

									&.url {
										min-width: 20%;
										text-align: left;
										text-overflow: ellipsis;
										overflow: hidden;
										position: relative;
										display: inline-block;
										word-break: break-word;
									}
								}
							}
						}
					}
				}
			}
			&--empty-text {
				min-height: 100%;
			}
		}
	}
}
