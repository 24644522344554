.customized-report-added-components {
	@include container-default();
	@include box-shadow-default();
	max-width: 20rem;
	background-color: $white;
	padding: 2rem;
	&__title {
		margin-bottom: 2rem;
	}
	&__item {
		@include box-shadow-default();
		margin-bottom: 1rem;
		padding: 1rem;
		align-self: stretch;
		cursor: pointer;
		h4 {
			font-size: 1.6rem;
		}
		img {
			max-width: 100%;
		}
		&.selected {
			background-color: $light-gray;
		}
	}
}
