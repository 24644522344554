.rank-prediction-detail {
	@extend .suggestion-detail-modal;
	@include container-default();

	&__holder {
		max-width: 90vw;
		@include container-default(
			$flex-direction: column,
			$align-items: center,
			$justify-content: flex-start
		);
		background-color: $white;
		padding: 2rem 4rem 0;
		border-radius: $small-border;
		max-height: 70%;
		overflow-x: scroll;
	}
	&__container {
		@include container-default($flex-direction: row);

		&__details {
			max-height: 100%;
			margin-right: 2rem;
		}
		&__chart {
			flex-basis: 50%;
			align-self: flex-start;
			&__effectiveness-graph {
				@include container-default($flex-direction: column);
				&--header {
					@include container-default(
						$flex-direction: row,
						$justify-content: space-between
					);
					width: 100%;
					padding: 2rem;
					&__selector {
						min-width: 20rem;
					}
				}
				&--content {
					padding: 2rem;
					width: 100%;
					.recharts-wrapper .recharts-cartesian-grid-vertical line {
						stroke-dasharray: 0 0;
					}
					.recharts-wrapper
						.recharts-cartesian-grid-vertical
						line:nth-last-child(-n + 2) {
						stroke-opacity: 0;
					}
					.recharts-wrapper
						.recharts-cartesian-grid-horizontal
						line:first-child {
						stroke-opacity: 0;
					}
				}
			}
		}
		&__optimization {
			margin-left: 1rem;
			margin-top: -1rem;
			padding: 2rem;
			align-self: flex-start;
			max-width: 1000px;
			box-shadow: 4px 5px 11px 0px rgba(0, 0, 0, 0.29);
			border-radius: 0 5px 5px 5px;
		}
		&__item {
			&.shadow {
				@include box-shadow-default();
				margin: 1rem;
			}
			&.comparison {
				background-color: $light-gray-border;
			}
			&.chart {
				min-width: 400px;
				min-height: 200px;
			}
			&.padding {
				padding: 1rem;
			}
			&--section {
				padding: 1rem;
				border-bottom: 1px solid $divider-color;
				cursor: pointer;
				&__title {
					font-size: 1.6rem;
					color: $dark-blue;
				}
				&__description {
					color: $blue-gray;
					font-size: 1.2rem;
					font-weight: 400;
				}

				&.top-20 {
					@include container-default($align-items: flex-start);
					cursor: unset;
				}
				&.improve {
					background-color: $light-gray-border;
					border-bottom: none;
				}
			}
			background-color: $white;

			border-radius: $small-border;
			&__comparison-item {
				background-color: $light-gray-border;

				@include container-default(
					$flex-direction: row,
					$justify-content: space-between
				);
				p {
					color: $blue-gray;
					font-weight: 400;
				}
				&__text {
					flex-basis: 50%;
				}
				&__value {
					@include box-shadow-default();
					flex-basis: 20%;
					background-color: $white;
					border-radius: $small-border;
					padding: 0.5rem 1rem;
					font-size: 1.2rem;
				}
			}
			&__domain-item {
				width: 100%;
				&__header {
					@include container-default(
						$flex-direction: row,
						$align-items: center,
						$justify-content: space-between
					);
					@include divider($width: 100%);
					&.open {
						border-bottom: none;
					}
					&__title {
						flex-basis: 90%;
					}
				}
				&__item {
					width: 100%;
					min-width: 50%;
					p {
						margin: 0.5rem 0;
					}
					@include divider();
					@include container-default(
						$flex-direction: row,
						$align-items: center,
						$justify-content: space-between
					);
					&__key {
						font-size: 1.4rem;
						color: $dark-black-grey;
					}
					&__value {
						font-weight: bold;
						font-size: 1.2rem;
						color: $dark-blue;
						text-align: right;
						word-break: break-word;
					}
					&__details {
						h2 {
							font-size: 2rem;
							margin-bottom: 1.5rem;
						}
						&:last-child {
							@include divider();
						}
					}
				}
			}
		}
	}
}
