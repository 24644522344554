.suggestion-generator {
	padding: 1rem;
	&__generate-tasks {
		@include container-default(
			$flex-direction: row,
			$justify-content: flex-start,
			$align-items: center
		);
		p {
			margin-bottom: 0;
			font-size: 1.6rem;
			font-weight: bold;
		}
		padding: 1.5rem 1rem 1.5rem 10%;
		border-bottom: 0.5px solid $light-gray-border;
		&--status {
			min-width: 1rem;
			width: 1rem;
			height: 1rem;
			background-color: $light-green;
			margin-right: 2rem;
			border-radius: 50px;
			&.red {
				background-color: $red;
			}
			&.yellow {
				background-color: $light-orange;
			}
		}
		&--time {
			flex-basis: 40%;
			margin-left: 3rem;
		}
		&--type,
		&--status-value {
			margin-right: 3rem;
			font-weight: 500;
			width: 5rem;
		}
		&--status-value {
			color: $light-green;
			&.gray {
				color: $gray;
			}
			&.orange {
				color: $light-orange;
				cursor: pointer;
			}
		}
		&--info-text {
			margin: 3rem 0;
		}
	}
}
