.custom-menu-container {
	@include container-default();
	:hover {
		text-decoration: none;
		background-color: $blue-gray;
		border-radius: $small-border;
		p {
			color: $white;
		}
		.MuiSvgIcon-root {
			color: $white !important;
		}
	}
	&__item {
		@include container-default(
			$flex-direction: row,
			$justify-content: flex-start
		);
		flex: 1;
		width: 100%;
		height: 40px;
		:first-child {
			width: 10%;
			margin-right: 1rem;
			:hover {
				color: #3b5eda;
			}
		}
		:nth-child(2) {
			width: 60%;
			:hover {
				color: #3b5eda;
			}
		}
		:nth-child(3) {
			@include container-default(
				$justify-content: center,
				$align-items: flex-end
			);
			// margin-left: auto;
			width: 30%;
			font-size: 3rem;
		}
		.MuiSvgIcon-root {
			color: $dark-text !important;
		}
		&--active {
			p {
				color: $white;
			}
			.MuiSvgIcon-root {
				color: $white !important;
			}
		}
		.MuiSvgIcon-root {
			:hover {
				color: $white;
			}
		}
		&--text {
			display: flex;
			width: 40%;
			font-size: 1.6rem;
			color: $dark-text;
			margin: 0;
			text-align: left;
			font-weight: 300;
			&.no-margin {
				margin: 0 2rem 0 -1.2rem;
				width: 80%;
				// margin-right: 2rem;
			}
		}
		&--empty {
			width: 30%;
			margin-left: auto;
			.MuiSvgIcon-root {
				width: 45%;
				font-size: 3rem;
			}
			&.third {
				@include container-default(
					$justify-content: center,
					$align-items: flex-end
				);
			}
			&.small {
				width: 15%;
				margin-left: unset;
			}
		}
	}
	.MuiAvatar-colorDefault {
		width: 30px;
		height: 30px;
	}
}
