//
// Define variables, mixins
//

// Font
// @import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;400;500;700&display=swap');
// MaterialUI Icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Setting
$normalize-size: 1rem;
$default-size: 1.6 * $normalize-size;
$report-page-size: 2 * $default-size;
// Material UI color
$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deep-purple: #673ab7;
$light-purple: #97aff5;
$indigo: #3f51b5;
$blue: #2196f3;
$light-blue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$light-green: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deep-orange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blue-grey: #607d8b;
$background-gray: #f3f7fa;
$background-darker-gray: #edeff4;

$dark-gray: #9d9d9d;
$dark-black-grey: #333132;
$ming-blue: #3d6385;
$green-blue: #9bcec2;
$dark-blue: #182550;
$darker-blue: #202532;
$gray: #9d9d9d;
$lighter-gray: #f7f7f7;
$light-white-gray: #f8f8f8;
$light-gray: #f6f6f6;
$light-gray2: #c0c0c0;
$alto-gray: #d3d3d3;
$dark-alto-gray: #d7d7d7;
$white-gray: #ededed;
$darker-white: #f5f5f5;
$gray-blue: #edf0f9;
$light-gray-border: #d7e1ea;
$light-gray-background: #fbfbfc;
$divider-color: rgba(3, 64, 121, 0.21);

// Color
$modal-opacity: rgba(0, 0, 0, 0.3);
$primary: $dark-blue;
$light-primary: #e0f8ee;
$secondary: #00325a;
$primary-2nd: #15c0bb;
$blue: #3687ff;
$login-bg-color: #e0f8ee;
$blue: #1877f2;
$light-blue: #3687ff;
$medium-blue: #3686ffa1;
$ocean-blue: #61c9fa;
$login-bg-color: linear-gradient(180deg, #182550 0%, #001974 100%);
$blue-gray2: rgba(0, 25, 116, 0.11);
$placeholder-gray: #d5d5d5;
$white: #fff;
$black: #000;
$highlight: #0000000c;
$background-blue: rgba(59, 124, 218, 0.29);
$background-light-blue: #aeceff;
$background-darkerblue: rgba(54, 135, 255, 0.49);
$background-dashboa-blue: #000b4f;
$background-darkblue: #151f43;
$black-blue: #16182b;
$light-gray-border: #e6e8ea;
$light-orange: #efab3c;
$light-green: #008556;
$blue-gray: #3a4469;
$dark-yellow: #bcec4b;
$dark-text: #8c92a7;
$dark-border: #384160;
$default-text-color: $dark-blue;
$icon-gray: #898e9c;
$icon-light-gray: #eff0f7;
$turqoise: #61c9fb;
$gray-border: #e5e4e9;
$light-pink: #e2cacc;
$light-yellow: #fbf1d9;
$lighter-green: #ddecde;
$lighter-blue: rgba(78, 127, 230, 0.2);
$alert-color: #de1c22;
// Font-family
$lato: 'Lato';
$arial: 'Arial';
$lato: $lato, sans-serif;
$poppins: 'Poppins', sans-serif;
$spacemono: 'Space-Mono', monospace;
$font-1: $lato;
$font-2: $poppins;

// Font-size
$font-size-multiplier: 1;
$font-size: $default-size * $font-size-multiplier;
$font-multiplier-base: 0.875;
$font-size-base: $font-size * $font-multiplier-base;
$font-multiplier-large: 1.125;
$font-size-medium: $font-size * 0.625;
$font-size-large: $font-size * $font-multiplier-large;
$font-multiplier-small: 0.6875;
$font-size-small: $font-size * $font-multiplier-small;
$font-multiplier-header: 1.6;
$font-size-header: $font-size * $font-multiplier-header;
$font-multiplier-sub-header: 1.25;
$font-size-sub-header: $font-size * $font-multiplier-sub-header;
$font-size-icon-multiplier: 2.5;
$font-size-icon: $font-size-icon-multiplier * $font-size;
$font-size-huge: 3rem;

// Font-weight
$font-thin: 200;
$font-light: 300;
$font-regular: 400;
$font-normal: 500;
$font-bold: 600;
$font-bolder: 800;

// Icon size
$icon-small: 1.8rem;
$icon-medium: 3.3rem;

// Transition
$transition-default: all 0.4s;

//Animation timings
$appear: 0.5s;
$disappear: 0.5s;
$taller: 0.5s;
$shorter: 0.5s;

// padding,margin
$padding-extra-large: 10 * $normalize-size;
$padding-large: 5 * $normalize-size;
$padding-medium: 3 * $normalize-size;
$padding-medium-large: 2 * $normalize-size;
$padding-medium-small: 1.5 * $normalize-size;
$padding-small: $normalize-size;
$padding-smaller: 0.75 * $normalize-size;
$padding-tiny: 0.25 * $normalize-size;

//border radius
$round-border: 50%;
$medium-border: 10px;
$small-border: 8px;
$tiny-border: 2px;
$tiniest-border: 1px;

//input
$input-height: 4.2 * $normalize-size;
$logo-size: 7 * $normalize-size;
$price-container-height: 9 * $normalize-size;
$border-size: 0.15 * $normalize-size;
$border-size-thick: 0.3 * $normalize-size;

$button-min-width: 16 * $normalize-size;
$small-button-min-width: 10 * $normalize-size;

$min-width-modal: 40vw;
$tutorial-width-modal: 65vw;

$switch-height: 3.73 * $normalize-size;

$suggestion-estimate-width: 15 * $normalize-size;

$report-width: 1024px;
$report-height: $report-width / 210 * 297;
//Stripe
$card-box-shadow: 0 1px 3px 0 #e6ebf1;
$card-transition-box: box-shadow 150ms ease;
$card-transition: box-shadow 150ms ease;
$card-focus: 0 1px 3px 0 #cfd7df;

// letter spacing
$letter-space-min: 0.05rem;
$letter-space-medium: 0.125rem;
