.divider {
	background-color: $blue;
	width: 100%;
	height: 0.1 * $default-size;
	margin: $padding-medium-small 0;
}
.error-message {
	&__information-container {
		width: 90%;
		padding: 2rem;
		margin: 2rem auto 1rem;
	}
	&-container {
		position: fixed;
		top: 3rem;
		right: 3rem;
		width: 45rem;
		height: 7rem;
		border-top-left-radius: $medium-border;
		border-bottom-left-radius: $medium-border;
		z-index: 10;
		background-color: $white;
		display: flex;
		@include box-shadow-default;
		.icon {
			background-color: #ff6969;
			border-top-left-radius: $medium-border;
			border-bottom-left-radius: $medium-border;
			flex: 2;
			display: flex;
			justify-content: center;
			align-items: center;
			i {
				color: $white;
			}
		}
		.message {
			flex: 7;
			display: flex;
			justify-content: center;
			flex-direction: column;
			border-right: 1px solid $light-gray-border;
			p {
				margin: 0 0 0 2rem;
				font-family: $lato;
				font-size: 1.2rem;
				overflow: hidden;
			}
			.bold {
				font-weight: bold;
			}
		}
		.button {
			flex: 2;
			display: flex;
			justify-content: center;
			align-items: center;
			.text-button {
				span {
					color: $dark-gray;
					font-size: 1.2rem;
				}
			}
		}
	}
}
.message-container {
	z-index: 10001;
	.MuiSnackbarContent-root {
		background-color: $primary;
		#message-id {
			color: $white;
		}
	}
}

.disable-clicking {
	opacity: 0.5;
	pointer-events: none;

	div {
		opacity: 0.5;
	}
}
