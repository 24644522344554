.score-component {
	background: $primary;
	box-shadow: 7px -7px 10px -5px rgba(59, 94, 218, 0.07),
		-5px 2px 9px 3px rgba(59, 94, 218, 0.05);
	border-radius: $small-border 0 0 $small-border;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: 30%;
	max-width: 240px;
	min-width: 200px;

	&--label {
		color: $white;
		font-size: 1.3rem;
		font-weight: 700;
		align-self: flex-start;
		margin: 1rem 0 0.5rem 1.5rem;
		min-height: 2.5rem;
	}

	&__ring {
		height: 13rem;
		width: 13rem;
		margin: 0 1rem 0.5rem;
		@include breakpoint(desktop-large) {
			height: 14rem;
			width: 14rem;
		}
		&--text {
			font-size: 4rem;
			color: $white;
			&.no-value {
				font-size: 1.6rem;
				margin-top: 4rem;
			}
		}
	}

	&__items {
		@include container-default(
			$flex-direction: column,
			$justify-content: flex-start,
			$align-items: center
		);

		width: 100%;

		margin-top: auto;
		margin-bottom: 1rem;

		p {
			color: $white;
			font-size: 1.3rem;
		}
		h6 {
			margin-bottom: 2rem;
			font-family: $lato;
			font-weight: 700;
			width: 80%;
			color: $white;
			font-size: 1.4rem;
			text-transform: none;
		}

		&--item {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 80%;

			border-bottom: 0.5px solid rgba(255, 255, 255, 0.26);
			margin-bottom: 0.75rem;

			p {
				margin-bottom: 0.25rem;
				font-weight: 300;
			}
			&-number {
				margin-left: auto;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1.2rem;

				background: $blue-gray;
				width: 3rem;
				height: 3rem;
				border-radius: 50px;
				text-align: center;
			}
		}
	}
}
