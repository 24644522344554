.forget-password {
	display: flex;
	flex-direction: column;
	width: 30rem;
	margin: 0 auto;
	.input__container .input__row .input__border {
		padding: 1.5rem;
		font-size: 2rem;
		height: 4.5rem;
	}
	p {
		margin: $padding-medium-small 0;
		color: $white;
	}
	.input__container {
		p {
			text-align: start;
		}
	}
	.button-container button {
		height: 4.5rem;
	}
}
