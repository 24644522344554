.image-slideshow {
	margin: 0;

	/* Position the image container (needed to position the left and right arrows) */
	&__container {
		position: relative;

		&--mySlides {
			margin: 1rem 0;
			background-color: $light-gray-border;
			@include box-shadow-default();
			&-numbertext,
			&-deleteimage {
				color: $white;
				background-color: rgba(0, 0, 0, 0.8);
				font-size: 1rem;
				padding: 0.5rem 0.75rem;
				position: absolute;
				top: 0;
			}
			&-deleteimage {
				right: 0;
			}
			&-deleteimage:hover {
				cursor: pointer;
				text-decoration: underline;
			}
			img {
				width: 100%;
				object-fit: contain;
				max-height: 300px;
			}
		}

		/* Next & previous buttons */
		&--prev,
		&--next {
			cursor: pointer;
			position: absolute;
			top: 40%;
			width: auto;
			padding: 1rem;
			margin-top: -3.125rem;
			color: $white;
			font-weight: bold;
			font-size: 1.5rem;
			border-radius: 0 3px 3px 0;
			user-select: none;
			-webkit-user-select: none;
			background-color: rgba(0, 0, 0, 0.8);
		}
		&--prev:hover {
			text-decoration: underline;
		}
		&--next:hover {
			text-decoration: underline;
		}
		/* Position the "next button" to the right */
		&--next {
			right: 0;
			border-radius: 3px 0 0 3px;
		}

		&--row:after {
			content: '';
			display: table;
			clear: both;
		}
		&__row {
			@include container-default(
				$flex-direction: row,
				$justify-content: flex-start
			);
			/* Ten columns side by side */
			&--column {
				border: 0.5px solid $light-gray-border;
				flex-basis: 25%;
				opacity: 0.5;
				cursor: pointer;
				margin: 0.25rem;

				img {
					width: 100%;
				}
			}

			.active,
			&-demo:hover {
				cursor: default;
				opacity: 1;
			}
		}
	}
}
