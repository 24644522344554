[class*='-colorPrimary'] {
	background-color: $primary;
	//color: $blue !important;
}

[class*='-colorSecondary'] {
	background-color: $secondary;
	color: $secondary !important;
}

.Mui-disabled {
	opacity: 0.5;
	+ span {
		opacity: 0.5;
	}
}

.Mui-checked {
	.MuiSwitch-thumb {
		color: $white;
	}
}
.MuiPopover-root {
	z-index: 200000 !important;
}

.MuiPickersPopper-root {
	position: absolute !important;
	z-index: 12000 !important;
}

.MuiListItemText-root {
	.MuiTypography-root {
		font-size: 15px !important;
	}
}

.MuiDialog-root {
	z-index: 14000 !important;
}

.MuiPickersDatePickerRoot-toolbar {
	background-color: $dark-blue !important;
}

.MuiPickersDay-daySelected {
	color: #fff;
	font-weight: 500;
	background-color: $primary !important;

	.MuiIconButton-label {
		color: $white;
	}
}
.MuiPickersDay-dayDisabled {
	background-color: $gray !important;
}

.MuiDataGrid-panelContent {
	.MuiFormControl-root {
		.MuiFormLabel-root {
			background-color: $white;
			font-size: 1.6rem;
		}
		.MuiInputBase-formControl {
			background-color: $white;
		}
	}
}
