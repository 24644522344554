.modal__content {
	.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiTypography-h4 {
		color: white;
	}
	.MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar.MuiToolbar-gutters {
		background-color: $dark-blue;
	}
	.MuiPickersDatePickerRoot-toolbar {
		align-items: initial;
	}
	.MuiPickersMonth-monthSelected {
		background-color: $white;
	}
	.MuiPickersYear-yearSelected {
		background-color: $white;
	}
	.MuiPickersMonthSelection-container {
		width: auto;
	}
	.MuiPickersBasePicker-pickerView {
		max-width: initial;
	}
	.blue-button {
		button {
			background-color: $blue;
		}
	}
}
