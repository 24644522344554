.text-button {
	span {
		color: $blue;
		font-size: $font-size-base;
		cursor: pointer;
		font-weight: $font-bold;
	}
	&.align-start {
		text-align: start;
	}
	&.align-center {
		text-align: center;
	}
	&.align-end {
		text-align: end;
	}
}
