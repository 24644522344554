.create-project-modal {
	h4 {
		font-family: $lato;
		text-align: center;
		color: $black;
	}
	p {
		text-align: center;
		font-family: $lato;
		padding: 1.5rem;
	}
	.input__container {
		margin: 0;
	}
}
