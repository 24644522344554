.technical-audit-page {
	height: 100%;
	width: 100%;
	&__content {
		height: 100%;
		width: 100%;
		background-color: $background-gray;
		padding: 5rem 5rem;
		max-height: 90vh;
		overflow-y: scroll;
		&--container {
			@include container-default(
				$flex-direction: row,
				$justify-content: space-between
			);

			max-height: 800px;
			flex-wrap: wrap;
		}

		&--checks {
			@include box-shadow-light();
			background-color: $white;
			padding: $padding-medium $padding-medium;
			border-radius: $small-border;
			width: 35%;

			align-self: flex-start;

			h6 {
				margin-bottom: 2rem;
				font-size: 1.4rem;
			}
			#checks--text {
				font-size: 1.4rem;
				color: $dark-blue;
			}
			&__content {
				&--checks {
					display: flex;
					flex-direction: column;
					overflow: scroll;
				}
			}
			&--item {
				@include container-default(
					$flex-direction: row,
					$align-items: center,
					$justify-content: flex-start
				);
				p {
					margin-bottom: 0;
					font-size: 1.2rem;
					color: $dark-blue;
				}
				&.checked-icon {
					color: $green;
				}
				&.unchecked-icon {
					color: $red;
				}

				&.label {
					text-transform: capitalize;
				}
				&.value {
					margin-left: auto;
				}
				&--divider {
					width: 100%;
					color: rgba(3, 64, 121, 0.21);
				}
			}
		}

		&__score {
			@include box-shadow-light();
			max-width: 40%;
			background-color: $white;
			padding: $padding-medium $padding-large;
			border-radius: $small-border;

			height: 100%;

			&--container {
				@include container-default();
				&--label {
					font-weight: 700;
					margin-left: -1.5rem;
					margin-bottom: 2rem;
					font-size: 1.5rem;
					width: 100%;
				}

				&__ring {
					width: 200px;

					.CircularProgressbar .CircularProgressbar-path {
						stroke: url(#auditColors);
					}
				}

				&__item {
					text-align: center;
					margin-top: 2rem;
					width: 100%;
					&--text {
						width: 60%;
						margin: 0 auto;
						padding: $padding-tiny;
						font-weight: 700;
						&.great {
							border: 1.25px solid #0b9c08;
							color: #0b9c08;
							border-radius: $small-border;
						}
						&.good {
							border: 1px solid #0b9c08;
						}
						&.okay {
							border: 1px solid #0b9c08;
						}
						&.bad {
							border: 1px solid #0b9c08;
						}
					}
				}
			}
		}

		&__issues {
			@include container-default(
				$align-items: flex-start,
				$justify-content: space-around
			);
			align-self: flex-start;
			h6 {
				margin-bottom: 3rem;
				font-size: 1.4rem;
			}

			@include box-shadow-light();
			width: 60%;
			height: 100%;
			background-color: $white;
			padding: $padding-medium $padding-medium;
			border-radius: $small-border;

			&__selector {
				@include container-default(
					$justify-content: space-between,
					$align-items: center,
					$flex-direction: row
				);
				width: 100%;
				margin-bottom: 1rem;
				&--select {
					margin-left: auto;
					width: 40%;
				}
			}

			&__items {
				border: 1px solid $light-gray-border;
				&--container {
					@include container-default(
						$align-items: flex-start,
						$justify-content: none
					);
					width: 100%;
					overflow: scroll;
				}
				@include container-default(
					$align-items: flex-start,
					$justify-content: none
				);
				width: 100%;

				border-bottom: 0.5px solid rgba(3, 64, 121, 0.21);
				// &--divider {
				// 	width: 100%;
				// 	color: rgba(3, 64, 121, 0.21);
				// }
				&__item {
					@include container-default(
						$flex-direction: row,
						$justify-content: flex-start
					);
					width: 100%;
					margin-left: 1rem;

					:first-child {
						width: 30%;
					}
					p {
						font-size: 1.2rem;
						margin-top: $padding-smaller;
						padding-bottom: 0;
					}

					&--number {
						font-weight: 700;
						margin-right: 1.5rem;
						width: 5%;
					}

					&--errors {
						margin-right: auto;
						background-color: #af0000;
						padding: 1rem;
						border-radius: $small-border;
						max-width: 50%;
					}
					&--warnings {
						margin-right: auto;
						background-color: #f5dd00;
						padding: 1rem;
						border-radius: $small-border;
						max-width: 50%;
					}
					&--notices {
						margin-right: auto;
						background-color: $turqoise;
						padding: 1rem;
						border-radius: $small-border;
						max-width: 50%;
					}
				}
			}
		}

		&--container__table {
			@include box-shadow-light();
			background-color: $light-gray;
			margin: 2rem 0;
			padding: $padding-medium $padding-medium-small $padding-medium
				$padding-medium;
			background-color: $white;
			margin-bottom: auto;
			border-radius: $border-size;
			.custom-table__headers {
				&.first {
					flex-basis: 10%;
				}
			}
			.custom-table__keyword-table__row--expanded {
				&--items {
					&--item {
						flex-basis: 35%;
					}
					&.first {
						width: 50%;
						margin-left: 1rem;
						font-size: 1.4rem;
					}
					&.second {
						width: 50%;
						margin-left: 1rem;
						font-size: 1.4rem;
						text-align: center;
					}
				}
			}
			.custom-table__keyword-table {
				&__row {
					&--headers {
						&--item {
							&.first {
								flex-basis: 5%;
							}
							&.second {
								margin-left: 0rem;
							}
						}
					}
					&--expanded {
						&.header {
							color: $gray;
							font-weight: 500;
							font-size: 1.5rem;
						}
						&.first {
							flex-basis: 5%;
							margin-left: 5%;
						}

						&--item {
							&.first {
								flex-basis: 50%;
								margin: 0 2.5%;
							}

							&.second {
								flex-basis: 50%;
								align-self: center;
							}
							p {
								margin-left: 2rem;
							}
						}
					}
				}
			}
		}
	}
}
