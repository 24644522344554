.file-upload-button {
	&__container {
		@include button-default;
		max-width: 20rem;
		background-color: $primary;
		padding: $padding-small;
		margin: 2rem;
		&.disabled {
			background-color: $light-gray2;
			cursor: not-allowed;
		}
	}
	&__label {
		@include container-default();
		cursor: pointer;
	}
	&__text {
		color: $white;
		margin-bottom: 0;
	}
	&__input {
		display: none;
	}
}
