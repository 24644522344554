.button-container {
	@include button-container-default;
	&.full-width {
		width: 100%;
	}
	margin: 5%;
	border-radius: 6px;
	button {
		.MuiSvgIcon-root {
			margin: 0.5rem;
		}
		@include button-default;
		&.button-primary {
			background-color: $dark-blue;
		}
		&.button-primary-2nd {
			background-color: $primary-2nd;
		}
		&.button-light-blue {
			background-color: $light-blue;
			font: $poppins;
		}
		&.button-secondary {
			background-color: $secondary;
		}
		&.dark-gray {
			background-color: $blue-gray;
			.button-text {
				color: $white;
			}
		}
		&.gray {
			background-color: $icon-light-gray;
			.button-text {
				color: $dark-blue;
			}
		}
		&.button-clear-blue {
			border: 1px solid $light-blue;
			background-color: $white;
			.button-text {
				color: $light-blue;
				font-family: $lato;
			}
		}
		&.button-white {
			background-color: $white;
			.button-text {
				color: $default-text-color;
			}
		}
		&.button-dark-white {
			background-color: $white;
			border: 2px solid $primary;
			.button-text {
				color: $primary;
				padding: 0.8rem;
			}
		}
		&.button-light-blue {
			background-color: $light-blue;
			border: 2px solid $light-blue;
			.button-text {
				color: $white;
				padding: 0.8rem;
			}
		}
		&.button-blue-white {
			background-color: $medium-blue;
			.button-text {
				color: $white;
				padding: 0.8rem;
			}
		}
		&.button-cancel {
			background-color: $default-text-color;
			.button-text {
				color: $white;
			}
		}
		&.button-alert {
			background-color: $alert-color;
			.button-text {
				color: $white;
			}
		}
		&.button-icon-with-text {
			font-family: $lato;

			background-color: transparent;
			box-shadow: unset;
			padding: unset;

			.button-text,
			.button-text.icon-start,
			.button-text.icon-end {
				font-family: $lato;
				margin: unset;
				padding: unset;
				min-width: unset;
				display: flex;
				align-items: center;
				color: $default-text-color;

				.icon {
					width: $icon-medium;
					height: $icon-medium;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: $default-text-color;

					// box-shadow: 0 0.5rem $padding-small rgba($black, 0.15);

					&.icon-primary {
						background-color: $primary;
					}
					&.icon-secondary {
						background-color: $secondary;
					}
					&.icon-transparent {
						color: $default-text-color;
						background-color: transparent;
						box-shadow: unset;
					}
					&.icon-modal-close {
						color: $icon-gray;
					}
					&:hover {
						font-weight: $font-bold;
					}
				}
			}
		}
		&.button-modal-close {
			background-color: $icon-light-gray;
		}
		&:disabled {
			background-color: $light-gray2;
			color: $white;
			border-color: $light-gray2;
			&.button-icon-with-text {
				background-color: transparent;
				.button-text .icon,
				.button-text.icon-start .icon,
				.button-text.icon-end .icon {
					background: $gray;
					cursor: not-allowed;
				}
			}
		}
	}

	.button-text {
		padding: $padding-small;
		text-align: center;
		flex-direction: row;
		font-family: $lato;
		font-size: $font-size;
		min-width: $button-min-width;
		color: $white;
		&.icon-end:not(.button-icon) {
			margin-right: -$padding-small;
			.icon {
				margin-left: $padding-small;
			}
		}
		&.icon-start:not(.button-icon) {
			//margin-left: -$padding-small;
			.icon {
				margin-right: $padding-small;
			}
		}

		&.button-icon {
			min-width: unset;
			padding: 0.6rem;
			.icon {
			}
		}
	}

	.button-text-with-tool-tip {
		padding: $padding-small;
		text-align: center;
		flex-direction: row;
		font-family: $lato;
		font-size: $font-size;
		min-width: $button-min-width;
		color: $white;
	}

	&.button-small {
		.button-text {
			font-size: $font-size-base;
			min-width: $small-button-min-width;
			padding: 0.5rem 2rem;
		}
	}
}
