.xcloser {
	@include container-default(
		$flex-direction: column,
		$justify-content: center,
		$align-items: center
	);
	float: right;
	background-color: #edf0f9;
	cursor: pointer;
	border-radius: $small-border;
	text-align: center;
	margin: 1rem;
	padding: 0.5rem;
	width: 3.6rem;
	height: 3.6rem;
	i {
		font-size: 2.5rem;
		color: #3686ff;
	}
}
.xcloser:hover {
	i {
		font-weight: 600;
	}
}
