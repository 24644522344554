.tutorial-modal-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: auto;
	flex: 1;
	justify-content: space-between;
	h6 {
		padding: $padding-small;
	}
}

.steps-container {
	width: 80%;
	margin: auto;
	display: flex;
	justify-content: center;
	.step-button {
		margin: $padding-small;
	}

	.step-marker {
		background-color: $grey;
		padding: 0.4rem;
		border-radius: 20px;
		margin: 0.5rem;
	}
	.active {
		color: $white;
		background-color: $blue;
	}
}

.tutorial-image {
	width: 80%;
}
.tutorial-step-buttons-holder {
	margin-top: $padding-small;
	display: flex;
	justify-content: center;
}
.highlight-task-description {
	@include highlightFixed();
}

.highlight-background {
	@include highlightNoScaling();
}
.highlight-ignore-button {
	@include highlight();
}
.highlight-done-button {
	@include highlight();
}
.highlight-tasks-tabs {
	@include highlightFixed();
}
.highlight-tasks-filters {
	@include highlightFixed();
}
.highlight-add-to-do-button {
	position: relative;
	@include highlightNoScaling();
}
.highlight-show-details-button {
	position: relative;
	@include highlightNoScaling();
}
.highlight-project-settings-navigation {
	p {
		color: #3b5eda;
	}
	svg {
		color: #3b5eda !important;
	}
	@include highlight();
}
///
.highlight-ecommerce {
	@include highlight();
}
.highlight-sidenav {
	@include highlight-sidenav();
}

.highlight-tutorial-button,
.highlight-profile-button,
.highlight-project-settings-button,
.highlight-tools-button,
.highlight-technical-audit-button,
.highlight-keyword-ranking-button,
.highlight-subscription-button,
.highlight-keyword-planner-button,
.highlight-ads-visualisation-button,
.highlight-tasks-button,
.highlight-dashboard-button {
	@include highlight-small($background-color: $turqoise);
	margin-left: 2rem;
	max-width: 90%;
}

.highlight-create-project-button {
	@include highlight-small();
	padding: 1rem;
	@include box-shadow-default();
}

.highlight-conversion-value,
.highlight-conversion-rate,
.highlight-country-selection,
.highlight-language-selection,
.highlight-project-url,
.highlight-project-name,
.highlight-estimated-time,
.highlight-get-analytics-goals,
.highlight-notification,
.highlight-delete-project,
.highlight-generate-tasks,
.highlight-datasources {
	@include highlight();
}
.project-tutorial {
	@include container-default(
		$flex-direction: column,
		$justify-content: space-around,
		$align-items: center
	);
	flex: 1;
	&-switch-holder {
		@include container-default($align-items: center, $justify-content: center);
		width: 50%;
	}
	h6 {
		color: $dark-blue;
		font-size: 1.8rem;
	}

	&-holder {
		.show-and-tell-btn {
			font-family: $poppins;
			margin: 0;
			color: #001974;
			font-weight: bold;
		}
		display: flex;
		flex-direction: column;
		margin: 1rem;
		align-items: center;
		width: 80%;
		p {
			white-space: pre-line;
		}

		&--project-url-holder {
			display: flex;
			align-items: flex-end;
			&--paragraph {
				margin-bottom: 1.3rem;
			}
		}
		&.last-step {
			flex: 1;
		}
		&--last-step-button {
			margin-top: auto;
		}

		.tutorial-modal {
			&__country-selection {
				display: flex;
				width: 100%;
				&--item {
					width: 100%;
				}
			}
		}
		.project-tutorial-header {
			color: $black;
			margin: $padding-small;
		}

		.ecommerce-switch-holder {
			display: flex;
			justify-content: center;
			margin: 1.2rem;
		}
	}
}
