.StripeElement {
	box-sizing: border-box;
	width: 100%;
	padding: $padding-small;
	box-shadow: $card-box-shadow;
	-webkit-transition: $card-transition-box;
	transition: $card-transition;
}

.StripeElement--focus {
	box-shadow: $card-focus;
}

.StripeElement--invalid {
	border-color: $red;
}

.StripeElement--webkit-autofill {
	background-color: $red;
}

.setting-page {
	height: 100%;
	position: relative;
	&__left-panel,
	&__right-panel {
		background-color: #e5e5e5;
	}
	.settings-data-sources {
		background: #ffffff;
		box-shadow: 7px -7px 10px -5px rgba(59, 94, 218, 0.07),
			-5px 2px 9px 3px rgba(59, 94, 218, 0.05);
		border-radius: $small-border;
		margin: 1.5rem;
	}
	&__left-panel {
		width: 25%;
		position: fixed;
		top: $padding-medium * 2 + $logo-size;
		left: 0;
		padding: $padding-medium $padding-large;
		height: calc(100% - #{$padding-medium * 2 + $logo-size});
	}
	&__right-panel {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		padding: $padding-medium $padding-large;
		margin-left: auto;

		&-sub-title {
			font-family: $lato;
			font-size: $font-size-sub-header;
			font-weight: $font-bold;
			margin-bottom: $padding-small;
			display: flex;
			align-items: center;
			.fa-pen {
				margin-left: $padding-small;
				font-size: $font-size;
			}
		}
		&-group {
			margin-bottom: $padding-medium;
		}
		&-half-group {
			justify-content: flex-end;
			align-items: center;
			margin: 1rem;
			.input__container {
				padding: $padding-small;
				max-width: 80%;
			}
			display: flex;
			// justify-content: space-between;
			> * {
				max-width: 49%;
			}
			:first-child {
				margin-right: auto;
			}
			&.sticky {
				position: sticky;
				top: 0;
				background-color: $white;
				// opacity: 0.9;
				z-index: 2;
				//margin-top: -2rem;
				margin-bottom: 2rem;
			}
			&__project-url-settings__urls {
				padding: 1rem;
				margin-top: 1rem;
				border: 2px solid $dark-blue;
				border-radius: $small-border;
				&__item {
					margin: 1rem;
				}
			}
			&--align-right {
				margin-left: auto;
				margin: 2% 5%;
			}
			&--goals {
				@include container-default(
					$flex-direction: column,
					$justify-content: space-around,
					$align-items: flex-start
				);
				margin: 1rem;
				.button-container {
					margin: 0rem;
				}
				h5 {
					font-size: 1.6rem;
					color: $dark-blue;
					margin-bottom: 1rem;
				}
				p {
					font-size: 1.4rem;
					margin-bottom: 0.25rem;
				}

				&--list {
					@include container-default(
						$flex-direction: row,
						$align-items: center,
						$justify-content: space-between
					);
					flex-wrap: wrap;
					width: 100%;
					&--item {
						margin-right: 1rem;
						flex-basis: 45%;
					}
				}
			}
			&--inputs {
				@include button-container-default();
				flex-wrap: wrap;
				width: 50%;
				justify-content: flex-start;
				p {
					color: $dark-blue;
					font-size: 1.6rem;
				}
				&-switch {
					justify-self: flex-start;
					margin: 0 auto 0 1rem;
				}
			}
			.box-row {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: flex-start;
				.input__container {
					margin-top: unset;
					padding-top: unset;
					margin-left: 6rem;
					padding-right: 0;
					p {
						margin-top: unset;
						padding-top: unset;
						margin-left: 0;
						span {
							width: 15rem;
						}
					}
				}
			}
			&--buttons {
				@include button-container-default();
				flex-wrap: wrap;
			}
			.setting-page__add-notification-button-container {
				justify-content: flex-start;
				.button-icon-with-text {
					width: unset;
					.button-text.icon-start .icon {
						color: $default-text-color;
						font-size: $font-size-header;
						justify-content: flex-start;
						margin: unset;
					}
				}
			}
			.setting-page__notification-subscriber {
				&-save {
					margin: 0;
					button {
						background-color: $dark-blue;
					}
				}
				&-current-user {
					display: flex;
					align-items: center;
					font-family: $lato;
					height: $switch-height;
				}
				&-remove {
					justify-content: flex-start;
					.button-icon-with-text {
						width: unset;
						.icon {
							color: $default-text-color;
							font-size: $font-size-header;
							justify-content: flex-start;
							margin: unset;
						}
					}
				}
			}
		}
	}
	&__country-selection {
		margin: $padding-small;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		&--item {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			width: 100%;
			margin: 1rem 0;
			padding-right: unset;
			p {
				width: 50%;
			}
			.input__container {
				width: 100%;
				margin-right: unset;
				margin-left: unset;
			}
		}
	}
	&__language-selection {
		margin: $padding-small;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		&--item {
			display: flex;
			flex-direction: column;
			align-items: baseline;
			width: 100%;
			padding-right: unset;
			justify-content: space-around;
			margin: 1rem 0;
			p {
				width: 50%;
			}
			.input__container {
				width: 100%;
				margin-right: unset;
				margin-left: unset;
			}
		}
	}

	&__sub-page-container {
		border: $border-size solid $light-gray;
		padding: $padding-medium;
		border-radius: $small-border;
		height: 100%;
	}
	&__sub-page-link {
		text-align: end;
		font-family: $lato;
		padding: $padding-small 0;
		font-weight: $font-light;
		cursor: pointer;
		&:hover {
			font-weight: $font-bold;
		}
		&.selected {
			font-weight: $font-bold;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				width: $border-size-thick;
				background-color: $primary-2nd;
				right: -$padding-small;
				top: 0;
				bottom: 0;
				margin: $padding-smaller 0;
			}
		}
	}

	&__source-information {
		position: relative;
		font-family: $lato;
		padding: $padding-medium-small $padding-medium;
		font-size: 1.6rem;
		&-icon {
			height: $font-size-large;
			position: absolute;
			left: 0;

			> * {
				height: 100%;
				font-size: $font-size-large;
				color: $blue;
			}
			.MuiSvgIcon-root {
				font-size: 1.8rem;
			}
		}
		&-button-container {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
		&-connect-button {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: calc(#{$padding-smaller} + 1px) calc(#{$padding-smaller} + 6px);
			border: $border-size $light-gray solid;
			border-radius: $small-border;
			color: $default-text-color;
			font-family: $lato;
			min-width: $button-min-width + $normalize-size;
			&:hover {
				text-decoration: none;
				font-weight: $font-bold;
			}
		}
		.button-container + &-connect-button {
			margin-left: $padding-small;
		}
		.MuiOutlinedInput-root {
			background-color: white !important;
			color: $primary !important;
			font-weight: $font-bold;
		}
		&.date-box {
			padding: 0rem 3rem 0rem 0rem;
		}
		&.date-text {
			padding: 1.5rem 3rem 0.5rem 1rem;
		}
	}
	&__profile-bottom-button-container {
		display: flex;
		justify-content: flex-end;
		.button-container {
			margin-left: $padding-small;
		}
	}
	&__subscription {
		&-period {
			&-selector {
				display: flex;
				width: fit-content;
				border-radius: $medium-border;
				background-color: $white;
			}
			&-selector-container {
				display: flex;
				justify-content: center;
			}
			&-option {
				font-family: $lato;
				font-weight: $font-bold;
				cursor: pointer;
				width: 15rem;
				height: 5rem;
				color: $dark-blue;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 3px solid $dark-blue;
				&:first-child {
					border-right: none;
					border-top-left-radius: $small-border;
					border-bottom-left-radius: $small-border;
				}
				&:last-child {
					border-left: none;
					border-top-right-radius: $small-border;
					border-bottom-right-radius: $small-border;
				}
				&.selected {
					background-color: $dark-blue;
					color: $white;
				}
				&.disabled {
					background-color: $light-gray;
					color: $gray;
				}
			}
		}

		&-option {
			border: $border-size solid $light-gray;
			border-radius: $small-border;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			padding: $padding-medium $padding-small;
			&-container {
				justify-content: space-between;
			}
			&.selected {
				background-color: $light-primary;
				width: auto;
				border: 0;
				margin: $border-size 0;
			}

			&-description {
				font-family: $lato;
				font-size: $font-size-sub-header;
			}
			&-price {
				font-family: $lato;
				font-size: $font-size-header;
				p {
					color: $gray !important;
					text-transform: none !important;
					font-weight: 300 !important;
				}
				&-container {
					height: $price-container-height;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin: $padding-small 0;
				}
				&.unit {
					font-family: $lato;
					align-self: flex-end;
					font-size: $font-size-small;
				}
				&.description {
					font-family: $lato;
					font-size: $font-size-small;
				}
			}
			&-features {
				margin-bottom: $padding-medium;
			}
			&-feature {
				font-family: $lato;
			}
			&-starting-date {
				max-width: 100%;
				background-color: $white;
				border-radius: $small-border;
				margin-bottom: $padding-small;
				&-current {
					margin-bottom: $padding-small;
					width: 100%;
					border-radius: $small-border;
					padding: $padding-small;
				}
				.MuiTypography-body1 {
					font-family: $lato;
					font-size: $font-size-base;
					text-transform: uppercase;
				}
				.MuiTypography-root.MuiPickersCalendarHeader-dayLabel.MuiTypography-caption {
					font-family: $lato;
					font-size: $font-size-small;
				}
				.MuiPickersCalendar-transitionContainer {
					height: 18 * $normalize-size;
				}
				.MuiPickersDay-day {
					width: $normalize-size * 2.95;
					height: $normalize-size * 2.95;
					.MuiTypography-root {
						font-family: $lato;
						font-size: $font-size-small;
					}

					&.MuiPickersDay-daySelected {
						background-color: $dark-blue;
						.MuiTypography-root {
							color: $white;
						}
					}
				}
			}
		}
	}
	&__subscription-detail {
		border-radius: $small-border;
		border: $border-size $light-gray solid;
		min-width: 40rem;
		.divider {
			background-color: $default-text-color;
		}
		&-header {
			background-color: $primary;
			padding: $padding-small $padding-medium;
			border-top-left-radius: $small-border;
			border-top-right-radius: $small-border;
			font-family: $lato;
			font-weight: $font-bold;
			color: $white;
		}
		&-body {
			background-color: $white;
			border-bottom-left-radius: $small-border;
			border-bottom-right-radius: $small-border;
			padding: $padding-medium;
			&-header,
			&-info {
				display: flex;

				> div {
					font-family: $lato;
					flex: 1;
				}
				div:first-child,
				div:last-child {
					flex: 2;
				}
				div:last-child {
					text-align: end;
				}
				&__price {
					display: flex;
					flex-direction: row;
				}
			}
			&-header div {
				font-size: $font-size-small;
			}
			&-summary {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				&-total {
					font-family: $lato;
					margin-bottom: 0;
				}
				&-tax,
				&-billing-date {
					font-family: $lato;
					font-size: $font-size-small;
				}
			}
		}
	}
	&__modal {
		height: 60vh;
		overflow: scroll;
		min-width: 500px;
		@include breakpoint(desktop) {
			min-width: 600px;
		}
		&-search-container {
			width: 100%;
			@include container-default(
				$align-items: flex-start,
				$justify-content: space-between,
				$flex-direction: column
			);

			p {
				text-align: left;
				font-weight: bold;
				font-size: 1.4rem;
				margin-bottom: 0;
				margin-left: 1.25rem;
			}
			.input__container {
				width: 95%;
			}
			.button-container {
				margin: 0;
				margin-left: 5rem;
				margin-top: 2rem;
			}
			padding-bottom: 1rem;
			border-bottom: 1px solid $light-gray-border;
		}
		&-option {
			&-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: $padding-medium-small;
				border: 1px solid $light-gray-border;
				padding: $padding-medium-small;
			}

			&-name {
				font-family: $lato;
				&.disabled {
					color: $gray;
				}
				p {
					text-align: left;
				}
			}
			&-title {
				font-size: 2rem;
			}
			&-extra-information {
				font-family: $lato;
				@include container-default(
					$flex-direction: row,
					$justify-content: flex-start
				);
				font-size: $font-size-small;
				p {
					text-align: left;
					margin-bottom: 0;
					font-size: 1.2rem;
				}
				&--value {
					color: $dark-blue;
					margin-left: auto;
				}
				&--property {
					min-width: 12rem;
					font-weight: 600;
					margin-right: 2rem;
				}
				&--url-option {
					font-size: 1.4rem;
				}
			}
		}
		.modal__content {
			padding: $padding-small;
			height: 50vh;
		}
		.modal__footer {
			display: flex;
			justify-content: center;
		}
	}
}

.MuiChip-root {
	margin: auto;
}

.subscription {
	height: 100%;
	width: 100%;
	h3,
	p {
		font-family: $lato;
		color: $default-text-color;
		font-weight: 600;
	}
	.notifications__icon__amount-bubble {
		p {
			color: $white;
		}
	}
	&__error {
		width: 90%;
		max-width: 300px;
		p {
			text-align: left;
			color: red;
		}

		margin: 1rem;
	}
	&__header {
		padding: 0 1rem;
		display: flex;
		justify-content: flex-end;
		margin: 1rem;
		h3 {
			font-family: $lato;
			text-transform: capitalize;
		}
	}
	&__instruction-for-customer-account {
		display: flex;
		justify-content: center;
		color: red;
	}
	&__plans {
		flex-wrap: wrap;
		background-color: $light-gray;
		padding: 2rem 5rem 0 4rem;
		display: flex;
		justify-content: space-around;
		width: 100%;
		overflow-y: scroll;
		max-height: 90%;
		min-height: 90%;
		&__wrapper {
			.container {
				@include box-shadow-light();
				position: relative;
				margin: 1rem;
				width: 30rem;
				min-height: 70rem;
				border-radius: $medium-border;
				background-color: $white;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.button-container {
					width: 90%;
				}
				.header-active {
					height: 5rem;
					width: 100%;
					background-color: $ocean-blue;
					display: flex;
					justify-content: center;
					align-items: center;
					p {
						color: $default-text-color;
						font-family: $lato;
						font-weight: bold;
						font-size: 1.6rem;
						margin: 0 !important;
						text-transform: uppercase;
					}
				}
				.header-hidden {
					visibility: hidden;
					height: 0;
				}
				&-title {
					text-align: center;
					margin: 2rem 0 1rem 0;
				}
				&-price {
					text-align: center;
					display: flex;
					justify-content: center;
					p {
						font-size: 2rem;
					}
				}
				&-features {
					width: 100%;
					margin: 2rem 0;
					.row {
						height: 4rem;
						border-top: 0.1rem solid $grey;
						display: flex;
						justify-content: center;
						align-items: center;
						p {
							margin: 0 !important;
						}
					}
					.row:last-child {
						border-bottom: 1px solid $light-gray2;
					}
				}
				.button-primary-2nd {
					//position: absolute;
					bottom: 2rem;
					background-color: $blue;
					width: 90%;
					div {
						font-family: $lato;
						font-weight: bold;
					}
				}
			}
			.selected {
				margin: 0 auto;
				margin-bottom: 3rem;
				.button-primary-2nd {
					background-color: $background-darkerblue;
				}
			}
		}
	}
}
