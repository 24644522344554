.suggestion-detail-modal {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(128, 128, 128, 0.553);
	z-index: 10000;
	@include container-default();
	margin-top: auto;
	&__body {
		&-closer {
			width: 100%;
			position: sticky;
			top: 0;
			background-color: $white;
			z-index: 2;
			margin-bottom: 2rem;
			padding-top: 1.7rem;
		}
		@include box-shadow-light();
		background-color: $white;
		padding: 0 1.7rem 1.7rem 1.7rem;
		border-radius: $small-border;
		min-width: 80vw;
		min-height: 40vh;
		max-width: 70vw;
		max-height: 80vh;
		@include container-default(
			$flex-direction: column,
			$justify-content: flex-start,
			$align-items: flex-start
		);

		overflow: scroll;

		&--close-button {
			margin-left: auto;
			cursor: pointer;
			.MuiSvgIcon-root {
				font-size: 28px;
				color: rgb(17, 25, 116);
			}
		}

		&--date {
			font-size: 1.5rem;
			font-weight: bold;
			&.edit-ads {
				margin-bottom: 2rem;
			}
		}

		&__task-details {
			@include container-default(
				$align-items: center,
				$justify-content: center
			);
			width: 100%;
			&--title {
				font-size: 2.6rem;
			}
			&--description {
				margin-top: 2rem;
				min-width: 100%;
				font-size: 1.6rem;
				flex: 1;
				padding: 1rem;
				border-radius: $small-border;
				border: 1px solid $light-gray-border;
				&--text {
					font-size: 1.2rem;
					white-space: pre-wrap;
				}
			}
		}
		&--external-links-button {
			max-width: 20rem;
		}
		&--list-container {
			@include mui-box-list();
			margin-top: 3rem;
		}
		&--keyword-container {
			@include container-default(
				$flex-direction: row,
				$justify-content: stretch
			);
			margin-top: 2rem;
			margin-bottom: 4rem;
			border: 2px solid $medium-blue;
			border-radius: $small-border;
			min-height: 100px;
			width: 50%;
			&.open {
				width: 100%;
			}
			&__box {
				border-bottom: 0.6px solid #03407936;
				display: flex;
				justify-content: space-between;
				margin-top: 0.5rem;
				&--value {
					font-weight: bold;
					font-size: 1.4rem;
					flex-basis: 30%;
					text-align: right;
				}
				// &--value:first-letter {
				// 	text-transform: uppercase;
				// }
				&--title {
					font-size: 1.4rem;
					font-weight: lighter;
					width: 100%;
				}
				&--quality-score {
					@include container-default(
						$flex-direction: row,
						$justify-content: flex-start
					);
					flex-wrap: wrap;
					p {
						font-size: 1.3rem;
					}
					li {
						margin: 0.5rem 1rem;
						padding: 0.5rem 1rem;
						border-radius: 25px;
						color: $white;
						background-color: $turqoise;
					}
				}

				&.view-more {
					flex-basis: 20%;
					@include container-default(
						$align-items: center,
						$justify-content: center,
						$flex-direction: row
					);
					&.open {
						flex-basis: 100%;
					}
					p {
						margin-bottom: 0;
						font-size: 1.3rem;
						color: $white;
					}
				}

				&.view-more {
					flex: 1;
					flex-basis: 20%;
					height: 100px;
					color: $white;
					background-color: $dark-blue;
					cursor: pointer;
				}
			}
			&__box:last-child {
				border: none;
			}
		}
		&--title-container {
			border: 1px solid $light-gray-border;
			@include suggestion-modal-group-container();
			min-height: 125px;
			&-holder {
				@include container-default(
					$justify-content: center,
					$align-items: flex-start,
					$flex-direction: column
				);
				border-radius: $medium-border;
				min-height: 160px;
			}

			&--title {
				height: 100%;
				@include container-default(
					$align-items: center,
					$justify-content: space-around
				);
				min-height: 160px;
				padding: 1rem 1rem 1rem 2.2rem;
				word-break: break-word;
				width: 100%;
				p {
					font-size: 1.3rem;
					font-weight: bold;
				}

				&--top {
					width: 100%;
					border-bottom: 0.5px solid $light-gray-border;
					&-title {
						font-weight: bold;
					}
					&-text {
						font-weight: 400 !important;
					}
				}
				&--bottom {
					width: 100%;
					&-title {
						font-weight: bold;
					}
					&-text {
						font-weight: 400;
					}
				}
				&__details-container {
					background-color: $gray-blue;
					@include container-default(
						$flex-direction: column,
						$align-items: center,
						$justify-content: center
					);
					width: 100%;
					border-radius: $medium-border 0 $medium-border $medium-border;
					&--details {
						width: 90%;
						.suggestion-detail-modal__body--title-container--title__details-container--titles {
							width: 100%;
							border: none;
						}

						@include container-default(
							$flex-direction: column,
							$align-items: space-around,
							$justify-content: space-around
						);

						margin: 1rem 0;
					}

					&--titles {
						width: 90%;
						margin: 1rem;

						@include container-default(
							$flex-direction: row,
							$align-items: space-around,
							$justify-content: space-evenly
						);
						&.column {
							background-color: $gray-blue;
							flex-direction: column;
							&-copy-to-clipboard-button-holder {
								width: 20%;
								margin: 0rem 1rem 2rem 1rem;
							}
						}
						p {
							font-weight: 400;
							font-size: 1.5rem;
						}
						&--content {
							white-space: break-spaces;
							padding: 0rem 2rem 0rem 2rem;
						}
						&--items {
							&.item-1 {
								font-weight: bold;
								flex-basis: 50%;
								margin-bottom: 0;
							}
							&.item-2 {
								font-weight: bold;
								flex-basis: 25%;
								text-align: center;
								margin-bottom: 0;
							}
							&.details {
								font-weight: 400;
							}
						}
					}
					&--titles {
						margin-left: 0rem;
						&.margin-top {
							margin-top: 2rem;
						}
						&.border-bot {
							border-bottom: 1px solid $light-gray-border;
							margin-bottom: 1rem;
						}
					}
				}
			}
			&--description {
				display: flex;
				flex-direction: column;
				width: 100%;
				min-height: 100%;
				// border-left: 1px solid $light-gray-border;
				background-color: $gray-blue;
				padding: 0.5rem;
				&-title {
					margin: 2rem;
					margin-bottom: 0rem;
					p {
						font-size: 1.3rem;
					}
					&-top {
						font-weight: bold;
					}
				}
				&-buttons {
					display: flex;
					margin: 2rem;
					.button-container {
						margin: 0rem;
						.button-text {
							min-width: 10rem;
							font-weight: bold;
						}
					}
					&--see-ideas {
						flex-basis: 15%;
					}
					&--create-content {
						margin-left: 2rem;
						//flex-basis: 30%;
					}
				}
				&--container {
					padding-left: $padding-medium;
					p {
						color: $white;
						font-size: 1.2rem;
					}
				}
			}
		}
		&-group-container {
			display: flex;
		}
		.group-widener {
			width: 100%;
			@include container-default(
				$flex-direction: row,
				$align-items: space-around,
				$justify-content: space-between
			);
		}
		.group-item {
			flex-basis: 49%;
		}
		&-group {
			flex: 1;
			//margin-bottom: $padding-small;

			p {
				margin: 0.5rem 0rem;
			}
			h6 {
				margin: $padding-small 0 $padding-tiny;
				color: $primary;
				text-align: center;
			}
			&--values {
				border: 0.6px solid $light-gray-border;
				border-radius: $medium-border;
				display: flex;
				flex-direction: column;
				margin-bottom: 4rem;
				&-title {
					font-weight: bold;
					font-size: 1.4rem;
					margin-bottom: 1.6rem;
					margin-top: 0.8rem;
				}
				&-keywordgroup {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					width: 100%;
					padding: 2rem;
				}
				&__effectiveness-graph {
					@include container-default($flex-direction: column);
					&--header {
						@include container-default(
							$flex-direction: row,
							$justify-content: space-between
						);
						width: 100%;
						padding: 2rem;
						&__selector {
							min-width: 20rem;
						}
					}
					&--content {
						padding: 2rem;
						width: 100%;
						.recharts-wrapper .recharts-cartesian-grid-vertical line {
							stroke-dasharray: 0 0;
						}
						.recharts-wrapper
							.recharts-cartesian-grid-vertical
							line:nth-last-child(-n + 2) {
							stroke-opacity: 0;
						}
						.recharts-wrapper
							.recharts-cartesian-grid-horizontal
							line:first-child {
							stroke-opacity: 0;
						}
					}
				}
			}
			&--values li {
				font-size: 1.4rem;
				font-weight: 500;
				padding-bottom: 0.5rem;
			}
			&--values ul {
				width: 100%;
				padding: 1rem;
				padding-left: 2rem;
			}

			&-detail p {
				text-align: center;
			}
			&-detail ul {
				text-align: center;
				display: flex;
				flex-flow: wrap;
			}
			&-detail li {
				padding: $padding-small;
				display: block;
			}
			// &-detail div {
			// 	padding: $padding-medium;
			// }
		}
		&__tags-container {
			border: 1px solid $gray-blue;
			@include suggestion-modal-group-container();
			background-color: $gray-blue;
			display: flex;
			flex-direction: column;
			&__item {
				padding: 0.5rem 2rem;
				border-bottom: 0.6px solid $white;
				display: flex;
				align-items: center;
				min-height: 5rem;
				&__title {
					font-weight: 700;
					flex-basis: 15%;
					font-size: 1.4rem;
					min-width: 8rem;
				}
				&__content {
					flex: 1;
					display: flex;
					align-items: center;
					&.priority {
						.MuiButtonBase-root {
							background-color: $white;
							text-decoration: none;
							font-size: 1.2rem;
							padding: 0.5rem 1rem 0.5rem 0.2rem;
							.MuiButton-startIcon {
								margin-left: 0;
							}
						}
					}
					.dropdown-with-icon__text {
						font-size: 1.2rem;
						color: $black;
					}
					&__add-assignees {
						margin-left: auto;
						display: flex;
						flex-basis: 50%;
						max-width: 30rem;
						min-width: 20rem;
						&__select {
							flex-basis: 90%;
						}
						&__button {
							display: flex;
							align-items: center;
						}
					}
					&__add-tracking-attribute {
						margin-right: auto;
						display: flex;
						flex-basis: 50%;
						max-width: 30rem;
						min-width: 20rem;
						&__select {
							flex-basis: 30%;
						}
						&__button {
							display: flex;
							align-items: center;
						}
					}
					&.value {
						margin-left: 2rem;
						p {
							font-size: 1.4rem;
							margin-bottom: 0;
						}
					}
				}
			}
			&__item:last-child {
				border-bottom: none;
			}
		}
		&.modal-table {
			width: 100%;
		}
		&-image-list {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			width: 50%;
			&_type {
				flex-basis: 25%;
				padding: 1rem;
				font-weight: 300;
				text-transform: capitalize;
			}
			&_date {
				flex-basis: 30%;
				padding: 1rem;
				font-weight: 300;
			}
			.button-container {
				margin: 0.5rem 2rem;
				&_view-button {
					border-radius: 6px;
					height: 5rem;
					margin: 0.5rem 2rem 0.5rem 2rem;
				}
			}
		}
		&-extra-image {
			max-width: 100%;
		}
		.MuiChip-root {
			background-color: $white !important;
			border: 1.5px solid $primary;
			border-radius: 100px;
			font-size: 1.4rem;
			font-weight: bold;
		}
		.MuiChip-label {
			font-family: $lato;
			font-style: normal;
			font-weight: bold;
			font-size: 1.3rem;
		}
		.button-container {
			border-radius: $small-border;
			justify-self: center;
			align-self: center;
		}
	}
}

.table-position-column {
	font-weight: bold;
	margin-left: 2rem;
}

.category-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: $small-border;
	&.seo {
		background-color: $light-pink;
	}
	&.ADS_ad,
	&.ADS_campaign,
	&.ADS_account,
	&.ADS_adGroup,
	&.ads {
		background-color: $light-yellow;
	}
	&.CRO_exactPath,
	&.CRO_includePath,
	&.cro {
		background-color: $lighter-green;
	}
	&.SOME_ad,
	&.SOME_campaign,
	&.SOME_account,
	&.SOME_adSet,
	&.some {
		background-color: $lighter-blue;
	}
	&.CUSTOM {
		background-color: $lime;
	}

	p {
		margin-bottom: 0;
		font-size: 1.2rem;
	}
	&--dot {
		border-radius: 50%;
		width: 10px;
		height: 10px;
		margin: 1rem;
		&.seo {
			background-color: $red;
		}
		&.ADS_ad,
		&.ADS_campaign,
		&.ADS_account,
		&.ADS_adGroup,
		&.ads {
			background-color: $amber;
		}
		&.CRO_exactPath,
		&.CRO_includePath,
		&.cro {
			background-color: $green;
		}
		&.SOME_ad,
		&.SOME_campaign,
		&.SOME_account,
		&.SOME_adSet,
		&.some {
			background-color: $blue;
		}
		&.CUSTOM {
			background-color: $white;
		}
	}
	&--name {
		margin-right: 1rem;
		color: $black;
	}
}
