.calendar {
	width: 100%;
	height: 100vh;
	padding: 4rem;
	overflow-y: hidden;
	position: relative;
	.rbc-calendar {
		border-radius: 1.2rem;
		background-color: $white;
		padding: 0.5rem 1.5rem 2rem 1.5rem;
	}
	.rbc-month-view {
		border: none;
		.rbc-header {
			font-size: 15px;
			line-height: 24px;
			border-bottom: 0.1rem solid $light-gray-border;
			border-top: 0.1rem solid $light-gray-border;
			padding: 0.5rem;
			text-transform: lowercase;
			span {
				font-weight: 400;
			}
		}
		.rbc-button-link {
			margin: 0.5rem;
		}
	}
	.rbc-header + .rbc-header {
		border-left: none;
	}
	// .rbc-event {
	// 	background-color: $dark-blue;
	// }
	.rbc-event.rbc-selected {
		background-color: $dark-blue;
	}
	.rbc-row-segment {
		padding: 0 0.5rem 0.2rem 0.5rem;
	}
	.rbc-day-slot .rbc-event-label {
		color: $white;
	}
	.rbc-month-row {
		border-left: 0.1rem solid $light-gray-border;
		border-right: 0.1rem solid $light-gray-border;
	}
	.rbc-month-row:last-child {
		border-bottom: 0.1rem solid $light-gray-border;
	}
	.rbc-date-cell {
		padding-right: 0;
	}
	&__tool-bar {
		display: flex;
		margin: 0.5rem 0rem;
		height: 4rem;
		&__button-wrapper {
			flex-grow: 2;
			@include container-default($flex-direction: row);
			&__date-text {
				align-self: center;
				font-weight: bold;
				font-size: $font-size-large;
				cursor: pointer;
				width: 16rem;
				text-align: center;
				&.day-view {
					width: 19rem;
				}
			}
		}
		&__selector {
			flex: 1;
			display: flex;
			width: fit-content;
			border-radius: $medium-border;
			background-color: $white;
			margin: 0.5rem 0rem;
			&__option {
				font-family: $lato;
				font-weight: $font-bold;
				font-size: 1.3rem;
				cursor: pointer;
				color: $dark-blue;
				display: flex;
				justify-content: center;
				align-items: center;
				border: $tiny-border solid $dark-blue;
				padding: 0.5rem;
				text-transform: capitalize;
				&:first-child {
					border-right: none;
					border-top-left-radius: $small-border;
					border-bottom-left-radius: $small-border;
				}
				&:last-child {
					border-left: none;
					border-top-right-radius: $small-border;
					border-bottom-right-radius: $small-border;
				}
				&.selected {
					background-color: $dark-blue;
					color: $white;
				}
				&.disabled {
					background-color: $light-gray;
					color: $gray;
				}
			}
		}
	}
	&__event-wrapper {
		@include container-default(
			$flex-direction: row,
			$justify-content: space-between
		);
		padding: 0.3rem;
		&__time-group {
			@include container-default($flex-direction: row);
			.circle-icon {
				color: $dark-yellow;
				margin: 0rem 1rem 0rem 0.3rem;
				font-size: 0.7rem;
				.MuiSvgIcon-root {
					font-size: 0.7rem;
				}
				&.seo {
					color: $red;
				}
				&.ADS_ad,
				&.ADS_campaign,
				&.ADS_account,
				&.ADS_adGroup,
				&.ads {
					color: $amber;
				}
				&.CRO_exactPath,
				&.CRO_includePath,
				&.cro {
					color: $green;
				}
				&.SOME_ad,
				&.SOME_campaign,
				&.SOME_account,
				&.SOME_adSet,
				&.some {
					color: $blue;
				}
				&.CUSTOM {
					color: $white;
				}
			}
			.time-text {
				font-size: 1.3rem;
				&.seo &.ADS_ad,
				&.ADS_campaign,
				&.ADS_account,
				&.ADS_adGroup,
				&.ads &.CRO_exactPath,
				&.CRO_includePath,
				&.cro &.SOME_ad,
				&.SOME_campaign,
				&.SOME_account,
				&.SOME_adSet,
				&.some &.CUSTOM {
					color: $dark-blue !important;
				}
				&.instagram,
				&.facebook,
				&.instagram_scheduled,
				&.linkedin_scheduled,
				&.linkedIn {
					color: $white;
				}
			}
		}
		.post-icon {
			color: $white;
			.MuiSvgIcon-root {
				font-size: 1.5rem;
			}
		}
	}
	&__date-header {
		@include container-default(
			$flex-direction: row,
			$justify-content: space-between,
			$align-items: flex-start
		);
		position: relative;
		&__date-string {
			font-weight: 400;
			margin: 1rem 0rem 1rem 1rem;
		}
		&__add-button {
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
		}
	}
	&__date-header:hover .calendar-add-icon {
		display: inline;
	}
	.calendar-add-icon {
		display: none;
	}
	.loading-screen {
		//position: relative;
	}
}
