.word-press-tools {
	@include container-default(
		$flex-direction: column,
		$align-items: stretch,
		$justify-content: flex-start
	);
	height: 100%;
	width: 100%;
	&__article {
		margin-top: 2rem;
		h4 {
			margin-bottom: 1rem;
		}
	}
	&__container {
		@include container-default($flex-direction: row, $align-items: flex-start);

		max-height: 90vh;
		&__content {
			@include box-shadow-light();
			background-color: $white;
			margin: 1rem auto;
			padding: $padding-medium-small;
			height: 100%;
			width: 97%;
			&__page-lists {
				@include container-default(
					$flex-direction: column,
					$align-items: flex-start,
					$justify-content: space-around
				);
				img {
					max-width: 100%;
				}
			}
			overflow-x: scroll;
		}
	}
	&__item-list {
		@include container-default(
			$align-items: flex-start,
			$justify-content: center
		);
		margin: $padding-small;
		padding: $padding-small;
		height: 100%;
		width: 100%;
		&__title {
			@include container-default(
				$flex-direction: row,
				$align-items: center,
				$justify-content: space-between
			);
			h2 {
				font-size: 2.5rem;
				margin-right: 2rem;
			}
		}
		&__title-container {
			width: 100%;
			@include container-default(
				$align-items: center,
				$justify-content: space-between,
				$flex-direction: row
			);
			.button-container {
				margin: 1rem;
			}
			&__buttons {
				@include container-default($flex-direction: row);
			}
		}

		&__items {
			@include container-default(
				$align-items: flex-start,
				$justify-content: space-around,
				$flex-direction: row
			);
			transition: height 2s ease-out;
			margin-top: 2rem;
			flex-wrap: wrap;
			&__item {
				@extend .report-page-template__content__container--item;
				padding: $padding-small $padding-medium-small;
				flex-basis: 45%;
				margin: 0 0 1rem 0;
				@include container-default(
					$flex-direction: row,
					$align-items: center,
					$justify-content: space-between
				);
			}

			&__page-selector {
				@include container-default(
					$align-items: center,
					$justify-content: center,
					$flex-direction: row
				);
				min-width: 100%;
				margin: 0 $padding-small;
				margin-top: auto;
				max-width: 250px;

				p {
					margin-bottom: 0;
					cursor: pointer;
				}
				&__arrow-right {
					cursor: pointer;
					margin-right: auto;
					margin-left: 1rem;
					min-width: 30px;
					margin-bottom: -2px;
				}
				&__arrow-left {
					cursor: pointer;
					margin-right: 1rem;
					margin-left: auto;
					min-width: 30px;
					margin-top: -3px;
				}
				&__page-numbers {
					@include container-default($flex-direction: row);
					&__item {
						margin: 0 1rem;
						&.selected {
							background-color: $white;
							border-radius: $round-border;
							border: 0.5px solid $dark-blue;
							padding: 1px 7px;
							margin: 0;
							margin-right: -2px;
							cursor: not-allowed;
						}
					}
				}
			}
		}
	}
}
