.roas-allogator-page {
	height: 100%;
	width: 100%;
	&__content {
		@include container-default($flex-direction: column);
		//max-height: 90vh;
		&--container {
			@include box-shadow-light();
			background-color: $light-gray;
			margin: 2rem 1.5rem;
			padding: 2rem $padding-medium-small;
			height: 100%;
			width: 95%;
			background-color: $white;
			margin-bottom: auto;
			border-radius: 1.4rem;
			&__header {
				@include container-default(
					$justify-content: center,
					$flex-direction: row,
					$align-items: center
				);
				font-weight: $font-light;
				margin-left: 0.1rem;
				&.name-group {
					flex-basis: 25%;
				}
				&.roas {
					flex-basis: 5%;
					@include container-default(
						$justify-content: flex-start,
						$flex-direction: row
					);
				}
				&.price-per-conversion {
					flex-basis: 15%;
					@include container-default(
						$justify-content: flex-start,
						$flex-direction: row
					);
				}
				&.suggestion-container {
					flex-basis: 40%;
				}
				&.devices {
					flex-basis: 25%;
				}
				&.input {
					flex-basis: 50%;
					@include container-default(
						$justify-content: flex-start,
						$flex-direction: row
					);
				}
			}
			&__body {
				&.total-budget {
					justify-content: flex-end;
				}
				@include container-default(
					$justify-content: center,
					$flex-direction: row,
					$align-items: center
				);
				margin: 1rem 0rem;
				&__name-group {
					flex-basis: 25%;
					width: 100%;
					display: flex;
					align-self: flex-start;
					&.title {
						flex: 1;
						margin-left: 1rem;
						word-break: break-word;
					}
					&.ad-group-title {
						flex: 1;
						font-weight: $font-light;
						margin-left: 1rem;
						word-break: break-word;
					}
					&.total-budget {
						align-self: auto;
						flex-basis: 20%;
					}
				}

				&__roas {
					flex-basis: 5%;
					align-self: flex-start;
					&.header {
						font-weight: $font-light;
					}
				}
				&__devices {
					flex-basis: 25%;
					padding-top: 0.2rem;
					@include container-default($align-items: flex-start);
					&.title {
						font-weight: $font-light;
					}
					&.item {
						font-size: 1.4rem;
					}
				}
				&__price-per-conversion {
					flex-basis: 15%;
					align-self: flex-start;
					&.header {
						font-weight: $font-light;
					}
				}
				&__suggestion-value {
					flex-basis: 40%;
					display: flex;
					flex-direction: column;
					&__input-container {
						display: flex;
						margin-bottom: 1rem;
						&__item {
							flex-basis: 50%;
							.input__container {
								margin: 0;
								width: 90%;
								.input__row .input__border {
									border: 0.2rem solid rgba(0, 25, 116, 0.19);
								}
								input {
									font-weight: bold;
								}
							}
						}
					}
				}
				&--item {
					align-self: center;
					&.width {
						min-width: 18rem;
					}
					&__button-container {
						.MuiCheckbox-root {
							.MuiSvgIcon-root {
								font-size: 2rem;
								color: '#21CCFF';
							}
						}
					}
				}
			}
		}
		&--title {
			font-size: 2.2rem;
			margin-bottom: 1rem;
		}
		&--description {
			font-weight: $font-light;
			margin-bottom: 2rem;
		}
	}
}
