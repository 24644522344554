.comments {
	@include container-default();
	width: 100%;
	background-color: $gray-blue;
	border: 1px solid $gray-blue;
	border-radius: $medium-border;
	max-height: 500px;
	&__container {
		@include container-default($justify-content: flex-start);
		width: 95%;
		overflow-y: scroll;
		padding-top: 1.5rem;
	}
	&__input {
		@include container-default();
		width: 95%;
		margin-bottom: 2rem;
	}
	&__title {
		margin-top: 0.5rem;
		width: 100%;
		padding: 1rem;
		margin-right: auto;
		p {
			margin-bottom: 0;
			font-size: 1.4rem;
			font-weight: bold;
		}
	}
	&__item {
		@include container-default(
			$flex-direction: row,
			$justify-content: space-between,
			$align-items: none
		);
		width: 100%;
		&--user {
			@include container-default(
				$flex-direction: column,
				$justify-content: normal,
				$align-items: center
			);
			flex-basis: 5%;
			min-height: 100%;
			min-width: 5rem;
			.MuiAvatar-root {
				border: 2px solid $white;
				border-radius: 6px;
			}
			&__divider {
				margin: 0.5rem 0rem;
				flex-direction: row;
				border-left: 2px solid $white;
				// make div take up full space, with width unset the div width is 1px which in return centered the border line
				flex: 1;
			}
		}
		&--comment {
			background-color: $white;
			border: 1px solid $white;
			border-radius: $medium-border;
			padding: 1rem 1.5rem;
			flex-basis: 94%;
			margin-bottom: 2rem;
			p {
				font-size: 1.2rem;
			}
			&__info {
				.author-text {
					display: flex;
					.full-name {
					}
					.time {
						margin-left: 2rem;
						color: $grey;
					}
				}
			}
			&__text {
				font-size: 1.2rem;
				color: $black;
				word-break: break-word;
				white-space: pre-wrap;
			}
		}
		&--input {
			display: flex;
			flex-basis: 94%;
			.input__container {
				margin: 0;
				.input__row {
					margin-right: 1.8rem;
					textarea,
					input {
						font-size: 1.2rem;
					}
					.input__border {
						border: 1px solid $light-blue;
					}
				}
			}
		}
	}
}
