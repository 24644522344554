.radio__container {
	display: flex;
	align-items: center;
	padding: $padding-small;
	font-size: $font-size-small;

	.MuiSvgIcon-root {
		width: 2rem;
		height: 2rem;
	}
}
