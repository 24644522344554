.top-keywords {
	flex-basis: 20%;
	min-width: 200px;
	background-color: $white;
	border-radius: $small-border;

	&-holder {
		display: flex;
		margin-left: 1.2rem;
		flex-direction: column;
		height: 100%;
		width: 80%;

		&--item {
			margin-top: 1rem;
			p {
				font-weight: lighter;
				text-transform: capitalize;
				font-size: 1.4rem;
			}
		}
	}
	h5 {
		font-weight: 700;
		text-transform: none;
		padding-top: 2rem;
		font-size: 1.4rem;
		margin-bottom: 1.7rem;
	}
	a {
		margin-top: auto;
		font-size: 1.4rem;
		font-weight: bold;
		padding: $padding-small;
		text-decoration: underline;
		&:visited {
			text-decoration: none;
			color: unset;
		}
	}
}
