.post-scheduler-page {
	height: 100%;
	width: 100%;
	padding: 3rem;
	overflow-y: scroll;
	&__content {
		border-radius: $medium-border;
		margin-bottom: 2rem;
		@include container-default($flex-direction: row, $align-items: flex-start);
		&.edit-page {
			flex-direction: column;
		}
		&__post-creation {
			flex-basis: 50%;
			.input__container {
				width: 98%;
			}
			p {
				font-weight: 700;
				margin-left: 1rem;
				margin-bottom: 2rem;
			}
			&__channel-selection {
				@include container-default($align-items: flex-start);
				@include bordered-container($padding: 2rem 1rem);
				.shortcut__button {
					justify-content: flex-start;
					margin-left: 1rem;
					&__item {
						margin-right: 2rem;
					}
				}
			}
			.post-scheduler-page__content__post-edit__post-container__post--texts__ai-selection__item {
				margin: 0rem 1rem;
				&--name {
					font-size: 1.6rem;
				}
				&--value {
					font-size: 1.6rem;
				}
			}
			&__topic {
				@include bordered-container($padding: 2rem 1rem);
			}
			&__content-type {
				@include bordered-container();
			}
			&__content-subject {
				@include bordered-container();
			}
			&__keywords {
				@include bordered-container();
				&__text {
					@include container-default(
						$flex-direction: row,
						$justify-content: flex-start
					);
					p:last-child {
						color: $dark-text;
						margin-left: 0.5rem;
					}
				}
			}
			&__ai-content {
				position: relative;
				@include bordered-container();
				&__item {
					width: 100%;
					padding: 0rem 1rem;
					textarea {
						-ms-overflow-style: none; /* Internet Explorer 10+ */
						scrollbar-width: none; /* Firefox */
						width: 100%;
						min-height: 20rem;
						border: 1px solid $light-gray-border;
						padding: 0.5rem;
						resize: none;
					}
					textarea::-webkit-scrollbar {
						display: none; /* Chrome */
					}
					// styling for ReactQuill if we use it later!
					.ql-editor {
						height: auto;
						overflow-y: scroll;
					}
					.ql-toolbar.ql-snow {
						border-radius: 1rem 1rem 0rem 0rem;
					}
					.ql-toolbar.ql-snow + .ql-container.ql-snow {
						border-radius: 0rem 0rem 1rem 1rem;
					}
					.highlighted-pop-up {
						.button-container {
							margin: 0;
							button {
								background-color: $background-darker-gray;
							}
							.button-text {
								display: flex;
								align-items: center;
								justify-content: center;
								color: $dark-blue;
								img {
									margin-left: 1rem;
								}
							}
						}
					}
				}
				&__button {
					@include container-default(
						$flex-direction: row,
						$justify-content: flex-start
					);
					margin: 1rem 0rem 1rem 1rem;
					.button-container {
						margin: 0rem;
						margin-right: 1rem;
					}
					.button-container:first-child {
						width: 5rem;
					}
					.button-container button .MuiSvgIcon-root {
						margin-left: 0.5rem;
						font-size: 2rem;
					}
					.button-container .button-text {
						display: flex;
						justify-content: center;
						align-items: center;
						margin-left: 0rem;
						min-width: 8rem;
						img {
							width: 2rem;
							height: 2rem;
							margin-left: 1rem;
						}
					}
				}
			}
			&--texts {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-bottom: 2rem;
				&--textcard {
					@include container-default($justify-content: space-between);
					width: 18%;
					min-width: 200px;
					padding: 0.5rem;
					margin-top: 1rem;
					border-radius: $medium-border;
					background-color: $white;

					textarea {
						width: 90%;
						min-height: 10rem;
						border: 1px solid $light-gray-border;
						border-radius: $medium-border;
						padding: 0.5rem;
						resize: none;
						margin: 0 1rem;
					}
					&.selected {
						border: 1px solid $primary;
					}
					&--selected-button {
						background-color: $light-blue;
					}
				}
			}
			&__post-container {
				h6 {
					font-size: 2.5rem;
					text-transform: none;
					font-weight: 700;
				}
				&__post {
					animation: appear 1.5s;
					@include container-default(
						$flex-direction: row,
						$justify-content: space-between,
						$align-items: stretch
					);
					flex-wrap: wrap;
					margin-bottom: 2rem;
					&--texts {
						@include container-default($justify-content: flex-start);
						background-color: $white;
						border-radius: $medium-border;
						flex-basis: 49%;
						//min-width: 450px;
						label {
							font-size: 1.4rem;
							font-weight: 400;
							margin-bottom: 1rem;
							margin-top: 2.5rem;
						}
						&__some-input {
							width: 100%;
							padding: 2rem;
							textarea {
								width: 100%;
								height: 20rem;
								border: 1px solid $light-gray-border;
								border-radius: $medium-border;
								padding: 0.5rem;
								resize: none;
							}
							&--title {
								// padding-bottom: 2rem;
							}
							.input__container {
								margin: 0rem;
								.input__row .input__border {
									border: 1px solid $gray-border;
								}
							}
							&--checkbox {
								display: flex;
								align-items: center;
								margin-left: 0.6rem;
								margin-bottom: 2rem;
								input {
									transform: scale(1.7);
								}
								p {
									margin-bottom: 0;
									margin: 0 0 0 1rem;
								}
								img {
									border-radius: 5px;
									margin-left: 1.3rem;
									height: 1.5rem;
								}
							}
							&--link-input {
								align-self: flex-start;
								width: 90%;
								margin-left: 1rem;
								p {
									font-size: 1.4rem;
									margin: 0;
									margin-bottom: 0.5rem;
								}
							}
							&--statistic {
								border: 1px solid #e6e8ea;
								padding: 2rem;
								border-radius: 10px;
								&--title {
									margin-top: 1rem;
									margin-bottom: 1rem;
									display: flex;
									align-items: center;
								}
								&--info-box {
									ul {
										margin: 0;
										display: flex;
										width: 100%;
										flex-wrap: wrap;
										padding: 0;
										li {
											margin-right: 2rem;
											margin-bottom: 1rem;
											margin-top: 1rem;
											min-width: 14rem;
											dd {
												margin: 0;
												font-weight: normal;
											}
										}
									}
								}
							}
						}
					}
					&__ai-selection {
					}
					&__slideshow {
						@include container-default($align-items: flex-start);
						background-color: $white;
						border-radius: $medium-border;
						flex-basis: 49%;
						padding: 2rem;
						//min-width: 450px;
						h6 {
							margin-bottom: 1rem;
						}
						p {
							font-size: 1.4rem;
						}
						&--add-button {
							margin-bottom: auto;
						}
					}
					&--publish {
						@include container-default(
							$flex-direction: row,
							$align-items: center,
							$justify-content: flex-start
						);
						flex-wrap: wrap;
						height: 10rem;
						padding: 2rem;
						background-color: $white;
						border-radius: $medium-border;
						.button-container {
							margin: 1rem;
						}
						&--time-picker {
							margin-left: 5rem;
							p {
								margin-bottom: 0rem;
								font-weight: 400;
								font-size: 1.2rem;
							}
							.MuiInputBase-input-MuiOutlinedInput-input {
								padding: 1.2rem 1rem;
							}
							.MuiTextField-root {
								input {
									padding: 1.2rem 1rem;
								}
							}
						}
						&__switch-container {
							@include container-default(
								$align-items: flex-start,
								$justify-content: space-between
							);
							height: 100%;
							&--name {
								font-weight: 700;
								font-size: 1.8rem;
								margin-bottom: 0rem;
							}
							&--switch {
								display: flex;
								margin-top: 1rem;
								p {
									margin-left: 1rem;
									margin-bottom: 0rem;
									font-weight: 400;
								}
							}
						}

						&--delete-button {
							margin-right: auto;
						}
						&--schedule-button {
							&--facebook,
							&--instagram {
								margin: 0 4rem;
								p {
									margin-bottom: 0.5rem;
									font-size: 1.2rem;
								}
							}
							display: flex;
							margin-left: auto;
						}
						&--times {
							&--boxes {
								display: flex;
								flex-wrap: wrap;
								p {
									border: $tiniest-border solid #e5e5ea;
									border-radius: 4px;
									margin: 0.5rem;
									padding: 1rem;
								}
							}
						}
					}
				}

				&--inputs-holder {
					&--datepicker {
					}
					&--buttons {
						display: flex;
					}
				}
			}
			&__image-cropper {
				min-width: 90vw;
			}
		}
		&__calendar {
			flex-basis: 50%;
			margin-right: 3rem;
			.calendar {
				padding: unset;
				margin-left: 3rem;
			}
		}
		&__post-edit {
			&__generate-content {
				background-color: $white;
				border-radius: $medium-border;
				padding: 2rem 2rem 0.1rem 2rem;
				margin-bottom: 2rem;
				&__selectors {
					@include container-default(
						$flex-direction: row,
						$justify-content: space-between,
						$align-items: flex-end
					);
					flex-wrap: wrap;
					&__select-with-search-holder {
						display: flex;
						&-item {
							min-width: 140px;
							flex-basis: 20%;
							padding-bottom: 0.5rem;
							p {
								font-size: 1.2rem;
								margin: 0 0 0.5rem 0;
							}
							&.keyword {
								flex-basis: 25%;
								padding-bottom: 0;
							}
							&.dropdown {
								margin-left: 4rem;
								margin-right: auto;
							}
						}
					}
				}

				&.button {
					width: fit-content;
					padding: 1rem;
				}

				&--title {
					font-size: 2rem;
					font-weight: bold;
				}
				&--description {
					p {
						font-size: 1.2rem;
					}
				}
				&--emoji-selector {
					@include container-default($flex-direction: row);
				}
			}
			&--texts {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-bottom: 2rem;
				&--textcard {
					@include container-default($justify-content: space-between);
					width: 18%;
					min-width: 200px;
					padding: 0.5rem;
					margin-top: 1rem;
					border-radius: $medium-border;
					background-color: $white;

					textarea {
						width: 90%;
						min-height: 10rem;
						border: 1px solid $light-gray-border;
						border-radius: $medium-border;
						padding: 0.5rem;
						resize: none;
						margin: 0 1rem;
					}
					&.selected {
						border: 1px solid $primary;
					}
					&--selected-button {
						background-color: $light-blue;
					}
				}
			}
			&__post-container {
				width: 100%;
				h6 {
					font-size: 2.5rem;
					text-transform: none;
					font-weight: 700;
				}
				&__post {
					animation: appear 1.5s;
					@include container-default(
						$flex-direction: row,
						$justify-content: space-between,
						$align-items: stretch
					);
					flex-wrap: wrap;
					margin-bottom: 2rem;
					&--texts {
						@include container-default($justify-content: flex-start);
						background-color: $white;
						border-radius: $medium-border;
						flex-basis: 49%;
						padding: 2rem;
						//min-width: 450px;
						label {
							font-size: 1.4rem;
							font-weight: 400;
							margin-bottom: 1rem;
							margin-top: 2.5rem;
						}
						&__some-input {
							width: 100%;
							//padding: 2rem;
							textarea {
								width: 100%;
								min-height: 20rem;
								border: 1px solid $light-gray-border;
								border-radius: $medium-border;
								padding: 0.5rem;
								resize: none;
							}
							&--title {
								// padding-bottom: 2rem;
							}
							.input__container {
								margin: 0rem;
								.input__row .input__border {
									border: 1px solid $gray-border;
								}
							}
							&--checkbox {
								display: flex;
								align-items: center;
								margin-left: 0.6rem;
								margin-bottom: 2rem;
								input {
									transform: scale(1.7);
								}
								p {
									margin-bottom: 0;
									margin: 0 0 0 1rem;
								}
								img {
									border-radius: 5px;
									margin-left: 1.3rem;
									height: 1.5rem;
								}
							}
							&--link-input {
								align-self: flex-start;
								width: 90%;
								margin-left: 1rem;
								p {
									font-size: 1.4rem;
									margin: 0;
									margin-bottom: 0.5rem;
								}
							}
							&--statistic {
								border: 1px solid #e6e8ea;
								padding: 2rem;
								border-radius: 10px;
								&--title {
									margin-top: 1rem;
									margin-bottom: 1rem;
									display: flex;
									align-items: center;
								}
								&--info-box {
									ul {
										margin: 0;
										display: flex;
										width: 100%;
										flex-wrap: wrap;
										padding: 0;
										li {
											margin-right: 2rem;
											margin-bottom: 1rem;
											margin-top: 1rem;
											min-width: 14rem;
											dd {
												margin: 0;
												font-weight: normal;
											}
										}
									}
								}
							}
						}
						&__ai-selection {
							width: 100%;
							padding: 2rem;
							margin-top: 3rem;
							border-radius: 0.75rem;
							background: #fff;
							box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.04);
							&__item {
								@include container-default(
									$flex-direction: row,
									$justify-content: space-between
								);
								&--name {
									font-size: 1.4rem;
								}
								&--value {
									font-size: 1.4rem;
									max-width: 70%;
									margin-right: 1.5rem;
									.content-selection {
										justify-content: flex-end;
										&__item {
											margin: 1rem 0rem 1rem 1rem;
											cursor: none;
										}
									}
								}
							}
							&__divider {
								margin: 1rem 0rem;
								@include divider($width: '100%');
							}
							&__divider:first-child {
								margin: 2rem 0rem;
								@include divider($width: '100%');
							}
						}
					}
					&__right-side {
						@include container-default($align-items: flex-start);
						flex-basis: 49%;
						height: 100%;
						//min-width: 450px;
						&__slideshow {
							position: relative;
							background-color: $white;
							border-radius: $medium-border;
							padding: 2rem;
							margin-bottom: 2rem;
							width: 100%;
							h6 {
								margin-bottom: 1rem;
							}
							p {
								font-size: 1.4rem;
							}
							&--add-button {
								display: flex;
								flex-direction: row;
								.button-container {
									margin: 2rem 3rem 2rem 0rem;
									.add-photo {
										padding: 0.4rem 0rem;
										.button-text {
											min-width: 14rem;
										}
									}
									.ai-generate {
										padding: 0.15rem 2rem;
										.button-text {
											display: flex;
											align-items: center;
											img {
												height: 2.5rem;
												margin-right: 1rem;
											}
										}
									}
								}
							}
						}
						&__publish-to-other {
							background-color: $white;
							border-radius: $medium-border;
							padding: 3rem;
							width: 100%;
							height: 13rem;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							h6 {
								margin-bottom: 1rem;
							}
							&__switch-button-and-text-container {
								display: flex;
								justify-content: space-between;
								width: 70%;
								&__switch-button-with-text {
									display: flex;
									flex-direction: row;
									justify-content: space-between;
									p {
										margin-left: 1.5rem;
									}
								}
							}
						}
					}

					&--publish {
						@include container-default(
							$flex-direction: row,
							$align-items: center,
							$justify-content: flex-start
						);
						flex-wrap: wrap;
						height: 13rem;
						padding: 2rem;
						background-color: $white;
						border-radius: $medium-border;
						width: 100%;
						justify-content: space-between;
						.button-container {
							margin: 1rem;
						}
						&--time-picker {
							margin-left: 5rem;
							p {
								margin-bottom: 0rem;
								font-weight: 400;
								font-size: 1.2rem;
							}
							.MuiInputBase-input-MuiOutlinedInput-input {
								padding: 1.2rem 1rem;
							}
							.MuiTextField-root {
								input {
									padding: 1.2rem 1rem;
								}
							}
						}
						&__switch-container {
							@include container-default(
								$align-items: flex-start,
								$justify-content: space-between
							);
							height: 100%;
							&--name {
								font-weight: 700;
								font-size: 1.8rem;
								margin-bottom: 0rem;
							}
							&--switch {
								display: flex;
								margin-top: 1rem;
								p {
									margin-left: 1rem;
									margin-bottom: 0rem;
									font-weight: 400;
								}
							}
						}

						&--delete-button {
							// margin-right: auto;
						}
						&--schedule-button {
							&--facebook,
							&--instagram {
								margin: 0 2rem;
								p {
									margin-bottom: 0.5rem;
									font-size: 1.2rem;
								}
							}
							display: flex;
							margin-right: auto;
						}
						&--times {
							&--boxes {
								display: flex;
								flex-wrap: wrap;
								p {
									border: $tiniest-border solid #e5e5ea;
									border-radius: 4px;
									margin: 0.5rem;
									padding: 1rem;
								}
							}
						}
					}
				}

				&--inputs-holder {
					&--datepicker {
					}
					&--buttons {
						display: flex;
					}
				}
			}
			&__image-cropper {
				min-width: 90vw;
			}
			flex-basis: 50%;
			.calendar {
				padding: 0rem;
			}
			.calendar__event-wrapper__time-group .circle-icon {
				margin-right: 0.3rem;
			}
			margin-left: 2rem;
		}
	}

	&--images {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.content-selection {
	display: flex;
	flex-wrap: wrap;
	margin-left: 1rem;
	&__item {
		background-color: $background-darker-gray;
		border-radius: 6px;
		padding: 0.5rem 1rem;
		margin: 0rem 1rem 1rem 0rem;
		&.selected {
			@include selected-item();
		}
		cursor: pointer;
	}
}
