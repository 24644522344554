.tool-tip {
	z-index: 999999 !important;

	&__title {
		margin-top: 1rem;
		max-width: 200px;
		color: $white;
		font-size: 1.2rem;
		font-weight: $font-regular;
		font-family: $lato;
	}
}
