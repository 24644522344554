.error-boundary {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	&__box {
		padding: 3.2rem;
		border: 0.05rem solid $dark-gray;
		border-radius: 2rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		p {
			margin-bottom: 1.6rem;
		}
		&-buttons {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.button-left {
			margin-right: 1.6rem;
		}
	}
}
