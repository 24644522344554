.task-table {
	width: 100%;
	height: 100%;
	background-color: $background-gray;
	border-radius: $medium-border; //todo add medium border
	// margin-bottom: 3rem;
	padding: 1rem 2rem;
	&.white {
		background-color: $white;
	}
	&__header {
		// height: 10rem;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		// h5,
		// p {
		// 	color: $black;
		// 	font-family: $lato;
		// 	text-transform: capitalize;
		// 	font-size: $font-size-sub-header;
		// }
		h5 {
			margin-top: 1.5rem;
			margin-bottom: $padding-smaller;
		}
		.nav {
			@include nav-style();
			max-height: 3rem;
			.item {
				margin-left: auto;
			}
		}
		.switch {
			@include container-default(
				$flex-direction: row,
				$align-items: center,
				$justify-content: space-between
			);
			margin-left: auto;
			p {
				margin-right: 1rem;
			}
		}
		&--date {
			margin-top: 2rem;
		}
	}
	&__tasks {
		margin: 1rem 0;
		max-height: 70vh;
		width: 100%;
		overflow-y: scroll;
		padding-right: 1rem;
		&__load-button {
			cursor: pointer;
			text-align: center;
			padding: 1rem;
			&.loaded {
				cursor: none;
			}
		}
		&-row {
			border-top: 1px solid $gray;
			display: flex;
			flex-direction: row;
			width: 100%;
			// height: 6rem;
			align-items: center;
			font-family: $lato;
			font-weight: bold;
			color: $black;
			cursor: pointer;
			&.task {
				padding: 0.5rem 0;
			}
			&--task {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				flex-direction: column;

				p {
					font-family: $lato;
					font-weight: bold;
					color: $black;
					font-size: $font-size-small;
					padding-top: 0.25rem;
					margin-bottom: 0;
				}
				&--values {
					@include container-default(
						$flex-direction: row,
						$justify-content: flex-start
					);
					margin-left: 4rem;
					width: 100%;
					p {
						font-family: $lato;
						// font-weight: bold;
						// color: $black;
						// font-size: 1.6rem;
						padding: 0.25rem 0;
						font-weight: 400;
						font-size: $font-size-small;
						align-self: flex-start;
						margin-left: 2rem;
						color: $dark-blue;
					}
					&__comments {
						@include container-default(
							$flex-direction: row,
							$align-items: center,
							$justify-content: center
						);
						p {
							margin-bottom: 0.25rem;
							margin-right: 0.5rem;
							margin-left: 0;
						}
					}
					&-value {
						font-weight: 400;
						font-size: $font-size-small;
						align-self: flex-start;
						margin-left: 4rem;
						color: $dark-blue;
					}
				}
				&-description {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					p {
						font-family: $lato;
						font-weight: bold;
						color: $primary;
						font-size: 1.3rem;
						padding-top: 0.25rem;
						margin-bottom: 0;
					}
					.suggestion-icon {
						height: $icon-small;
						width: $icon-small;
						color: $blue;
						margin: $padding-small;
						font-size: $icon-small;
					}
				}
			}
			.flex {
				padding: 0 0.5rem;
				width: 22rem;
				display: flex;
				justify-content: space-between;
				margin-left: auto;
				.buttons {
					width: 16rem;
					text-align: center;
					p {
						font-family: $lato;
						margin: 0;
						color: $dark-blue;
						font-size: $font-size-small;
					}
					p:hover {
						cursor: pointer;
					}
					.disabled {
						color: $gray;
					}
					.thin {
						font-weight: 100 !important;
					}
					:first-child {
						margin-bottom: 1.5rem;
					}
				}
				.icons {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 6rem;
					i {
						color: $light-gray2;
					}
				}
			}
		}
		&-expanded {
			@include box-shadow-light();
			@include container-default(
				$flex-direction: column,
				$justify-content: center,
				$align-items: flex-start
			);
			margin-bottom: 2rem;
			width: 100%;
			background-color: $background-gray;
			border-radius: $medium-border;
			padding: 1.5rem;
			p {
				color: $black;
				font-family: $lato;
				margin-bottom: 0.25rem;
				font-size: 1.4rem;
			}
			&--button {
				align-self: flex-end;
				width: 25%;
			}
		}
	}
}
