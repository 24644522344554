.information-bar-trial-version,
.information-bar-warning-version,
.information-bar-basic-version {
	background-color: $turqoise;
	padding: 1.5rem;
	display: flex;
	width: 100%;
	justify-content: space-between;
	cursor: pointer;
	p {
		color: $white;
		margin-bottom: 0%;
	}
}
.information-bar-warning-version {
	background-color: $light-orange;
}
