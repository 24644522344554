.checkbox__container {
	display: flex;
	align-items: center;
	margin-left: -12px;
	&-label {
		&:hover {
			cursor: pointer;
		}
	}

	.MuiSvgIcon-root {
		width: 2rem;
		height: 2rem;
	}
	.Mui-disabled {
		color: $gray !important;
	}
}
