.signup-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;

	.input__label {
		color: $blue;
	}
	.button-container {
		position: relative;
		.button-text {
			color: $white;
		}
		&.signup-form__submit-button {
			margin: $padding-small 0;
		}
	}
	&__signin-button-text {
		text-align: center;
		color: $blue;
		font-size: $font-size-base;
		font-weight: $font-bold;
	}
	&__button-bottom-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	&__wrapper-group {
		&-1,
		&-2 {
			margin-bottom: $padding-medium-small;
		}
	}
	.facebook-button,
	.google-button {
		position: relative;
		display: flex;
		margin-bottom: $padding-small;
		align-items: center;
		justify-content: center;

		a {
			width: 100%;
			border-radius: $small-border;
			padding: $padding-smaller $padding-medium;
			text-align: center;
			flex-direction: row;
			font-family: $lato;
			font-size: $font-size;
			min-width: 16rem;
			&:hover {
				text-decoration: none;
			}
		}
		.button-icon {
			position: absolute;
			z-index: 1;
			left: $padding-medium-small;
			height: $font-size-large;
			width: $font-size-large;
			display: flex;
			align-items: center;
			justify-content: center;
			.fa-facebook {
				color: $white;
				font-size: $font-size-header;

				&::before {
					vertical-align: middle;
				}
			}
		}
	}
	.facebook-button {
		a {
			background-color: $blue;
			.button-text {
				color: $white;
			}
		}
	}
	.google-button {
		a {
			background-color: $white;
		}
		.button-text {
			color: $default-text-color;
		}
	}
}
