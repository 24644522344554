.modal-table {
	border: 1px solid $light-gray-border;
	padding: 2rem;
	margin-top: 3rem;
	width: 100%;
	border-radius: $medium-border;
	&__action-text {
		font-family: $lato;
		font-style: normal;
		font-size: 1.3rem;
		font-weight: 600;
		color: $dark-blue;
	}
	&__header {
		font-family: $lato;
		font-style: normal;
		font-weight: 700;
		font-size: 1.3rem;
		line-height: 15px;
		color: $black;
	}

	@include mui-box-list();

	.MuiTableBody-root {
		.MuiTableRow-root {
			background-color: $white !important;
		}
	}

	.MuiTableCell-root {
		@include modal-table-text();
	}

	.MuiTableRow-root {
		.MuiTableCell-root {
			@include modal-table-text();
			padding: 0.5rem;
		}
	}
}

.modal-table h6 {
	font-size: 1.6rem;
	margin-bottom: 1rem;
}
