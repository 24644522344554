.category-creator-page {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
	&__modal {
		@include container-default(
			$align-items: center,
			$justify-content: space-around
		);
		h4 {
			width: 60%;
			color: $dark-blue;
			font-size: 1.6rem;
			margin: 1rem 0;
		}
		flex: 1;
		min-height: 10rem;
		&--keywords {
			margin-left: 4rem;
			align-self: flex-start;
		}
		&--close-button {
			margin-top: auto;
		}
	}
	&__content {
		margin-top: 2rem;
		@include container-default($flex-direction: column);
		overflow-y: scroll;
		height: 100%;
		&--container {
			background-color: $background-gray;
			margin: 2rem 1.5rem;
			height: 100%;
			width: 95%;
			border-radius: 1.4rem;
			@include box-shadow-light();
			.blog-post-creator__keyword {
				border-top-left-radius: 1.4rem;
				border-bottom-left-radius: 1.4rem;
			}
			.button-container {
				//margin: unset;
				//padding: unset;
				.button-blue {
					justify-content: flex-start;
					background-color: unset;
					padding: unset;
					margin: unset;
					.button-text {
						&.icon-start {
							padding: unset;
							margin: unset;
							color: #182550;
							display: flex;
							align-items: center;
							justify-content: flex-start;
						}
					}
				}
			}
			&--keyword-item {
				@include container-default(
					$align-items: center,
					$justify-content: flex-start,
					$flex-direction: row
				);
				p {
					margin-bottom: 0;
				}
			}

			&--tracking-editor {
				@include container-default(
					$align-items: center,
					$justify-content: flex-start,
					$flex-direction: row
				);
				flex-direction: column;
				align-items: flex-start;
				margin-left: 5%;
				margin-bottom: 5%;
				.selector {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					width: 100%;
					.dropdown {
						width: 50%;
					}
					.button-container {
						margin: unset;
					}
				}
			}

			&--file {
				@include container-default(
					$align-items: center,
					$justify-content: center,
					$flex-direction: row
				);
				margin-top: 1rem;
				i {
					color: $dark-blue;
					font-size: 2rem;
				}
				p {
					font-size: 1.2rem;
				}
				border-radius: $small-border;

				&-input {
					input[type='file'] {
						display: none;
					}
					cursor: pointer;
				}
			}

			&--search {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: stretch;
				.input {
					.text {
					}
					.textarea {
						scrollbar-width: none;
						width: 100%;
						min-height: 20rem;
						border: 1px solid #e6e8ea;
						padding: 0.5rem;
						resize: none;
					}
				}
				flex: 1;
				height: auto;
				margin-left: 5%;
				margin-bottom: 5%;
				&--search-button {
					@include container-default($flex-direction: row);
					.button-container {
						margin: 2rem;
					}
					.button-text {
						font-size: $small-font-size;
					}
					.button-blue {
						font-size: $small-font-size;
						width: 150px;
						background-color: $turqoise;
					}
					.button-gray {
						font-size: $small-font-size;
						width: 150px;
						background-color: $light-gray;
						.button-text {
							color: $black;
						}
					}
				}
			}

			&__label {
				@include container-default(
					$flex-direction: row,
					$align-items: flex-end,
					$justify-content: flex-start
				);
				margin-left: 5%;
				margin-bottom: 3rem;
				.input__container {
					flex-basis: 40%;
				}
				.MuiInputLabel-root,
				.MuiAutocomplete-inputRoot {
					background-color: white;
					font-size: $default-size;
				}
				.Mui-focused {
					font-size: $default-size;
				}
				&--add-label {
					margin-left: 2rem;
					flex-basis: 25%;
					.MuiAutocomplete-root {
						width: 100%;
					}
				}
				&--select {
					flex: 1;
					margin-left: 2rem;
					max-width: 60rem;
					.MuiAutocomplete-root {
						//width: 90%;
						width: 100%;
						padding-right: 2rem;
					}
					.MuiInputLabel-root,
					.MuiAutocomplete-inputRoot {
						background-color: white;
						font-size: $default-size;
					}
					.Mui-focused {
						font-size: $default-size;
					}
				}
				.button-container {
					margin: 2rem 2rem 1rem;
					width: 150px;
				}
				&--display {
					@include container-default($flex-direction: row);
					margin-bottom: 0.5rem;
					&.item {
						border: 1px solid black;
						border-radius: 5px;
						padding: 0.5rem;
						margin-bottom: 1rem;
					}
					&.item:hover + &.remove,
					&.remove:hover {
						display: inline-block;
					}
					&.remove {
						position: relative;
						top: -15px;
						right: 20px;
						display: none;
						margin-right: -30px;
					}
				}
			}

			&__table {
				background-color: $white;
				margin-bottom: auto;
				border-radius: 1.4rem;
				padding-top: 2rem;
				@include box-shadow-light();
				.custom-table {
					@include container-default();
					&__headers {
						width: 90%;
						> :first-child {
							flex-basis: 30%;
							text-align: start;
						}
						> :nth-child(2) {
							flex-basis: 20%;
							text-align: start;
							// width: 18px;
							// max-width: 18px;
							// margin-right: 1rem;
						}
						// > :nth-child(3) {
						// 	flex-basis: 7.5%;
						// 	text-align: start;
						// 	// flex-basis: 20%;
						// 	// text-align: start;
						// }
						// > :nth-child(4) {
						// 	flex-basis: 7.5%;
						// 	text-align: start;
						// }
						:nth-child(n + 3):nth-child(-n + 6) {
							flex-basis: 10%;
							text-align: start;
						}
						// :nth-child(9) {
						// 	flex: 1;
						// }
						// > :nth-child(5) {
						// 	flex-basis: 15%;
						// 	text-align: start;
						// }
						// > :nth-child(6) {
						// 	flex-basis: 15%;
						// 	text-align: start;
						// }
						> :last-child {
							// flex-basis: 10%;
							flex-basis: 5%;
							text-align: start;
						}
						&--item {
							text-align: center;
							margin-left: 0rem;
						}
					}
					&__keyword-table {
						@include container-default();
						width: 100%;

						&__row {
							@include container-default(
								$flex-direction: column,
								$justify-content: space-between
							);
							width: 100%;
							cursor: pointer;
							// margin-bottom: 1rem;

							&--divider {
								width: 90%;
								color: #034079;
							}

							&--headers {
								@include container-default(
									$flex-direction: row,
									$justify-content: flex-start
								);
								width: 90%;

								&--item {
									margin-left: 0rem;
									font-weight: 400;
									text-align: center;
									flex-basis: 20%;
									width: 90%;
									&.yellow {
										color: rgb(255, 217, 0);
									}
									&.red {
										color: red;
									}
									&.green {
										color: green;
									}
									&.expanded {
										flex-basis: 20%;
										&.yellow {
											color: rgb(255, 217, 0);
										}
										&.red {
											color: red;
										}
										&.green {
											color: green;
										}
									}
									&.products-and-clicks {
										flex-basis: 10%;
										text-align: start;
									}
									&.conversions-rate-and-value {
										flex-basis: 10%;
										text-align: start;
									}
									&.actions {
										flex-basis: 5%;
										justify-content: center;
									}
									&.positive {
										color: #10a003;
										font-weight: bold;
									}

									&.negative {
										color: #b70606;
										font-weight: bold;
									}
									&.name {
										display: flex;
										align-items: center;
										flex-basis: 30%;
										font-weight: 500;
										text-align: start;
										&.expanded {
											flex-basis: 16.5%;
										}
									}
									&.description {
										flex-basis: 20%;
										text-align: start;
										display: flex;
										.found {
											padding: 0.5rem 1rem;
											border-radius: 1rem;
											background-color: #d1ffc1;
											color: #4e9934;
										}
										.not-found {
											padding: 0.5rem 1rem;
											border-radius: 1rem;
											background-color: #ffc1c1;
											color: #db0d0d;
										}
										&.expanded {
											flex-basis: 7%;
										}
									}
									&.url {
										overflow: hidden;
										position: relative;
										display: inline-block;
										flex: 1;
										text-overflow: ellipsis;
										white-space: nowrap;
										text-align: left;
										margin-left: 3rem;
									}
									&.label {
										flex-basis: 10%;
										@include container-default(
											$flex-direction: row,
											$justify-content: center
										);
										margin-left: 1rem;
										&.expanded {
											flex-basis: 7%;
										}

										.MuiBadge-badge {
											font-size: 1rem;
											top: 5px;
											right: -10px;
											background-color: $dark-blue;
										}
									}
									&.chip {
										background-color: #fff !important;
										border: 1.5px solid $dark-blue;
										border-radius: 100px;
										font-size: 1.4rem;
										padding: 0.5rem;
										min-width: 100%;
									}
								}
							}
							&--expanded {
								margin-bottom: 0;
								width: 90%;
								&--date {
									width: 7.5%;
									color: $dark-blue;
									max-width: 7.5%;
									margin-right: 0.5rem;
								}
								&--delete-button {
									width: 100%;
									margin-left: auto;
									.button-container {
										max-width: 200px;
										margin: 0;
										margin-left: auto;
										.button-text {
											font-size: 1.4rem;
											padding: 0.5rem;
										}
									}
								}
								&--subcategory-and-product:first-child {
									margin-top: 1rem;
								}
								&--subcategory-and-product {
									width: 100%;
									&--subcategory {
										margin-left: 0.25rem;
										display: flex;
										flex-direction: row;
										align-items: center;
										font-weight: 400;
										text-align: center;
										width: 100%;
										&.blank {
											flex-basis: 5%;
											text-align: start;
											&.expanded {
												flex-basis: 7%;
											}
										}
										&.name {
											display: flex;
											align-items: center;
											padding-left: 2rem;
											flex-basis: 30%;
											font-weight: 500;
											text-align: start;
											&.expanded {
												flex-basis: 16.5%;
											}
										}
										&.description {
											flex-basis: 20%;
											text-align: start;
											display: flex;
											.found {
												padding: 0.5rem 1rem;
												border-radius: 1rem;
												background-color: #d1ffc1;
												color: #4e9934;
											}
											.not-found {
												padding: 0.5rem 1rem;
												border-radius: 1rem;
												background-color: #ffc1c1;
												color: #db0d0d;
											}
											&.expanded {
												flex-basis: 7%;
											}
										}
										&.products-and-clicks {
											flex-basis: 10%;
											text-align: start;
										}
										&.conversions-rate-and-value {
											flex-basis: 10%;
											text-align: start;
										}
										&.actions {
											flex-basis: 5%;
											justify-content: center;
										}
									}
									&--products-container {
										&--products {
											margin-left: 0.25rem;
											display: flex;
											flex-direction: row;
											align-items: center;
											font-weight: 400;
											text-align: center;
											width: 100%;
											&.blank {
												flex-basis: 5%;
												text-align: start;
												&.expanded {
													flex-basis: 7%;
												}
											}
											&.name {
												display: flex;
												align-items: center;
												padding-left: 4rem;
												flex-basis: 30%;
												font-weight: 500;
												text-align: start;
												&.expanded {
													flex-basis: 16.5%;
												}
											}
											&.description {
												flex-basis: 20%;
												text-align: start;
												display: flex;
												.found {
													padding: 0.5rem 1rem;
													border-radius: 1rem;
													background-color: #d1ffc1;
													color: #4e9934;
												}
												.not-found {
													padding: 0.5rem 1rem;
													border-radius: 1rem;
													background-color: #ffc1c1;
													color: #db0d0d;
												}
												&.expanded {
													flex-basis: 7%;
												}
											}
											&.products-and-clicks {
												flex-basis: 10%;
												text-align: start;
											}
											&.conversions-rate-and-value {
												flex-basis: 10%;
												text-align: start;
											}
											&.actions {
												flex-basis: 5%;
												justify-content: center;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.competition-analysis {
			padding: 20px;
			background-color: #fff;
			border-radius: 1.4rem;

			&__headers {
				.button-container {
					margin: unset;
				}
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: unset;
				padding-bottom: 3.5rem;
			}
			&__table {
				h3 {
					font-size: 2rem;
					margin-bottom: 2rem;
				}
				.custom-table {
					&__headers {
						justify-content: space-between;
						margin-bottom: 1rem;
						&--item {
							flex-basis: 10%;
							margin-left: unset;
						}
						> :first-child {
							flex-basis: 20%;
						}
						> :last-child {
							flex-basis: 10%;
							margin-left: unset;
							display: flex;
							justify-content: flex-end;
						}
					}
					&__keyword-table {
						&__row {
							&--headers {
								justify-content: space-between;
								&--item {
									flex-basis: 10%;
									margin-left: unset;
									&.keyword {
										margin-left: unset;
										flex-basis: 20%;
									}
									&.create-article-button {
										flex-basis: 10%;
										margin-left: unset;
										display: flex;
										justify-content: flex-end;
										align-items: stretch;
										flex-direction: row;
									}
								}
							}
							&--divider {
								width: 100%;
							}
						}
					}
				}
			}
			&__reviews {
				h3 {
					font-size: 2rem;
					margin-bottom: 2rem;
					margin-top: 4rem;
				}
				p {
					font-size: 1.7rem;
					font-weight: 400;
				}
			}
		}
	}
}
