// Core layout stylesheet

#app {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	header {
		padding: $padding-medium $padding-large;
		opacity: 1;
		animation: appearNav $appear;
	}
	.hidenav {
		opacity: 0.1;
		animation: hideNav $disappear;
	}

	main {
		@include container-default($justify-content: flex-start);
		width: 100%;

		&.unauthorized-layout {
			height: auto;
		}

		&.authorized-layout {
			height: 100vh;

			&.open {
				transition: max-width 0.5s ease-out;
				// Total view width - sidenav size when open
				max-width: calc(100vw - 28rem);
			}
			&.closed {
				// Total view width - sidenav size when closed
				transition: max-width 0.5s ease-in;
				max-width: calc(100vw - 7rem);
			}
			&.report {
				height: 100%;
				display: flex;
				justify-content: center;
			}
		}
	}
}

.update-modal {
	&__content {
		padding: 2rem;
	}
}
.unauthorized-layout {
	display: flex;
	width: 100%;
	height: 100%;
	padding: $padding-large;

	input {
		font-family: $lato;
	}
	// input::placeholder {
	// 	font-family: $lato;
	// }
	.input__error {
		font-family: $lato;
	}
	.button-text {
		font-family: $lato;
		color: $white;
	}
	&__logo {
		max-width: 100%;
		&-container {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: $padding-large $padding-extra-large;
		}
	}
	&__body {
		display: flex;
		flex-direction: column;
		@include box-shadow-default();
		flex: 1;
		margin: $padding-large $padding-extra-large;
		padding: $padding-large $padding-large;
		background-color: $login-bg-color;
		border-radius: $small-border;
		position: relative;
		&-content {
			flex: 1;
		}
		&.reset-password {
			@extend .login__content;
			//max-width: 400px;
			margin: 0;
			background-color: $dark-blue;
		}
		&-icon {
			color: $turqoise;
			font-size: $font-size-icon;
			background-color: $white;
			// padding: $padding-medium-small;
			height: $normalize-size * 7;
			width: $normalize-size * 7;
			border-radius: $round-border;
			display: flex;
			align-items: center;
			justify-content: center;
			&-container {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: $padding-medium-small 0;
			}
		}
		&__login {
			height: 100vh;
			width: 100vw;
			background-color: $login-bg-color;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			&-logo {
				position: absolute;
				top: 2rem;
				left: 5rem;
				h1 {
					font-family: Arial, Helvetica, sans-serif;
					color: $white;
					font-weight: normal;
					text-transform: capitalize;
				}
			}
		}
	}
}
.authorized-layout {
	> * {
		font-family: $lato;
	}
	width: 100%;
}
.none {
	display: none;
}
