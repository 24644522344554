.crop {
	animation: appear 1.5s;
	.ord-ne::after,
	.ord-nw::after,
	.ord-se::after,
	.ord-sw::after {
		background-color: $primary;
		border-radius: 50%;
		border: 0.5px solid $light-gray-border;
		width: 14px;
		height: 14px;
	}
	@include container-default($align-items: stretch);
	max-height: 600px;
	background-color: $white;
	border-radius: $medium-border;
	margin-bottom: 2rem;
	&__container {
		@include container-default();
		// position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 80px;
		&__crop-description {
			padding: 2rem;
			p {
				font-size: 1.4rem;
			}
		}
	}
	&__add-file {
		margin: 2rem;
		&--description {
			font-weight: 400;
		}
		&__add-container {
			@include container-default();
			height: 30rem;
			border: 2px dashed $dark-blue;
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
			border-radius: 12px;
			background: $light-gray-border;
			h6 {
				margin-bottom: 2rem;
			}
		}
	}
	&__crop-container {
		@include container-default(
			$flex-direction: row,
			$justify-content: flex-start,
			$align-items: stretch
		);
		margin: 0rem 1rem;
		&__aspect-rations {
			@include container-default(
				$align-items: flex-start,
				$justify-content: space-between
			);
			flex-basis: 30%;
			margin-right: 2rem;
			&__item {
				@include container-default($flex-direction: row);
				width: 100%;
				background-color: $white;
				border-radius: $medium-border;
				border: 0.5px solid $light-gray-border;
				cursor: pointer;
				margin: 1rem;

				p {
					margin-bottom: 0;
					flex-basis: 70%;
					padding: 3rem 2rem;
				}
				&.active {
					border: 2px solid $primary;
					background-color: $primary;
					p {
						color: $white;
					}
				}
				&--image-container {
					@include container-default();
					max-width: 30%;
					flex-basis: 30%;
					background-color: $light-gray-border;
					border-radius: 0 8px 8px 0;
					align-self: stretch;
					img {
						margin: auto 0;
						max-width: 100%;
					}
					&.aspect-1-1 {
						img {
							margin: 0;
							width: 100%;
							height: 100%;
							object-fit: fill;
							border-radius: 0px 8px 8px 0px;
						}
					}
					&.aspect-191-1 {
						img {
							width: 100%;
							height: 60%;
							object-fit: cover;
						}
					}
					&.aspect-4-5 {
						img {
							width: 80%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}
			&__item:first-child {
				margin-top: 0rem;
			}
			&__item:last-child {
				margin-bottom: 0rem;
			}
		}
		&__buttons {
			@include container-default($flex-direction: row);
			margin-left: auto;
			max-width: 40rem;
			margin-right: 4rem;
		}
	}
	&__controls {
		@include container-default();
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 50%;
		transform: translateX(-50%);

		display: flex;
		align-items: center;
		margin: 1rem;

		&__container {
			min-height: 100%;
			width: 100%;
			background-color: $light-gray-border;
			@include container-default(
				$flex-direction: row,
				$justify-content: center
			);
			margin-right: 1rem;
			border-radius: 12px;
			&__cropper {
				max-height: 400px;
				max-width: 100%;

				margin: 2rem;

				// border: 2px solid $light-gray-border;
			}
			&__preview-canvas {
				display: none;
				margin: 2rem;

				max-width: 600px;
				canvas {
					@include box-shadow-default();
					border: 2px solid $light-gray-border;
				}
			}
		}
		&__slider-container {
			position: relative;
			width: 45%;
			margin: 1rem auto 1rem 2rem;
			&__text {
				align-self: flex-start;
			}
			&__slider {
				padding: 22px 0px;
				background-color: $white;
			}
		}
	}
}
