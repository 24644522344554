.suggestion-ad-modal {
	position: absolute;
	left: 0;
	z-index: 200000;
	.ads-modal__content {
		width: 100vw;
		&--container {
			width: 90%;
			overflow-x: visible;
			&__campaigns--items {
				width: 80%;
			}
		}
	}
	&__body {
		&__edit-ad {
			width: 100%;
			&-container {
				@include container-default(
					$flex-direction: row,
					$align-items: center,
					$justify-content: space-around
				);
				flex-wrap: wrap;
				&.buttons {
					justify-content: flex-end;
					align-items: flex-end;
					flex-direction: row;
					margin-bottom: 3rem;
				}
				.button-container {
					margin: 1rem;
				}
				width: 100%;
				&__ad-item {
					// @include container-default(
					// 	$flex-direction: row,
					// 	$justify-content: flex-end
					// );
					// p {
					// 	margin-right: 1rem;
					// 	margin-bottom: 0;
					// }
					// margin: 1rem;
					// cursor: pointer;
					flex-basis: 30%;
				}
			}
		}
	}
}
.tasks {
	height: 100%;
	width: 100%;
	&__content {
		height: 88vh;
		width: 100%;
		background-color: $background-gray;
		padding: 0;
		overflow-y: hidden;
		&__filter {
			width: auto;
			margin: 0 0.5rem;
			display: flex;
			border-radius: $small-border;
			text-align: center;
			padding: 0.4rem 0.5rem;
			background-color: $background-gray;
			span {
				color: $primary;
			}
			&.filter-button {
				background-color: $background-gray;
				.MuiButton-root {
					background-color: $background-gray;
				}
				span {
					color: $primary;
					font-size: 1.6rem;
					font-weight: 500;
				}
			}
			&.filter-search {
				background-color: white;
			}
		}
		&__switch {
			@include container-default($flex-direction: row);
			background-color: $background-gray;
			border-radius: $small-border;
			margin-left: auto;
		}
		&__filters-list {
			@include container-default(
				$flex-direction: row,
				$justify-content: flex-start,
				$align-items: center
			);
			flex-wrap: wrap;
			background-color: $white;
			padding: 0.5rem 2rem;
			border-top: 1px solid $background-gray;
			&__button {
				color: $primary;
			}
			&__item {
				@include container-default($flex-direction: row);
				margin: 0 0.5rem;
				border-radius: $small-border;
				text-align: center;
				padding: 0.5rem 1rem;
				background-color: $light-white-gray;
				&__priority-text {
					margin: 0 0.25rem;
				}
				p {
					margin-bottom: 0;
				}
				&__priority,
				&__category,
				&__avatar {
					margin: 0 0.4rem;
					font-weight: 400;
				}
				&__avatar {
					.MuiAvatar-root {
						margin-left: 0rem;
						border: 1px solid $background-gray;
						border-radius: 1rem;
						background-color: $white;
						width: 26px;
						height: 24px;
						color: $dark-blue;
					}
				}
				&__priority {
					@include container-default($flex-direction: row);
					background-color: $white;
					border-radius: $small-border;
				}
				&__priority:hover,
				&__avatar:hover,
				&__category:hover {
					cursor: pointer;
				}
			}
		}
		&-filter {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			row-gap: 1rem;
			background-color: $white;
			padding: 1rem 2rem;
			border-top: 1px solid #e5e5ea;

			i {
				color: $black;
				font-size: 3rem;
				margin-right: 1rem;
			}
			.item {
				width: auto;
				margin: 0 0.5rem;
				display: flex;
				border-radius: $small-border;
				text-align: center;
				padding: 1rem 1.5rem;
				background-color: $light-white-gray;
				p {
					color: $dark-blue;
				}
				.flex {
					display: flex;
					justify-content: center;
					align-items: center;
					p {
						margin: 0;
						font-weight: 500;
					}
					.count {
						color: $dark-gray;
						margin-left: 1rem;
						font-weight: bold;
						font-size: 1.4rem;
						display: flex;
						margin-bottom: -0.2rem;
					}
				}
			}
			.item:hover {
				cursor: pointer;
			}
			.active {
				background-color: $dark-blue;
				p {
					color: $white;
					font-weight: bold;
				}
				.flex {
					.count {
						color: $dark-yellow;
					}
				}
			}
		}
	}
}
