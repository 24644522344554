.selectable-component {
	border-radius: 5px;
	border: black solid 2px;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 90px;
	max-height: 90px;
	margin: auto;
	img {
		max-width: 80px;
		max-height: 80px;
	}
}
