.loading-circle-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	&--items {
		@include container-default();
		z-index: 400001;
		&__container {
			@include container-default(
				$flex-direction: row,
				$justify-content: space-around,
				$align-items: flex-end
			);
			width: 120px;
			height: 180px;
			@keyframes loadingBar {
				0% {
					height: 20px;
				}
				25% {
					height: 40px;
				}
				50% {
					height: 60px;
				}
				75% {
					height: 80px;
				}
				100% {
					height: 20px;
				}
			}
			&--item {
				background-color: white;
				height: 80px;
				width: 15px;
				border-radius: 25px;

				&.first {
					animation: loadingBar 1.5s linear;
					animation-iteration-count: infinite;
					animation-fill-mode: both;
					animation-direction: reverse;
				}
				&.second {
					animation: loadingBar 2s linear;
					animation-iteration-count: infinite;
					animation-fill-mode: both;
					animation-delay: 0.25s;
					animation-direction: reverse;
				}
				&.third {
					animation: loadingBar 1.5s linear;
					animation-iteration-count: infinite;
					animation-fill-mode: both;
					animation-delay: 0.5s;
					animation-direction: reverse;
				}
				&.fourth {
					animation: loadingBar 2s linear;
					animation-iteration-count: infinite;
					animation-fill-mode: both;
					animation-delay: 0.75s;
					animation-direction: reverse;
				}
			}
		}
		&--text {
			color: $white;
			font-weight: 500;
		}
	}
	.loading-circle {
		position: relative;
		&::before {
			content: '';
			display: block;
			padding-top: 100%;
		}
		svg {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			animation-name: loadingCircleSpin;
			animation-duration: 0.9s;
			animation-iteration-count: infinite;
			animation-timing-function: cubic-bezier(0.55, 0.25, 0.45, 0.75);
			@keyframes loadingCircleSpin {
				0% {
					transform: rotate(-45deg);
				}
				100% {
					transform: rotate(315deg);
				}
			}
		}
	}
}

.loading-screen {
	z-index: 50000000;
	// background-color: rgba($white, 0.7);
	background: hsla(0, 0%, 0%, 0.726);
	transition: $transition-default;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	.loading-circle-container {
		width: 100%;
		height: 100%;
		justify-content: center;
	}
}
