.ads-visualisation-page {
	height: 100%;
	width: 100%;
	&__container {
		@include container-default($flex-direction: row, $align-items: flex-start);
	}
	&__content {
		@include container-default($flex-direction: column);
		width: 100%;
		&--ads {
			max-width: 50%;
			overflow-y: scroll;
			max-height: 92vh;
			&.full {
				min-width: 100%;
			}
		}
		&--container {
			@include box-shadow-light();
			@include container-default($flex-direction: row);
			display: flex;
			flex: 1;
			position: relative;
			&.selectors {
				flex-direction: column;
				position: relative;
				width: 98%;
				height: 100%;
				.ads-visualisation-page__blur-overlay {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					background: rgba(255, 255, 255, 0.9);
					backdrop-filter: blur(4px);
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 10;
					.ads-visualisation-page__blur-content {
						text-align: center;
						padding: 2rem;
						background: white;
						border-radius: 8px;
						box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
						max-width: 90%;
						margin: 0 auto;
						h3 {
							margin-bottom: 1rem;
						}
						p {
							margin-bottom: 1.5rem;
						}
						.button-container {
							width: 300px;
							margin: 0 auto;

							button {
								width: 100%;
								min-width: 200px;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
					}
				}
			}
			&--divider {
				border-top: 0.5px solid $divider-color;
				width: 100%;
				margin: 2rem 0;
			}
			&--selector {
				&--container {
					width: 100%;
					&--top-contantainer {
						@include container-default(
							$align-items: center,
							$justify-content: space-between,
							$flex-direction: row
						);
						p {
							color: $dark-blue;
							font-weight: bold;
							font-size: 1.2rem;
						}
					}
				}
				&-title {
					text-transform: none;
					color: $black;
					font-size: 2.3rem;
					margin-bottom: 1.5rem;
					align-self: flex-start;
					&--hide-filters {
						cursor: pointer;
					}
				}
			}
			background-color: $light-gray;
			margin: 1rem auto;
			padding: $padding-medium-small;
			height: 100%;
			width: 98%;
			background-color: $white;
			margin-bottom: auto;

			&__selectors {
				@include container-default(
					$flex-direction: row,
					$align-items: flex-start
				);
				justify-content: flex-start;
				flex-wrap: wrap;
				width: 100%;
				// max-height: 10rem;

				&.dates {
					@include container-default(
						$flex-direction: row,
						$align-items: flex-start
					);
					p {
						text-align: left;
					}
					h6 {
						text-transform: none;
						color: $black;
						align-self: flex-start;
						font-size: 2rem;
						width: 100%;
						text-align: left;
						margin-left: 1rem;
					}
					.button-container {
						margin: 10% 5%;
					}
				}
				// h6 {
				// 	color: $dark-blue;
				// 	font-size: 1.6rem;
				// 	text-align: center;
				// }
				&__title {
					text-transform: none;
					color: $black;
					align-self: flex-start;
					font-size: 2rem;
					width: 100%;
					text-align: left;
					margin-left: 1rem;
					margin-bottom: 1rem;
				}
				&--item {
					flex-basis: 15%;
					margin-left: 1rem;
					&.property {
						flex-basis: 15%;
						margin-left: 3rem;
						color: $dark-gray;
					}
					&.title {
						display: flex;
						margin-bottom: 1rem;
						margin-left: 0;
						color: $dark-gray;
					}
					&.operator {
						flex-basis: 10%;
						color: $dark-gray;
					}
					&.value {
						text-align: center;
						flex-basis: 10%;
						color: $dark-gray;
						.input__container {
							margin: unset;
							.input__error {
								display: none;
							}
						}
					}
					&.options {
						margin-left: 2rem;
						align-self: flex-end;
						flex-basis: 15%;
						padding-bottom: 2px;
					}
					&--options {
						@include container-default(
							$flex-direction: row,
							$align-items: center,
							// $justify-content: space-around
						);

						align-self: center;
						cursor: pointer;
						border: 2px solid $dark-blue;
						border-radius: $small-border;
						margin-right: 2rem;

						&.nav-selector {
							margin-bottom: 1rem;
						}
						p {
							color: $black;
							margin-bottom: 0;
							font-size: 1.3rem;
							text-align: center;
						}
						&--item {
							background-color: $white;
							border-radius: $small-border;
							padding: 1rem;
							width: 100%;
							text-transform: capitalize;

							&.active {
								background-color: $dark-blue;
								color: $white;
								border-radius: 0;
							}
						}
					}
				}
				&--button {
					// flex-basis: 20%;
					margin-left: auto;
					align-self: flex-end;

					.button-container {
						padding: 2px;
						margin: unset;
						max-width: 20rem;
						min-width: 20rem;
					}
				}
				&--date {
					@include container-default(
						$flex-direction: column,
						$align-items: flex-start,
						$justify-content: center
					);
					// padding: 0 1rem;
					margin: 1rem 1rem 1rem 3rem;
					&.button {
						margin-left: auto;
					}
				}
			}

			&__account-details {
				@include container-default(
					$flex-direction: column,
					$align-items: center,
					$justify-content: space-around
				);

				flex-wrap: wrap;
				h6 {
					text-transform: none;
					color: $black;
					align-self: flex-start;
					font-size: 2rem;
					margin-left: 1rem;
				}
				h5 {
					text-transform: none;
					color: $dark-blue;
					font-size: 1.6rem;
				}
				width: 100%;
				&--items {
					flex-wrap: wrap;
					margin-left: 3rem;
					h6 {
						color: $dark-blue;
					}
					p {
						color: $black;
						text-align: left;
					}
					margin-top: 2rem;
					@include container-default($flex-direction: row);
					justify-content: flex-start;
					width: 100%;
					&--container {
						margin-bottom: 1rem;
						margin-right: 1.5rem;
						background-color: $gray-blue;
						border: 0.5px solid $light-gray-border;
						border-radius: $small-border;
						padding: 1.5rem;
						&--title {
							display: flex;
							flex-direction: row;
						}
						p {
							margin-bottom: 0;
						}
					}
				}
			}

			&__adGroups {
				&--items {
					@include container-default(
						$flex-direction: column,
						$align-items: flex-start,
						$justify-content: space-between
					);

					&.large {
						flex-flow: row wrap;
					}
				}
				&--item {
					@include box-shadow-light();
					background-color: $light-gray;
					padding: $padding-medium-small;
					border: 1px solid $light-green;
					border-radius: $small-border;
					margin-bottom: 1rem;
					h6 {
						width: 100%;
						font-size: 1.3rem;
						text-transform: none;
						text-align: left;
						margin-bottom: 1rem;
						word-break: break-word;
						cursor: pointer;
					}
					p {
						font-size: 1.3rem;
						margin-bottom: 0.5rem;
					}
					&--keyword {
						@include container-default(
							$flex-direction: column,
							$align-items: space-between,
							$justify-content: center
						);
						p {
							font-size: 1.2rem;
						}
						&--title {
							color: $dark-blue;
							align-self: center;
							margin: 1.5rem 0;

							font-size: 1.5rem;
						}
						&--view-more {
							color: $dark-blue;
							font-size: 1.6rem;
							font-weight: bold;
							cursor: pointer;
							width: 100%;
							text-align: center;
							margin-top: 1rem;
							align-self: center;
							margin-bottom: 0;
						}
						&-item {
							@include container-default(
								$flex-direction: row,
								$align-items: center,
								$justify-content: space-between
							);

							&.border {
								border-bottom: 1px solid $divider-color;
								margin-bottom: 0.5rem;
								p {
									margin-bottom: 0.5rem;
								}
							}

							&.key {
								word-break: break;
								// max-width: 50%;
							}
							&.blue {
								color: $dark-blue;
							}
							&.value {
								font-weight: bold;
								font-size: 1.2rem;
							}
							&.icon {
								width: 100%;
								@include container-default();
								cursor: pointer;
								.MuiSvgIcon-root {
									color: $dark-blue;
								}
							}

							&.small {
								flex-wrap: wrap;
							}
						}
					}
					&.large {
						width: 100%;

						// margin: 1rem 2.5%;
					}
					&.medium {
						width: 100%;
						min-height: 140px;
						// margin: 1rem 2%;
					}
					&.small {
						width: 100%;
						h6,
						p {
							font-size: 1.1rem;
						}
					}

					&.blue-border {
						border-color: $dark-blue;
					}
					&.red {
						background-color: #fff1f1;
					}
					&.green {
						background-color: rgb(210, 250, 210);
					}
					&.neutral {
						background-color: unset;
					}
				}
			}
			&__campaigns {
				@include container-default(
					$flex-direction: row,
					$justify-content: flex-start,
					$align-items: flex-start
				);
				width: 100%;
				// margin-left: 75px;
				&.scrollable {
					width: unset;
					overflow: scroll;
				}

				&--container {
					margin: 0 0.5rem;
					&.large {
						width: 400px;
					}
					&.medium {
						width: 200px;
						flex-flow: row wrap;
					}
					&.small {
						width: 125px;
						flex-flow: row wrap;
					}
				}

				&--item {
					@include box-shadow-light();
					@include container-default(
						$flex-direction: row,
						$align-items: center,
						$justify-content: space-between
					);
					&.invisible {
						visibility: hidden;
					}
					&-header {
						width: 100%;
						@include container-default(
							$align-items: center,
							$justify-content: center,
							$flex-direction: row
						);
						.edit {
							margin-bottom: 1.5rem;
							padding: 0.5rem 1rem;
							cursor: pointer;
							flex-basis: 20%;
							justify-content: flex-end;
							p {
								margin: unset;
								text-decoration: underline;
								padding-top: 0.2rem;
							}
						}
					}
					background-color: $light-gray;
					// margin: 2rem 1.5rem;
					padding: $padding-medium-small;
					flex-basis: 20%;
					margin-bottom: 1rem;

					background-color: $white;
					border-radius: $small-border;
					border: 1px solid #ed0000b2;
					p {
						font-size: 1.2rem;
					}
					h6 {
						width: 100%;
						font-size: 1.3rem;
						text-transform: none;
						text-align: left;
						margin-bottom: 1.5rem;
						cursor: pointer;
					}
					&.large {
						min-height: 220px;
						width: 400px;
						flex-flow: column nowrap;
						align-items: stretch;
						padding: 2rem;
					}
					&.medium {
						min-height: 100px;
						width: 200px;
						flex-flow: row wrap;
					}

					&.small {
						min-height: 100px;
						width: 125px;
						flex-flow: row wrap;
						h6 {
							font-size: 1rem;
						}
					}
					&.red {
						background-color: #fff1f1;
					}
					&.green {
						background-color: rgb(210, 250, 210);
					}
					&.neutral {
						background-color: unset;
					}
					&.open {
						height: unset;
					}
					&--hidden {
						max-height: 0;
						overflow: hidden;
						transition: max-height 0.6s ease-in-out;
						&.medium {
							max-height: 50px;
							width: 100%;
						}
						&.open {
							max-height: 400px;
						}
					}
				}
			}
			// .section-labels {
			// 	position: absolute;
			// 	left: 0;
			// 	top: 0;
			// 	bottom: 0; // This ensures full height
			// 	display: flex;
			// 	flex-direction: column;
			// 	min-width: 75px;
			// 	height: 98%;
			// 	padding-left: 1rem;

			// 	.section-label {
			// 		font-size: 14px;
			// 		color: #333;
			// 		font-weight: 500;
			// 		height: 100%;

			// 		// Adjust height based on campaign/ad group size
			// 		&.campaigns {
			// 			flex-grow: 1;
			// 			flex-basis: 50%;
			// 			padding-top: 1rem;
			// 		}

			// 		&.ad-groups {
			// 			flex-grow: 1;
			// 			flex-basis: 50%;
			// 			&.medium,
			// 			&.small {
			// 				margin-bottom: 4.5rem;
			// 			}
			// 		}
			// 	}
			// }
		}
	}
}

.MuiInputBase-input {
	font-size: 1.6rem !important;
	font-family: $lato !important;
}
.MuiTypography-body2 {
	font-size: 1.4rem !important;
	font-family: $lato !important;
}
.MuiTypography-caption {
	font-size: 1.4rem !important;
	font-family: $lato !important;
}
.MuiTypography-body1 {
	font-size: 1.4rem !important;
	font-family: $lato !important;
}

.ads-modal__content {
	position: fixed;
	width: 200vw;
	height: 200vh;
	z-index: 10000;
	background-color: rgba($light-gray, 0.7);
	top: 0;
	&__warning-modal {
		display: flex;
		flex-direction: column;
		align-items: center;
		&__button {
			display: flex;
			flex-direction: row;
			justify-content: center;
		}
	}
	&--container {
		position: relative;
		@include container-default($align-items: center, $justify-content: center);
		@include box-shadow-light();
		margin: auto;
		margin-top: 15vh;
		background-color: $white;
		width: 80%;
		max-width: 800px;
		// min-height: 500px;
		max-height: 80vh;
		&-close {
			width: 100%;
			p {
				font-size: 3rem;
				text-align: center;
				padding-left: 6rem;
				// padding-top: 2rem;
			}
			&-edit {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				margin-top: 1.5rem;
				cursor: pointer;
				color: $gray;
				&.active {
					.MuiSvgIcon-root {
						color: $light-blue;
					}
				}
			}
		}

		&__enable-editing {
			display: flex;
			align-items: center;
			flex-direction: row;
			flex-wrap: nowrap;
			margin-left: auto;
			margin-right: 3rem;
			p {
				margin-right: 2rem;
			}
		}

		&__campaigns--items {
			@include container-default(
				$flex-direction: column,
				$justify-content: space-between,
				$align-items: center
			);
			overflow: scroll;
			width: 100%;
			// margin-top: 5rem;
			&.buttons-container {
				display: flex;
				align-items: center;
				flex-direction: row;
				justify-content: center;
				&--button {
					margin-top: auto;
				}
			}
			&-header {
				width: 100%;
				.input__container {
					margin-left: 20%;
					width: 60%;
					margin-bottom: 2rem;
					.input__row {
						width: 100%;
					}
					p {
						margin: unset;
					}
				}
			}
			.input__container {
				// align-items: center;
				width: 61%;
				// margin-left: 13%;
				.input__row {
					width: 100%;
					// margin-left: 3%;
				}
			}
			&__container {
				@include container-default(
					$flex-direction: column,
					$justify-content: space-between,
					$align-items: stretch
				);

				// margin-top: 4rem;
				margin-left: 8rem;
				width: 70%;
				&.buttons-container {
					display: flex;
					align-items: center;
					flex-direction: row;
					justify-content: center;
					margin: unset;
					.button-container {
						margin-left: 5rem;
						&--button {
							margin-top: auto;
						}
					}
				}
				&.ad {
					flex-direction: row;
					margin-top: 3rem;
					margin-bottom: 4rem;
					justify-content: space-around;
				}
				&.ad-details {
					margin-top: 3rem;
					margin-bottom: 2rem;
					justify-content: space-between;
					flex-wrap: wrap;
				}
				&.ad-creative {
					margin-top: 3rem;
				}
				&--item {
					&.input-holder {
						.input__container {
							.input__error {
								display: none;
							}
						}
					}
					// margin-top: 1rem;
					margin-bottom: 1rem;
					@include container-default(
						$flex-direction: row,
						$justify-content: space-between,
						$align-items: center
					);
					&.button {
						flex-basis: 50%;
						.button-container {
							width: 15rem;
						}
					}
					&__input-container {
						@include container-default(
							$flex-direction: row,
							$justify-content: flex-start,
							$align-items: center
						);
						width: 100%;
						&__icon {
							.MuiSvgIcon-root {
								font-size: 1.8rem;
								margin-top: 3rem;
								cursor: pointer;
							}
							.MuiSvgIcon-root:first-child {
								margin-right: 1rem;
							}
							&.disable {
								.MuiSvgIcon-root {
									background-color: $light-gray;
									color: $gray;
								}
							}
							&.selector-icon {
								.MuiSvgIcon-root {
									margin-top: 0rem;
								}
							}
						}
						&__selector {
							flex: 1;
							margin-right: 2rem;
						}

						&.edit-mode {
							border: 1px solid black;
							border-radius: 1rem;
							padding: 1rem;
						}
					}
					&.facebook {
						margin-top: 0;
						margin-bottom: 0;
					}
					&.facebook-ad-creative {
						flex-direction: column;
					}
					&.keywords {
						display: block;
					}
					&.dropdown-holder {
						width: 30rem;
						flex-direction: column;
						flex-basis: 60%;
						&.status {
							flex-basis: 30%;
							margin-right: 10%;
						}
						&.bidding-strategy {
							margin-right: 5%;
						}
						&.ad-group-status {
							.ad-group-status-select {
								width: 50%;
								margin-left: 9%;
							}
						}
					}
					p {
						font-size: 1.4rem;
					}
					&.value {
						flex-basis: 20%;
						font-weight: 500;
					}
					&.key {
						flex-basis: 34%;
						word-wrap: break-word;
						&.ad-creative {
							font-weight: bold;
							font-size: 1.6rem;
							margin-left: 0.6rem;
							margin-right: 6rem;
						}
					}
					&.keyword {
						color: $blue;
					}
					&.description {
						@include container-default(
							$flex-direction: column,
							$align-items: flex-start
						);
						p {
							margin-top: 0.5rem;
						}
					}

					&.ad {
						flex-direction: column;
						justify-content: space-around;
						align-items: center;
						flex-wrap: wrap;
						margin: 0 0.5rem;
						p {
							font-size: 1.2rem;
							text-align: center;
							margin-bottom: 0.1rem;
						}
					}
					&.ad-detail {
						flex-direction: column;
						justify-content: flex-start;

						p {
							font-size: 1.2rem;
							margin-bottom: 0.25rem;
							word-break: break-all;
						}
						margin-bottom: 2rem;
					}
					&.httphelper {
						text-align: center;
						margin-top: auto;
						font-size: 0.9rem !important;
						margin-bottom: auto !important;
					}
					&.bold {
						font-weight: bold;
					}
					&.ad-name-edit {
						align-items: center;
						margin-right: 3rem;
					}
				}
			}
		}
	}
}
