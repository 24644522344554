.privacy-policy {
	&.login-screen {
		text-align: center;
		margin-bottom: 4rem;
		a {
			color: $turqoise;
		}
	}
	&.sidenav {
		margin-top: 1rem;
		text-align: center;
		a {
			font-size: 1.2rem;
			color: $dark-text;
		}
	}
}
