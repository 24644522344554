.rank-predictor {
	height: 100%;
	width: 100%;
	&__content {
		height: 95vh;
		// background-color: $background-darker-gray;
		padding: 2rem 1rem;
		overflow-y: scroll;
		&__container {
			@include container-default(
				$align-items: flex-start,
				$flex-direction: column,
				$justify-content: space-around
			);
			flex: 1;
			background-color: $white;
			padding: 2rem;
			@include box-shadow-default();
			&__predictions {
				h2 {
					@include divider($thickness: 2px);
					margin-bottom: 2rem;
				}
			}
			&__prediction-item {
				@include container-default(
					$flex-direction: row,
					$align-items: center,
					$justify-content: space-between
				);
			}
		}
	}
}
