.report-component-selector {
	&__container {
		padding: 1rem;
	}
	&__items {
		margin-top: 1rem;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1rem;
	}
}
