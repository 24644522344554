.profile-page {
	height: 100%;
	width: 100%;
	padding: 3rem;
	background-color: $background-gray;
	max-height: 90vh;
	overflow-y: scroll;
	.file-check {
		display: flex;
		flex-direction: row;
		&_first-item {
			flex: 2;
			padding: 1rem;
			font-weight: 300;
		}
		&_second-item,
		&_other-item {
			flex: 1;
			padding: 1.25rem;
			font-weight: 300;
		}
		.button-container {
			margin: 0.5rem 2rem;
			&_download-file-button,
			&_upload-new-file-button,
			&_create-finetune-job-button {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				border-radius: 6px;
				height: 5rem;
				margin: 0.5rem 2rem 0.5rem 2rem;
			}
		}
	}
	.title {
		margin: 2rem 0 1rem 1rem;
		@include container-default(
			$flex-direction: row,
			$align-items: center,
			$justify-content: space-between
		);
		&.download-container {
			margin-left: auto;
		}
	}
	.save-button {
		height: 5rem;
		margin-left: 1rem;
		.button-container {
			margin: 0rem;
			width: 35rem;
		}
	}
	&__modal {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		flex: 1 1;
		min-height: 10rem;
		&__content {
			width: 60vw;
		}
	}
	h3,
	h5,
	p {
		font-family: $lato;
		text-transform: capitalize;
		color: $black;
	}
	&__input-panel {
		padding: 1rem 3rem;
		margin-bottom: 3rem;
		background-color: $white;
		border-radius: $small-border;
		.title-for-finetune-box {
			margin: 2rem 0 1rem 1rem;
			display: flex;
			flex-direction: row;
		}
		.horizontal-inputs-alone {
			.button-container {
				margin: 0 !important;
			}
			display: flex;
			flex-direction: column;
			max-height: 17rem;
			min-height: 12rem;
			margin-left: 1rem;
			width: 50%;
			margin-bottom: 2rem;
			.input-label-container {
				margin: 1rem 0 1rem 1rem;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				.input__container {
					width: auto;
					margin: unset;
				}
			}
			.text {
				align-items: center;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				margin-left: 2rem;
				p:last-child {
					color: $dark-text;
					margin-left: 0.5rem;
					text-transform: none;
				}
			}
			.textarea {
				border: 1px solid #e6e8ea;
				border-radius: 10px;
				height: 13rem;
				padding: 0.5rem;
				resize: none;
				width: 100%;
			}
		}
		.horizontal-inputs {
			&__phone-number {
				margin-right: 0;
				margin: 1rem;
				flex-basis: 25%;
				.PhoneInput {
					height: 4.2rem;
					input {
						border: 0.125rem solid rgba(0, 25, 116, 0.19);
						border-radius: $small-border;
						font-size: 1.6rem;
						color: $dark-blue;
					}
				}
				.PhoneInputInput {
					height: 4.2rem;
					border-radius: 8px;
					padding: 1.5rem;
					font-size: 2rem;
					font-weight: 400;
					color: $grey;
				}
				.PhoneInputCountrySelectArrow {
					color: $black;
				}
				.phone-number-title {
					text-align: start;
					margin-bottom: 0;
					margin: 1rem 1rem 0.75rem 0rem;
				}
				.input__error {
					color: red;
					position: relative;
					font-family: 'Lato', sans-serif;
					font-size: 1.1rem;
					text-align: start;
					font-weight: 400;
					bottom: 0;
					margin-top: 0.5rem;
					margin-bottom: 0;
					height: 2rem;
				}
			}
			.button-container {
				margin: 0 !important;
			}
			display: flex;
			height: 12rem;
			border-bottom: 2px solid $background-gray;
			margin-left: 1rem;
			.input__container {
				flex-basis: 25%;
			}
			.input__container:first-child {
				margin-left: 0;
			}
		}
		.horizontal-inputs:last-child {
			border: none;
		}
		.small {
			width: 50%;
			border-bottom: unset;
			.input__container {
				flex-basis: 50%;
			}
		}
		.row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 5rem;
			width: 100%;
			p {
				margin: 0.2rem 1rem 0 0;
			}
		}
		.ai-engine-selector {
			margin-bottom: 5rem;
			margin-left: 1rem;
			.title {
				text-transform: none;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
			}
			.section {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				width: 100%;
				margin: 2rem 0rem 2rem 0rem;
				&_title {
					flex-basis: 30%;
					margin-top: 1rem;
				}
				&_dropdown {
					width: 100%;
				}
			}
		}
		&__new-user {
			&__inputs-container {
				@include container-default(
					$flex-direction: row,
					$justify-content: flex-start,
					$align-items: flex-start
				);
				&__input {
					.input__container {
						width: unset;
					}
					flex-basis: 25%;
				}
				&__select {
					display: flex;
					flex-direction: column;
					width: 100%;
					flex-basis: 25%;
					min-height: 100%;
					padding: 1rem;
					p {
						margin-top: 1rem;
					}
				}
			}
			&__save-button {
				max-width: 20rem;
				margin-left: auto;
			}
		}
		&__company-users {
			margin: 2rem 2.5% 0;
			&__item {
				@include container-default(
					$flex-direction: row,
					$justify-content: flex-start,
					$align-items: flex-start
				);
				flex-wrap: wrap;
				margin-bottom: 1rem;
				p {
					font-size: 1.6rem;
					font-weight: 400;
					margin-right: 1rem;
					text-transform: none;
					margin-top: 1rem;
				}
				&__row {
					flex-basis: 20%;
					min-width: 15%;
					height: 100%;
					&.email {
						flex-basis: 30%;
					}
					&.last {
						flex-basis: 20%;
						max-width: 20%;
						margin-left: 5%;
					}
				}
			}
		}
	}
}
