.project-invitation {
	background-color: $white;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	&__logo {
		width: 100%;
		margin-top: 2rem;
		margin-bottom: 10rem;
		justify-self: flex-start;
		img {
			width: 90%;
		}
	}
	&__content {
		background-color: $white;
		background: $login-bg-color;
		max-width: 50rem;
		width: 90%;
		padding: 8rem 8rem 2rem 8rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 8px;
		min-width: unset;
		overflow: hidden;
		@include breakpoint(phone) {
			padding: 0 4rem;
		}
		@include breakpoint(small-phone) {
			padding: 0 4rem;
		}
		.modal__content {
			overflow-y: visible !important;

			h3 {
				margin-bottom: 1rem;
			}
		}
		&__error-message {
			padding: 1rem;
			p {
				color: $red;
			}
		}
	}
	.modal__wrapper {
		background-color: $white;
		overflow: visible !important;
	}
	p {
		font-family: $lato;
		color: $white;
		text-align: center;
		margin-bottom: 3rem;
	}
}
