.custom-tooltip {
	&__line {
		display: flex;
		margin-left: 1rem;
		&-label {
			font-weight: $font-bold;
			text-transform: capitalize;
			margin-right: $padding-small;
			&.event {
				font-size: 1.2rem;
				text-transform: none;
			}
		}
	}
}
.pie-charts {
	&__legend {
		font-size: $font-size-small;
		position: relative;
		padding-left: 2rem;
		margin-bottom: 1rem;
		&-container {
			@include container-default(
				$flex-direction: row,
				$justify-content: space-around
			);
			padding-left: unset;
		}
		&-indicator {
			position: absolute;
			height: 1.3rem;
			left: 0.5rem;
			width: 1.3rem;
			border-radius: $medium-border;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&__label {
		font-size: $font-size-small;
		text-shadow: 1px 1px $primary;
	}
}

.recharts-text {
	font-family: $lato;
	font-size: $font-size-small;
	color: $default-text-color;
}
.recharts-responsive-container {
}

.recharts-layer text {
	font-size: $font-size-small;
}
.recharts-layer {
	path {
		stroke: -webkit-linear-gradient(
			left,
			transparent 0%,
			#fff 50%,
			transparent 100%
		);
	}
}
