.register-page {
	@include container-default($justify-content: center, $align-items: center);
	height: 100vh;
	background-color: $background-dashboa-blue;
	&--form {
		@include container-default();
		min-width: 275px;
		max-width: 350px;
		.input__container {
			margin: 1rem;
			display: flex;
			flex-direction: column;
			width: 100%;
			margin-bottom: 1.5rem;
			position: relative;
			border-radius: $small-border;
		}
		&--thank-you {
			margin-top: 5rem;
			p {
				font-size: 2.5rem;
			}
			color: $white;
			white-space: pre-line;
			&--redirect-login {
				margin-top: 7rem;
				p {
					font-size: 2rem;
				}
				span {
					color: lightblue;
					text-decoration: underline;
				}
			}
		}

		&.input__label {
			color: $blue;
		}
		&.button-container {
			position: relative;
			.button-text {
				color: $white;
			}
			&.register-form__submit-button {
				margin: $padding-small 0;
			}
		}
		&__submit-button {
			&--text {
				color: $blue;
			}

			.button-container button {
				background-color: $white;
				.disabled {
					background-color: $dark-gray;
				}
			}
		}

		&__button-bottom-wrapper {
			@include container-default(
				$align-items: center,
				$justify-content: space-around
			);
			margin-top: 2.5rem;
			width: 100%;

			&--facebook-button,
			&--google-button {
				width: 100%;
				position: relative;
				display: flex;
				margin-bottom: $padding-small;
				align-items: center;
				justify-content: center;

				a {
					width: 100%;
					border-radius: $small-border;
					padding: $padding-smaller $padding-medium;
					text-align: center;
					flex-direction: row;
					font-family: $lato;
					font-size: $font-size;
					min-width: 16rem;
					&:hover {
						text-decoration: none;
					}
				}
				.button-icon {
					.MuiSvgIcon-root {
						font-size: 2rem;
					}
					position: absolute;
					z-index: 1;
					left: $padding-medium-small;
					height: $font-size-large;
					width: $font-size-large;
					display: flex;
					align-items: center;
					justify-content: center;
					.fa-facebook {
						color: $white;
						font-size: $font-size-header;

						&::before {
							vertical-align: middle;
						}
					}
				}
			}
			&--facebook-button {
				a {
					background-color: $blue;
					.button-text {
						color: $white;
					}
				}
			}
			&--google-button {
				a {
					background-color: $white;
				}
				.button-text {
					color: $default-text-color;
				}
			}
		}
	}
	&__wrapper-group {
		&-1,
		&-2 {
			margin-bottom: $padding-medium-small;
		}
	}
}
