//
// Mixins
//
/**
 * Breakpoints
 *
 * 1em = 16px
 * phone
 * tab-port
 * tab-land
 * desktop
 * big-desktop
 */
@mixin breakpoint($view) {
	@if ($view == small-phone) {
		@media (max-width: 25em) {
			@content; // 400px
		}
	}

	@if ($view == phone) {
		@media (min-width: 25em) and (max-width: 37.5em) {
			@content; // 400px - 600px
		}
	}

	@if ($view == tab-port) {
		@media (min-width: 37.5em) {
			@content; // 600px
		}
	}

	@if ($view == tab-land) {
		@media (min-width: 56.25em) {
			@content; // 900px
		}
	}

	@if ($view == desktop) {
		@media (min-width: 75em) {
			@content; // 1200px
		}
	}

	@if ($view == desktop-large) {
		@media (min-width: 121em) {
			@content; // 1800px
		}
	}
}

@mixin container-default(
	$flex-direction: column,
	$justify-content: center,
	$align-items: center
) {
	display: flex;
	flex-direction: $flex-direction;
	justify-content: $justify-content;
	align-items: $align-items;
}

@mixin button-container-default(
	$flex-direction: row,
	$justify-content: center,
	$align-items: center
) {
	display: flex;
	flex-direction: $flex-direction;
	justify-content: $justify-content;
	align-items: $align-items;
}

@mixin default-animation() {
	transition-duration: 0.75s;
}

@mixin highlightFixed() {
	@include default-animation();
	z-index: 100000 !important;
	background-color: aliceblue;
	border: 1px solid grey;
	padding: 1rem;
	position: fixed;
}

@mixin highlightNoScaling() {
	z-index: 100000 !important;
	background-color: aliceblue;
	border: 1px solid grey;
	padding: 1rem;
}

@mixin highlight() {
	@include default-animation();
	z-index: 100000 !important;
	background-color: aliceblue;
	border: 1px solid grey;
	padding: 1rem;
	transform: scale(1.5);
}
@mixin highlight-small($background-color: aliceblue) {
	@include default-animation();
	z-index: 100000 !important;
	background-color: $background-color;
	border: 1px dotted rgb(189, 189, 189);
	transform: scale(1.15);
}
@mixin highlight-sidenav() {
	@include default-animation();
	z-index: 100000 !important;
	background-color: aliceblue;
	border: 1px dotted rgb(189, 189, 189);
	padding: 1px;
}

@mixin button-default($background-color: $primary) {
	display: flex;
	background-color: $background-color;
	justify-content: center;
	align-items: center;
	// text-transform: uppercase;
	transition: $transition-default;
	border: 0;
	border-radius: $small-border;
	width: 100%;
	color: $white;
	// box-shadow: 0 0.5rem 1rem rgba($black, 0.15);

	&:hover {
		cursor: pointer;
		opacity: 0.9;
	}
	&:disabled {
		cursor: not-allowed;
		opacity: 1;
	}

	&:hover,
	&:focus {
		box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
	}
}

@mixin box-shadow-default(
	$x: 0,
	$y: 0,
	$blur: 0.5rem,
	$spread: 0.3rem,
	$color: rgba(0, 0, 0, 0.1)
) {
	-webkit-box-shadow: $x $y $blur $spread $color;
	-moz-box-shadow: $x $y $blur $spread $color;
	box-shadow: $x $y $blur $spread $color;
}
@mixin box-shadow-light() {
	box-shadow: 7px -7px 10px -5px rgba(59, 94, 218, 0.07),
		-5px 2px 9px 3px rgba(59, 94, 218, 0.05);
}

@mixin navOnHover {
	position: relative;
	&:hover::before {
		position: absolute;
		height: $border-size;
		width: 100%;
		content: '';
		bottom: -$padding-small;
		background-color: $primary;
	}
	&:hover {
		font-weight: $font-bold;
		text-decoration: none;
	}
}
@mixin report-text-font {
	font-family: $poppins;
	font-style: normal;
}

@mixin date-text {
	@include report-text-font();
	font-weight: 300;
	font-size: 13px;
	line-height: 20px;
	text-align: center;
	color: #b0b3ba;
	padding: 5px;
}

@mixin report-header-text {
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
	color: $black-blue;
	margin-top: 25px;
	margin-bottom: 10px;
}

@mixin modal-table-text {
	font-family: $lato;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	font-size: 1.3rem !important;
}

@mixin mui-box-list {
	.MuiBox-root ul {
		display: flex;
		flex-wrap: wrap;
		padding: 0rem;
	}

	.MuiBox-root li {
		text-align: center;
		padding: 0.5rem;
	}

	.MuiBox-root {
		background-color: $gray-blue;
		padding: 1rem;
		font-weight: '500';
		color: $darker-blue;
		margin: 0px;
		border-radius: $medium-border;
		.MuiTypography-root {
			font-family: $lato;
			font-style: normal;
			font-weight: bold;
			font-size: 1.3rem;
		}
	}
}

@mixin input-sub-text-style {
	position: relative;
	font-family: $lato;
	font-size: $font-size-small;
	text-align: start;
	font-weight: 400;
	bottom: 0;
	// right: $padding-small;
	// padding: $padding-smaller + ($font-size - $font-size-small);
	margin-top: 0.5rem;
	margin-bottom: 0;
	height: 2rem;
}

@mixin suggestion-modal-group-container {
	margin-top: 2rem;
	margin-bottom: 4rem;
	width: 100%;
	border-radius: $medium-border;
}

@mixin nav-style {
	display: flex;
	.tab {
		font-family: $lato;
		font-weight: 400;
		color: #182550;
		margin-right: 3rem;
		min-height: 3rem;
		&.disabled {
			color: #3b5eda59;
		}
	}
	.tab:hover {
		cursor: pointer;
		&.disabled {
			cursor: not-allowed;
		}
	}
	.selected {
		border-bottom: 4px solid $primary;
		border-radius: $tiny-border;
		font-weight: bold;
		color: $primary;
	}
}

@mixin droppable-item-container {
	background-color: $background-darker-gray;
	color: $dark-blue;
	padding: 0.5rem 1.5rem;
	margin: $padding-small 0;
	border-radius: $medium-border;
}

@mixin mui-avatar-style($width: 26px, $height: 24px, $border-radius: 1rem) {
	margin-left: 0rem;
	border: 1px solid #fff;
	border-radius: $border-radius;
	width: $width;
	height: $height;
	color: $black;
	background-color: $background-gray;
}

@mixin divider($width: 98%, $thickness: 0.5px) {
	width: $width;
	border-bottom: $thickness solid $light-gray-border;
}

@mixin bordered-container(
	$padding: 1rem,
	$currentStepPadding: 2rem 1rem 2rem 1rem
) {
	display: flex;
	flex-direction: column;
	background-color: $white;
	border-radius: $medium-border;
	padding: $padding;
	margin-bottom: 2rem;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.04);
	opacity: 0.5;
	//pointer-events: none;
	img {
		margin-left: auto;
		margin-right: 1rem;
		cursor: pointer;
	}
	&.current-step {
		opacity: 1;
		pointer-events: auto;
		padding: $currentStepPadding;
	}
}

@mixin selected-item {
	background-color: #0062e0;
	border: 1.5px solid #19afff;
	color: $white;
}
