.login {
	background: $white;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	&__logo {
		width: 50%;
		margin-top: 8rem;
		margin-bottom: 2rem;
		text-align: center;
		img {
			width: 90%;
		}
	}
	&-form {
		display: flex;
		flex-direction: column;
		.row {
			display: flex;
			justify-content: space-between;
			span {
				font-size: 1.4rem;
			}
			.error {
				color: orange;
			}
			i {
				margin-right: 0.3rem;
			}
		}
		.input__container .input__row .input__border {
			padding: 1.5rem;
			font-size: 2rem;
			height: 4.5rem;
		}
		.input__container .input__row input {
			min-height: 4.2rem;
			height: auto;
		}
		.input__container .input__error {
			font-size: 1.6rem;
		}
		&__wrapper {
			margin-top: 2.5rem;
			.button-container {
				margin-top: 2rem;
				.disabled {
					background-color: gray;
				}
			}
		}
		&__forget-password {
			margin-bottom: $padding-medium-small;
			span {
				color: $turqoise !important;
				font-weight: 600 !important;
			}
		}
		&__create-account {
			margin-top: $padding-medium-small;
			span {
				color: $white;
			}
		}
		&__signup {
			text-align: center;
		}
		&__divider {
			align-items: center;
			color: #435988;
			display: flex;
			font-size: 1.8rem;
			font-weight: 600;
			justify-content: center;
			margin-bottom: 2rem;
			margin-top: 2rem;
			position: relative;
			text-transform: uppercase;
			width: 100%;
			z-index: 0;
			&__text {
				display: inline;
				margin: 0;
				padding-left: 1.5rem;
				padding-right: 1.5rem;
				background: #0d1f60;
				font-size: 2rem;
			}
		}
		&__divider::before {
			border-top: 1px dashed #c8cdd6;
			content: '';
			height: 0.1rem;
			left: 0;
			position: absolute;
			top: 50%;
			width: 100%;
			z-index: -1;
		}
		.facebook-button,
		.google-button {
			position: relative;
			display: flex;
			margin-bottom: $padding-small;
			align-items: center;
			justify-content: center;
			height: 5.5rem;
			background-color: white;
			border-radius: $small-border;
			a {
				width: 100%;

				padding: 1rem $padding-medium;
				flex-direction: row;
				font-family: $lato;
				font-size: $font-size;
				min-width: 16rem;
				&:hover {
					text-decoration: none;
				}
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.button-text {
				color: $white;
				font-size: 2rem;
			}
			.button-icon {
				.MuiSvgIcon-root {
					font-size: 3rem;
					color: $white;
					margin: 0rem 1.5rem;
				}
				border-right: 1px solid white;
				height: 100%;
				display: flex;
				align-items: center;
				// position: absolute;
				// z-index: 1;
				// left: $padding-medium-small;
				// height: $font-size-large;
				// width: $font-size-large;
				// display: flex;
				// align-items: center;
				// justify-content: center;
				// .fa-facebook {
				// 	color: $black;
				// 	font-size: $font-size-header;

				// 	&::before {
				// 		vertical-align: middle;
				// 	}
				// }
			}
		}
		.facebook-button {
			background-color: #1877f2;
		}
		.google-button {
			background-color: #dc3735;
		}

		&__button-bottom-wrapper {
			margin-top: 2rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
		.divider {
			margin: $padding-medium 0;
		}
		.input-label {
			text-align: start;
		}
		.PhoneInput {
			height: 4.5rem;
		}
		.PhoneInputInput {
			height: 100%;
			border-radius: 8px;
			padding: 1.5rem;
			font-size: 2rem;
			font-weight: 400;
			color: $grey;
		}
		.PhoneInputCountrySelectArrow {
			color: $white;
		}
		.phone-number-title {
			text-align: start;
			margin-bottom: 0;
			margin-top: 0.5rem;
		}
		.input__error {
			color: red;
			position: relative;
			font-family: 'Lato', sans-serif;
			font-size: 1.6rem;
			text-align: start;
			font-weight: 400;
			bottom: 0;
			margin-top: 0.5rem;
			margin-bottom: 0;
			height: 2rem;
		}
	}
	&__reset-password {
		background: $login-bg-color;
		width: 100%;
	}
	.modal__wrapper {
		background-color: $white;
		overflow: visible !important;
	}
	span {
		font-family: $lato;
		color: $white;
		//font-weight: 500 !important;
	}
	.input__container {
		margin: 0.5rem 0 0 0;
		input {
			font-family: $lato !important;
		}
	}
	p {
		font-family: $lato;
		color: $white;
		text-align: center;
	}
	h3 {
		font-family: $lato;
		color: $white;
		text-align: center;
		margin-bottom: 2rem;
		margin-top: 4rem;
	}
	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-width: unset;
		@include container-default($flex-direction: row);
		&__register-container {
			background: $login-bg-color;
			flex-basis: 40%;
			height: 100%;
			padding: 1rem;
			@include container-default(
				$flex-direction: column,
				$align-items: center,
				$justify-content: flex-start
			);
			&__sign-up-form {
				width: 55%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				.sign-up-button {
					margin-top: 1rem;
					margin-bottom: 4rem;
					background-color: white;
					width: 100%;
					border-radius: $small-border;
					padding: 1rem $padding-medium;
					text-align: center;
					flex-direction: row;
					font-family: $lato;
					font-size: $font-size;
					min-width: 16rem;
					cursor: pointer;
				}
				.MuiDivider-root {
					background-color: $dark-border;
					width: 100%;
				}
			}
		}
		&__information {
			flex-basis: 60%;
			@include container-default(
				$flex-direction: column,
				$align-items: flex-start
			);
			height: 100%;
			p {
				color: $dark-blue;
				font-size: 2rem;
				font-weight: 400;
				margin-bottom: 0rem;
			}
			.MuiSvgIcon-root {
				margin-right: 1rem;
				color: #1fcbff;
			}
			&__feature {
				padding: 7rem 7rem 0rem 7rem;
				flex-basis: 50%;
				h1 {
					font-size: 6rem;
					max-width: 60rem;
					font-weight: 800;
					margin-bottom: 2rem;
				}
				.MuiGrid-root {
					margin-bottom: 2rem;
				}
			}
			&__testimonial {
				flex-basis: 50%;
				align-self: flex-end;
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				&__wrapper {
					max-width: 60rem;
					max-height: 30rem;
					padding: 3rem;
					background-color: white;
					border-radius: 2rem;
					border: 2px solid #edeff4;
					box-shadow: 1rem -1rem #edeff4, 2rem -2rem #14235266;
					margin-right: 7rem;
					margin-bottom: 7rem;
					display: flex;
					p {
						text-align: start;
					}
					.creditor {
						font-size: 1.6rem;
					}
				}
			}
		}
		@include breakpoint(phone) {
			padding: 0 4rem;
		}
		@include breakpoint(small-phone) {
			padding: 0 4rem;
		}
		.modal__content {
			overflow-y: visible !important;

			h3 {
				margin-bottom: 1rem;
			}
		}
		&__error-message {
			padding: 1rem;
			p {
				color: $red;
			}
		}
	}
	.logo {
		margin: 5rem 0;
		&__signup {
			margin: 5rem 0 2rem 0;
		}
	}
	.button-container {
		@include box-shadow-default();
		margin: 1rem 0 0.5rem 0;
		width: 100%;
		padding: 0;
		button {
			height: 5.5rem;
			border-radius: $small-border;
			background-color: $turqoise;
			div {
				color: $white;
				font-family: $lato;
				font-weight: 600;
				font-size: 2rem;
			}
		}
		.disabled {
			background-color: gray;
		}
	}
	.sign-up-link {
		margin-top: auto;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		a {
			margin-left: 0.5rem;
			color: $turqoise;
		}
	}
}
