.input__container {
	margin: $padding-small;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: $padding-smaller;
	position: relative;
	border-radius: $small-border;
	.input__label {
		color: $default-text-color;
		font-size: $font-size-small;
		margin-bottom: $padding-smaller;
	}
	.input__row {
		display: flex;
		flex-direction: row;
		align-items: center;
		border-radius: $small-border;
		textarea,
		input {
			font-family: $lato;
			font-weight: $font-regular;
			font-size: $font-size;
			width: 100%;
			outline: none;
			padding: $padding-smaller $padding-small;
			border-radius: $tiny-border;
			height: $input-height;
			// Prevented filled value showing on Safari
			// line-height: 45px;

			&:focus {
				list-style-type: none;
				border-color: $light-blue;
				border-style: solid;
				transition: $transition-default;
			}
			&:focus:enabled ~ .input__row--unit {
				@include container-default(
					$flex-direction: row,
					$justify-content: flex-end
				);
				min-width: 2rem;
				margin-left: -5.5rem;
			}
			&:disabled {
				background-color: $light-gray;
			}
		}
		textarea {
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			scrollbar-width: none; /* Firefox */
			resize: vertical;
		}
		textarea::-webkit-scrollbar {
			display: none; /* Chrome */
		}
		input:-webkit-autofill,
		input:-webkit-autofill:focus {
			-webkit-text-fill-color: $primary;
			line-height: 5px;
		}
		input:hover:enabled ~ .input__row--unit {
			@include container-default(
				$flex-direction: row,
				$justify-content: flex-end
			);
			min-width: 2rem;
			margin-left: -5.5rem;
		}
		.input__border {
			border: 0.125rem solid rgba(0, 25, 116, 0.19);
			border-radius: $small-border;
			&.has-error {
				border: 0.125rem solid red;
			}
		}
		.input__no-border {
			border-width: 0;
			&.has-error {
				border: 0.125rem solid red;
				border-radius: $small-border;
			}
		}
		&--search-icon {
			@include container-default();
			margin-left: -25px;
			cursor: pointer;
		}
		&--send-button {
			@include container-default();
			margin-left: -40px;
			//transform: rotate(270deg);
			.Mui-disabled {
				.MuiSvgIcon-root {
					color: $dark-gray;
				}
			}
		}
		&--unit {
			@include container-default(
				$flex-direction: row,
				$justify-content: flex-end
			);
			min-width: 2.5rem;
			margin-left: -4rem;
			transition: all 0.05s ease-in-out;
		}
	}
	.input__error {
		color: $red;
		@include input-sub-text-style();
	}
	.input__valid {
		color: $green;
		@include input-sub-text-style();
	}
}
