.reset-password {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	.button-container {
		margin: $padding-medium 0;
	}
	&__top-element-wrapper {
		.input_container {
			.input_label {
				color: $white;
			}
		}
	}
}
