.data-sources-connection {
	hr {
		width: 95%;
		border: 0.5px solid rgba(3, 64, 121, 0.21);
	}
	.data-connecting-box {
		display: flex;
		padding-right: $padding-small;
		padding-left: $padding-small;
		align-items: center;
		justify-content: space-between;
		&.border {
			border: solid pink 2px;
		}
	}
	.datasource-buttons {
		display: flex;
		p {
			margin-bottom: 0;
		}
		a:hover {
			text-decoration: none;
		}
		.connect-datasource {
			p {
				font-size: 1.6rem;
				font-weight: bold;
				color: $light-orange;
				cursor: pointer;
			}
		}
		.reconnect-datasource {
			margin-right: $padding-medium-small;
			p {
				font-size: 1.6rem;
				color: $light-orange;
			}
		}
		.connected-datasource {
			color: $light-green;
			display: flex;
			align-items: center;
			p {
				font-size: 1.6rem;
				color: $light-green;
			}
		}
		.disconnect-datasource {
			font-size: 1.6rem;
			margin-right: $padding-medium-small;
			cursor: pointer;
			color: $red;
		}
		.save-date {
			cursor: pointer;
		}
	}
}
