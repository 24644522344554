.customize-report {
	height: 80vh;
	width: 100%;
	margin-top: 1rem;
	box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.25);
	.pie-charts__label,
	.pie-charts__legend {
		font-size: 1.6rem;
	}
	&__content {
		@include container-default(
			$flex-direction: row,
			$align-items: flex-start,
			$justify-content: flex-start
		);
		height: 100%;
		width: 100%;
		padding: 3rem;
		overflow-y: scroll;
	}
	&__container {
		min-height: 100%;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}
	&__added-components {
		position: fixed;
		right: calc(25rem + 4rem);
		overflow-y: scroll;
		max-height: 70%;
		min-width: 20rem;
		max-width: 20rem;
		// background-color: white;
	}
	&__component-selector {
		@include box-shadow-default();
		background-color: white;
		min-width: 25rem;
		max-width: 25rem;
		flex: 1;
		overflow-y: scroll;
		max-height: 70%;
		position: fixed;
		right: 30px;

		&__delete-button {
			.button {
				background-color: $red;
			}
		}
	}
}
