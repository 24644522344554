.competitor-analysis {
	padding: 0 2rem;
	&__title {
		font-size: 3rem;
	}
	&__table-container {
		margin-top: 3rem;
		&__table {
			&__headers {
				@include container-default(
					$flex-direction: row,
					$justify-content: flex-start
				);
				width: 100%;
				border-bottom: 2px solid $dark-gray;
				margin-bottom: 2rem;
				:first-child {
					flex-basis: 20%;
					margin-left: 0;
					min-width: 20%;
				}
				&--item {
					font-weight: 500;
					flex-basis: 20%;
					cursor: pointer;
					font-size: 1.5rem;
					color: $gray;
					text-align: center;
				}
			}
		}
	}
}
