.report-page-template {
	height: 100%;
	width: 100%;
	overflow-y: clip;
	&__content {
		max-height: 90vh;
		min-height: 90vh;
		background-color: $background-darker-gray;
		padding: 2rem 2rem;
		overflow-y: scroll;
		&__container {
			@include container-default(
				$align-items: flex-start,
				$flex-direction: column,
				$justify-content: space-around
			);
			&__delete-modal {
				h5 {
					margin-bottom: 2rem;
				}
				&__buttons {
					@include container-default(
						$flex-direction: row,
						$justify-content: space-between
					);
				}
				overflow: unset;
			}
			&--template-inputs,
			&--template-items {
				@include container-default(
					$align-items: flex-start,
					$flex-direction: row,
					$justify-content: space-around
				);
				width: 100%;
				.MuiIconButton-root {
					padding: 6px;
				}
			}
			.button-container {
				margin: 1.5rem;
				.button-text {
					font-size: 1.8rem;
				}
			}
			&--item {
				@include droppable-item-container();
				&--drag {
					background-color: $turqoise;
				}

				&.pointer {
					@include container-default(
						$align-items: center,
						$justify-content: space-around,
						$flex-direction: row
					);
					p {
						color: $dark-blue;
						margin-bottom: 0;
						margin-right: auto;
						width: 100%;
					}
					cursor: pointer;
					&.highlight {
						border: 0.2rem dotted $dark-blue;
					}
				}
			}

			&--selected,
			&--selection {
				@include box-shadow-light();
				background-color: $light-gray;
				flex: 1;
				min-height: 30rem;
				border-radius: $medium-border;
				flex: 1;
				padding: 2rem;
				margin: 1.5rem;
				&__header {
					text-align: start;
					margin-bottom: 2rem;
					color: $dark-blue;
					font-size: $font-size-large;
					&.description {
						font-size: $font-size;
						margin-bottom: 4rem;
					}
					&.archieve {
						@include container-default(
							$flex-direction: row,
							$align-items: center,
							$justify-content: space-between
						);
					}
				}
				&__button {
					max-width: 25rem;
					margin-top: 3rem;
				}
			}
			&__customization {
				width: 100%;
				&__container {
					@include container-default(
						$align-items: flex-start,
						$flex-direction: column,
						$justify-content: space-around
					);
					margin: 1.5rem;
					padding: 2rem 3rem;
					background-color: $white;
					border-radius: $medium-border;
					.input__container {
						margin: 0rem;
						p {
							font-size: $font-size-base;
						}
					}
					h6 {
						margin-bottom: 2rem;
						font-size: 1.8rem;
					}
					&__template-name {
						@include container-default(
							$align-items: flex-start,
							$flex-direction: column,
							$justify-content: space-around
						);
						width: 50%;
						h6 {
							margin-bottom: 1rem;
						}
						&__description {
							margin-bottom: 1.5rem;
						}
						&__input {
							min-width: 100%;
						}
					}
					&__color-picker {
						width: 50%;
						h6 {
							margin-bottom: 3rem;
						}
						&__container {
							@include container-default(
								$align-items: flex-start,
								$flex-direction: row,
								$justify-content: space-between
							);
							min-width: 100%;
							&__item {
								flex-basis: 48%;
								&__picker-container {
									border: $tiniest-border solid $gray-border;
									border-radius: $small-border;
								}
								.MuiFormControl-root {
									padding: 0.3rem;
									margin-left: 0.5rem;
								}
								.ColorPicker-MuiButton-root {
									height: 37px;
									width: 37px;
									margin: 0;
									box-shadow: none;
									border: 1px dotted $dark-yellow;
								}
								.MuiInput-underline:before {
									border-bottom: none;
								}
								.MuiInput-underline:after {
									border-bottom: none;
								}
								.MuiInputBase-input {
									font-family: $lato;
									font-weight: $font-bold;
								}
								p {
									font-size: $font-size-base;
								}
							}
						}
					}
					&__logo {
						@include container-default(
							$align-items: flex-start,
							$flex-direction: row
						);
						h6 {
							margin-bottom: 3rem;
						}
						width: 100%;
						&__link {
							flex-basis: 50%;
						}
						&__preview {
							flex-basis: 50%;
							&__image {
								margin-left: 3rem;
								height: 100%;
								max-height: 120px;
							}
						}
					}
				}
			}
		}
		&__save-automated-button {
			margin-left: auto;
			margin-top: -1rem;
			max-width: 60%;
			margin-bottom: 1rem;
			&--button-text {
				font-size: 1.2rem !important;
				font-weight: 400;
			}
		}
		&__save-button {
			margin-left: 2rem;
		}
		&__star-icon {
			color: $light-orange;
		}
	}
	&__selection {
		min-width: 25rem;
	}
}
