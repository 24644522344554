.project-creation-wizard-page {
	background-color: $background-gray;
	width: 100%;
	@include container-default($flex-direction: column);
	.input__container .input__row {
		height: 4rem;
	}
	.input__container .input__row .input__border {
		border-radius: 4px;
	}
	&__container {
		@include box-shadow-light();
		@include container-default($justify-content: center);
		margin: 2rem 1.5rem;
		padding: $padding-medium-small;
		min-height: 85vh;
		max-height: 85vh;
		width: 50%;
		background-color: $white;
		border-radius: 1rem;
		// background-image: url('../../assets/images/project-creation-wizard.png');
		// background-repeat: no-repeat;
		// background-position: center center;
		&__back-button {
			@include container-default(
				$flex-direction: row,
				$justify-content: flex-start,
				$align-items: center
			);
			width: 100%;
			&--text {
				margin-left: 0.2rem;
				color: $dark-blue;
				font-size: 1.3rem;
				margin-bottom: 0;
			}
			cursor: pointer;
		}
		&__content {
			@include container-default($justify-content: flex-start);
			width: 80%;
			min-height: 300px;
			max-height: 88vh;
			margin-bottom: auto;
			height: 100%;
			// overflow-y: scroll;
			&.overflow-scroll {
				overflow-y: unset;
			}
			&.fullwidth {
				max-width: 100%;
				padding: 0 10%;
			}

			&.iframe-container {
				min-width: 75%;
				height: 100%;
			}

			h6 {
				text-transform: none;
				text-align: center;
				margin-top: 2rem;
				margin-bottom: 2rem;
				font-size: 2.4rem;
			}
			p {
				text-align: center;
				white-space: pre-line;
			}
			&__description-text {
				margin-bottom: 5rem;
			}
			&__divider {
				width: 100%;
				border-bottom: 1px solid $light-gray-border;
				margin: 0.5rem 0;
				&.thin {
					border-bottom: 0.2px solid $light-gray-border;
				}
			}

			&--buttons {
				@include container-default($flex-direction: row, $align-items: center);
				.button-text {
					font-size: 1.3rem;
					font-weight: 500;
					font-family: $poppins;
				}
				.button-container {
					margin: 2.5%;
				}
				.small {
					width: 30%;
				}
				width: 100%;
				margin: 4rem auto 2rem auto;
			}
			&__use-wizard {
				cursor: pointer;
				margin: 2rem;
				p {
					font-size: 1.6rem;
					font-weight: 400;
				}
			}
			&--inputs {
				@extend .project-creation-wizard-page__container__content--buttons;
				width: 60%;
				.column {
					flex-direction: column;
				}
			}
			&--description-details {
				width: 80%;
				p {
					color: $primary;
					text-align: left;
					font-size: 1.2rem;
				}
			}
			&__country-selection {
				@include container-default(
					$flex-direction: column,
					$align-items: center,
					$justify-content: space-around
				);
				margin-top: 3rem;
				width: 100%;

				&--selector {
					width: 80%;
					padding: 7px;
					p {
						text-align: left;
					}
				}
				&--next-button {
					max-width: 140px;
					align-self: flex-end;
					margin-top: auto;
					&.start {
						align-self: flex-start;
					}
					&.center {
						margin: auto auto 0 auto;
					}
				}
				&__language-selector {
					p {
						margin: 2rem 0;
						text-align: left;
					}
				}
			}
			&__project-url {
				@extend .project-creation-wizard-page__container__content__country-selection;
				width: 80%;
				justify-content: space-around;
				p {
					font-size: 1.2rem;
					text-align: left;
					margin-left: 1rem;
				}
				&.initial {
					align-items: flex-start;
					p {
						font-size: 1.6rem;
					}
				}
				&__input {
					width: 100%;
					.input__container {
						width: unset;
					}
				}
			}
			&__suggestion-container {
				@include container-default(
					$flex-direction: row,
					$align-items: flex-start
				);
				flex-wrap: wrap;
				margin: 2.5rem 0;
				&__description,
				&__ranking-pages {
					width: 95%;
					min-width: 300px;
					max-width: 400px;
					&.top-3 {
						background-color: $primary;
						border-radius: $small-border;
					}
					p {
						text-align: left;
					}
				}
				&__ranking-pages {
					padding: 1rem;
					max-width: 80%;
					min-width: 200px;
					margin-right: 3rem;
					margin-bottom: 1rem;
					@media (min-width: 1300px) {
						max-width: 320px;
						height: 100%;
						margin-bottom: 0;
					}
					p {
						color: $white;
						padding-left: 0;
					}
					&__ranking-row {
						@include container-default(
							$flex-direction: row,
							$justify-content: flex-start
						);
						width: 100%;
						p {
							font-size: 1rem;
							margin-bottom: 0;
						}
						&--title {
							font-size: 1.1rem;
						}
						&--own {
							font-weight: bold;
						}

						&--rank {
							display: inline-block;
							background-color: $light-blue;
							color: $white;
							border-radius: 50px;
							margin-right: 1rem;
							min-width: 24px;
							max-height: 24px;
							min-height: 24px;
							text-align: center;
							font-size: 1.2rem;
							margin-bottom: 0;
							padding: 4px 0;
							&.own {
								font-weight: bold;
							}
						}
					}

					li {
						font-size: 1.2rem;
						margin-bottom: 0.25rem;
						color: $white;
					}
				}
				&__description {
					max-width: 80%;
					@media (min-width: 1300px) {
						max-width: 320px;
					}
					li {
						font-size: 1.2rem;
						margin-bottom: 0.25rem;
					}
				}

				&__term-content {
					padding: 0 5% 2.5%;
					min-height: 160px;
					max-height: 160px;
					overflow: hidden;
					position: relative;
					&.closed:before {
						content: '';
						width: 100%;
						height: 100%;
						position: absolute;
						left: 0;
						top: 0;
						background: linear-gradient(transparent 100px, white);
					}
					&.open {
						max-height: unset;
						overflow: unset;
						min-height: unset;
					}
					p {
						font-size: 1.2rem;
						text-align: left;
					}
					h6 {
						font-size: 1.6rem;

						margin-bottom: 0;
					}
					&--view-more {
						@include container-default(
							$flex-direction: row,
							$align-items: center,
							$justify-content: center
						);
						font-size: 1.2rem;
						cursor: pointer;
						margin-bottom: 1rem;
						p {
							font-size: 1.2rem;
							margin-bottom: 0;
							font-weight: 600;
							color: $darker-blue;
						}
					}
				}
			}

			&__datasources {
				margin-top: 2rem;
				width: 100%;
				display: flex;
				flex-direction: column;
				overflow-y: auto;
				&__connect-later {
					font-size: 1.2rem;
					margin-top: 2rem;
				}
				.data-sources-connection .datasource-buttons .connect-datasource p {
					color: $dark-blue;
				}
				&.last-wizard-page {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					&.iframe-inner-container {
						margin-top: 0;
						aspect-ratio: 16/10;
						max-width: 828px;
						max-height: 480px;
						.iframe {
							height: 100%;
							width: 100%;
						}
					}
					&--finish-button {
						max-width: 140px;
						align-self: flex-end;
						margin: 0 auto;
						padding-top: 2rem;
					}
				}

				&__buttons {
					@include container-default(
						$flex-direction: row,
						$align-items: center,
						$justify-content: center
					);
					margin-top: auto;
					.button-container {
						margin: 1.5rem;
						max-width: 100px;
					}
					&.skip {
						margin-top: auto;
					}
				}
				&__value {
					@include container-default($flex-direction: row);
					width: 100%;
					&--container {
						margin: 1rem;
						@include container-default(
							$flex-direction: column,
							$justify-content: flex-start
						);
						width: 30%;
						min-height: 12rem;
						p {
							font-size: 1.2rem;
							text-align: left;
						}
					}
				}
				&.margin-bottom {
					margin-bottom: 3rem;
				}
				&__list {
					@include container-default();
					margin: 0 auto 3rem auto;
					width: 90%;
					max-width: 490px;
					&--item {
						@include container-default(
							$flex-direction: row,
							$justify-content: flex-start
						);
						padding: $padding-medium-small;
						border-bottom: 0.5px solid $light-gray-border;
						width: 100%;
						&--icon {
							height: 2rem;
							margin-right: 2rem;
							margin-left: 2rem;
						}
					}
					&--container {
						@include container-default(
							$flex-direction: row,
							$justify-content: flex-start
						);
						width: 100%;
					}
					&.connected {
						justify-self: flex-start;
						justify-content: flex-start;
						border-bottom: 0.5px solid $light-gray-border;
						padding: $padding-small;
					}
					&--connect,
					&--connected {
						font-weight: bold;
						margin-right: 2rem;
						margin-left: auto;
						margin-bottom: 0;
					}

					&--connect {
						color: $light-orange;
						cursor: pointer;
					}
					&--connected {
						color: $light-green;
					}
				}
				&__footer {
					@include container-default($justify-content: center);
					margin: 2rem auto 4rem auto;
					width: 80%;
					&--button {
						width: 60%;
						margin-bottom: 1rem;
					}
					p {
						font-size: 1.4rem;
						margin-bottom: 0;
					}
				}
			}
			&__datasources-connect {
				max-height: 400px;
				overflow-y: scroll;
				margin-top: 1rem;
				margin-bottom: 3rem;
				width: 100%;
				max-width: 600px;
				p {
					text-align: left;
				}
			}
			&__connect-data-source {
				@include container-default(
					$align-items: center,
					$justify-content: space-between
				);
				padding: $padding-tiny;
				justify-content: space-between;
				border: solid $blue 2px;
				border-radius: $small-border;
				cursor: pointer;
				&--container {
					@include container-default();
					padding: 1.25rem $padding-small;
					flex-direction: row;
					&--icon {
						height: 2rem;
						margin-right: 1rem;
					}
				}
			}
		}

		&__progress {
			border: 1px solid $dark-blue;
			margin: 0.5rem auto 0.5rem auto;
			background-color: rgba(255, 255, 255, 0.59);
			padding: 0.2rem;
			border-radius: $small-border;
			width: 60%;
			&--bar {
				background-color: $dark-blue;
				padding: 0;
				height: 6px;
				border-radius: $small-border;
				margin: 0;
				border: 1px solid $dark-blue;
				max-width: 100%;
			}
		}
	}
}
