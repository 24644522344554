.blog-post-creator {
	width: 100%;
	min-height: 100%;
	//margin-left: 1rem;
	//padding-bottom: 2rem;
	@include container-default($flex-direction: row, $align-items: none);
	.modal__container {
		min-width: 15vw;
		width: 15vw;
		overflow: hidden;
		padding: 1.5rem 2rem;
	}
	&__magic-write {
		@include container-default(
			$flex-direction: column,
			$align-items: center,
			$justify-content: flex-start
		);
		&__title {
			align-self: flex-start;
			@include container-default(
				$flex-direction: row,
				$align-items: center,
				$justify-content: flex-start
			);
			p {
				margin-left: 0rem !important;
				font-size: 1.8rem;
			}
			margin-bottom: 1rem;
		}
		&__divider {
			@include divider($width: 100%);
		}
		&__divider:last-child {
			margin-bottom: 2rem;
		}
		&__option {
			margin: 1rem 0rem;
			color: black;
			font-size: 1.8rem;
			cursor: pointer;
		}
	}
	.input__container {
		width: 98%;
	}
	&.center {
		margin: 0 auto;
	}
	&.column {
		flex-direction: column;
		height: unset;
	}
	&__divider {
		width: 95%;
		background-color: $light-gray-border;
		height: 1px;
		margin: 3rem auto;
	}
	&__button-group {
		@include container-default(
			$flex-direction: row,
			$justify-content: flex-end
		);

		&.copy-buttons {
			margin-left: 0;
		}
	}
	&__clear-button {
		margin-left: auto;
		max-width: 20rem;
	}
	&__content_generation {
		@include container-default(
			$flex-direction: row,
			$justify-content: flex-start
		);
		width: 100%;
	}
	&__button {
		width: 215px;
		&.category-description-button {
			margin: 0.5rem 0;
			display: flex;
			max-width: none;
			justify-content: space-between;
		}
		margin: 0.5rem 0;
		max-width: 200px;
		&.add {
			.button-container {
				margin-bottom: 0;
			}
			align-self: flex-start;
		}
		&.translate-button {
			margin-right: auto;
		}
		&.generate-content-button {
			margin-left: auto;
			margin-right: -1%;
		}
	}
	&__selector {
		margin-left: 2rem;
		min-width: 30rem;
	}
	p {
		margin: 1rem;
	}
	&__keyword {
		flex-basis: 50%;
		padding: 0 3rem;
		@include container-default(
			$align-items: flex-start,
			$justify-content: flex-start
		);
		background-color: #f9faff;
		min-height: 100%;
		h2 {
			font-size: 3rem;
			text-transform: none;
			margin: 1rem;
			font-style: normal;
			font-weight: 700;
			margin-bottom: 2rem;
		}
		textarea::-webkit-scrollbar {
			display: none; /* Chrome */
		}
		&__own-description {
			width: 100%;
			padding: 1rem;
			&.none {
				display: none;
			}
			* > h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p {
				font-family: initial;
				color: black;
				font-weight: initial;
				font-size: initial;
			}
			.quill {
				-ms-overflow-style: none; /* Internet Explorer 10+ */
				scrollbar-width: none; /* Firefox */
			}
			.quill::-webkit-scrollbar {
				display: none; /* Chrome */
			}
		}
		.ql-container.ql-snow {
			height: 20vh;
			max-height: 20vh;
			border: 0.125rem solid rgba(0, 25, 116, 0.19);
			border-radius: $small-border;
		}
		&__description {
			white-space: pre-line;
			font-size: 1.6rem;
			//padding-bottom: 2rem;
			font-weight: 400;
		}
		&__title {
			.MuiIconButton-root {
				background: #f0f0f0;
				padding: 0rem;
				margin: 0rem 1rem 1rem 0rem;
			}
			.MuiSvgIcon-root {
				font-size: 4.5rem;
			}
		}
		&__new-title {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
			margin-bottom: 2rem;
			h2 {
				flex-basis: 65%;
			}
			&__select {
				width: 27rem;
				margin-left: 1rem;
			}
		}
		&__header {
			white-space: pre-line;
			font-style: normal;
			font-weight: 700;
			font-size: 2rem;
			margin: 1rem 1rem 0rem 1rem !important;
		}
		&__advance {
			display: flex;
			justify-content: space-between;
			width: 98%;
			align-items: center;
			p {
				font-weight: 400;
			}
		}
		&__slider-container {
			display: flex;
			justify-content: space-between;
			width: 98%;
			align-items: center;
			p {
				font-weight: 400;
			}
			span {
				color: #1976d2 !important;
			}
			.MuiSlider-valueLabel {
				background-color: c0c0c0 !important;
			}
			.MuiSlider-valueLabelLabel {
				color: white !important;
			}
			.MuiSlider-root {
				color: #1976d2 !important;
				background-color: white !important;
				width: 40rem;
			}
		}
		textarea {
			width: 98%;
			min-height: 15rem;
			border: 1px solid $light-gray-border;
			border-radius: $medium-border;
			padding: 0.5rem;
			resize: none;
			margin: 1rem 1rem;
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			scrollbar-width: none; /* Firefox */
		}
		.input__container .input__row input {
			padding: 2rem 1rem;
		}

		.input__container {
			.keyword-input-label {
				font-weight: 400;
				margin: 0rem 0rem 1rem 0rem;
			}
		}
	}
	&__headlines {
		@include container-default($align-items: stretch);
		width: 100%;
		&__header {
			font-style: normal;
			font-weight: 700;
			font-size: 2.3rem;
			@include container-default(
				$flex-direction: row,
				$align-items: center,
				$justify-content: none
			);
			.MuiButtonBase-root {
				margin-left: auto;
				padding: 0rem;
				.MuiSvgIcon-root {
					font-size: 2.5rem;
				}
			}
		}
		&__input {
			@include container-default(
				$flex-direction: row,
				$justify-content: flex-start
			);
			.input__container {
				width: 100%;
			}
			&__two-inputs {
				display: flex;
				flex-direction: row;
				width: 100%;
				&__headers {
					display: flex;
					flex-basis: 50%;
					margin-left: 1rem;
				}
			}
			&.selected {
				.input__container .input__row input {
					border-color: $dark-blue;
				}
			}
		}
		&__icon {
			height: 1em;
			cursor: pointer;
			&__hidden {
				visibility: hidden;
			}
			&.two-icons {
				margin-left: 1rem;
			}
		}
	}
	&__generate-content {
		background-color: $white;
		border-radius: $medium-border;
		padding: 2rem 2rem 0.1rem 2rem;
		margin-bottom: 2rem;
		&__selectors {
			@include container-default(
				$flex-direction: row,
				$justify-content: space-between,
				$align-items: flex-end
			);
			margin: 1rem;
			min-width: 300px;
			flex-wrap: wrap;
			&__select-with-search-holder {
				display: flex;
				&-item {
					flex-basis: 100%;
					padding-bottom: 0.5rem;
					p {
						font-size: 1.2rem;
						margin: 0 0 0.5rem 0;
					}
					&.keyword {
						flex-basis: 25%;
						padding-bottom: 0;
					}
				}
			}
		}

		&.button {
			width: fit-content;
			padding: 1rem;
		}

		&--title {
			font-size: 2rem;
			font-weight: bold;
		}
		&--description {
			p {
				font-size: 1.2rem;
			}
		}
	}
	&__content {
		background-color: #fff;
		min-height: 100%;
		border-top-right-radius: 1.4rem;
		border-bottom-right-radius: 1.4rem;
		&.ads-content-creator {
			margin-top: 17rem;
			margin-left: 2rem;
		}
		//padding: 1rem;
		flex-basis: 50%;
		margin-bottom: 2rem;
		h2,
		h4 {
			text-transform: none;
			font-size: 2.3rem;
			margin: 0 1rem;
		}
		h2 {
			margin-bottom: 2rem;
		}

		&__text {
			white-space: break-spaces;
			p {
				margin: 0rem 1rem 3rem 1rem;
			}
		}
		&__article-list {
			margin: 1.3rem 0rem 1rem 0rem;
			display: flex;
			flex-direction: row;
			&__selector {
				display: flex;
				flex-direction: row;
				flex-basis: 80%;
				padding: 0rem 1rem;
			}
			&__status {
				flex-basis: 20%;
				display: flex;
				flex-direction: row;
				align-items: center;
			}
		}
		&__editor {
			&.none {
				display: none;
			}
			* > h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p {
				font-family: initial;
				color: black;
				font-weight: initial;
				font-size: initial;
			}
			height: 81vh;
			.quill.quill {
				.ql-container.ql-snow {
					.ql-editor {
						height: 90%;
						max-height: 76vh;
						overflow-y: scroll;
						min-height: 70vh;
					}
				}
			}
			&__quill-with-dropdown {
				height: 75vh;
				.quill.quill {
					.ql-container.ql-snow {
						.ql-editor {
							height: 90%;
							max-height: 70vh;
							overflow-y: scroll;
							min-height: 70vh;
							p {
								font-family: initial;
								color: black;
								font-weight: initial;
								font-size: initial;
							}
						}
					}
				}
			}
		}
		&__quill {
			height: 90%;
			max-height: 75vh;
			overflow-y: scroll;
			min-height: 70vh;
		}
		.ql-toolbar.ql-snow {
			//border-radius: 1rem 1rem 0rem 0rem;
			border: none;
			border-bottom: 1px solid #ccc;
			height: 5.1rem;
		}
		.ql-toolbar.ql-snow + .ql-container.ql-snow {
			border: none;
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			scrollbar-width: none; /* Firefox */
		}
		.ql-container.ql-snow::-webkit-scrollbar {
			display: none; /* Chrome */
		}
		.highlighted-pop-up {
			.button-container {
				margin: 0;
				button {
					background-color: $background-darker-gray;
				}
				.button-text {
					display: flex;
					align-items: center;
					justify-content: center;
					color: $dark-blue;
					img {
						margin-left: 1rem;
					}
				}
			}
		}
		.post-scheduler-page__content__post-creation__ai-content__item {
			position: relative;
			margin-bottom: 2rem;
		}
		.post-scheduler-page__content__post-creation__ai-content__item textarea {
			border-radius: 1rem;
			min-height: 13rem;
		}
		.post-scheduler-page__content__post-creation__ai-content__button {
			justify-content: flex-end;
		}
		.post-scheduler-page__content__post-creation__ai-content__button
			.button-container:first-child {
			width: unset;
			.button-text {
				font-size: 1.4rem;
			}
			.MuiSvgIcon-root {
				font-size: 1.4rem;
			}
		}
		.post-scheduler-page__content__post-creation__ai-content__button {
			position: absolute;
			margin: 0rem;
			bottom: 2rem;
			right: 2rem;
			width: 100%;
			cursor: pointer;
		}

		.competition-analysis {
			padding: 20px;
			background-color: #fff;
			border-radius: 1.4rem;

			&__headers {
				.button-container {
					margin: unset;
				}
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: unset;
				padding-bottom: 3.5rem;
			}
			&__table {
				h3 {
					font-size: 2rem;
					margin-bottom: 2rem;
				}
				.custom-table {
					&__headers {
						justify-content: space-between;
						margin-bottom: 1rem;
						&--item {
							flex-basis: 10%;
							margin-left: unset;
						}
						> :first-child {
							flex-basis: 20%;
						}
						> :last-child {
							flex-basis: 10%;
							margin-left: unset;
							display: flex;
							justify-content: flex-end;
						}
					}
					&__keyword-table {
						&__row {
							&--headers {
								justify-content: space-between;
								&--item {
									flex-basis: 10%;
									margin-left: unset;
									&.keyword {
										margin-left: unset;
										flex-basis: 20%;
									}
									&.create-article-button {
										flex-basis: 10%;
										margin-left: unset;
										display: flex;
										justify-content: flex-end;
										align-items: stretch;
										flex-direction: row;
									}
								}
							}
							&--divider {
								width: 100%;
							}
						}
					}
				}
			}
			&__reviews {
				h3 {
					font-size: 2rem;
					margin-bottom: 2rem;
					margin-top: 4rem;
				}
				p {
					font-size: 1.7rem;
					font-weight: 400;
				}
			}
		}
	}
	&__overview {
		@include container-default(
			$flex-direction: row,
			$justify-content: flex-start
		);
		align-items: center;
		margin: 1rem;
		width: 100%;
		&__title {
			font-weight: 400;
		}
		&__content {
			// font-weight: 400;
			// font-size: 1.8rem;
			flex-basis: 75%;
			border-radius: 6px;
			//padding: 1rem 1rem 1.5rem 1rem;
			.ql-container.ql-snow {
				height: 10vh;
			}
			.input__container {
				margin: 0rem;
				width: 100%;
				.input__row input {
					padding: 2rem 1rem 2.5rem 1rem;
					font-weight: 400;
					font-size: 1.8rem;
					background-color: $light-white-gray;
				}
				.input__row input:focus {
					border-color: rgba(0, 25, 116, 0.19);
					border-style: solid;
				}
				// .input__row {
				// 	.input__border {
				// 		border: 0.125rem solid white;
				// 	}
				// }
			}
		}
		img {
			width: 1.6rem;
			height: 1.6rem;
		}
		&__icon {
			cursor: pointer;
			margin-left: 1rem;
			flex-basis: 5%;
			&.plus-icon {
				margin-right: auto;
				display: flex;
				img {
					width: 3rem;
					height: 3rem;
					position: relative;
					top: 0.2rem;
				}
			}
		}
	}
	.input__container .input__error {
		display: none;
	}
}
