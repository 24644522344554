.dropdown-with-icon {
	.MuiButtonBase-root {
		background-color: $white;
		color: $black;
		font-weight: bold;
		font-size: 1.5rem;
		text-transform: none;
		span {
			color: $black;
			font-size: 1.2rem;
		}
	}
	&__text {
		text-transform: none;
	}
	.MuiSvgIcon-root {
		color: $black;
	}
}

.hidden-date-picker {
	display: none;
}

.not-selectable {
	pointer-events: none;
}
