//
// Typography
//

:root {
	font-size: 62.5%; // 1rem = 10px // 10px/16px = 62.5%
	// <400px
	@include breakpoint(small-phone) {
		font-size: 50%; //1 rem = 8px; 8px/16px = 50%
	}
	// ~ 400 - 600px
	@include breakpoint(phone) {
		font-size: 50%; //1 rem = 8px; 8px/16px = 50%
	}
	// >600px
	@include breakpoint(tab-port) {
		font-size: 56.25%; //1 rem = 9px; 9px/16px = 56.25%
	}
	// > 900px
	@include breakpoint(tab-land) {
		font-size: 58.75%; //1 rem = 11px; 10.2px/16px = 63.75%
		// font-size: 0.67vw; //1 rem = 11px; 12px/16px = 75%
	}
	// > 1800px
	@include breakpoint(desktop) {
		font-size: 60%; //1 rem = 11px; 12px/16px = 75%
	}
	// @include breakpoint(desktop-large) {
	// 	font-size: 80%; //1 rem = 11px; 12px/16px = 75%
	// }
}

* {
	box-sizing: border-box;
}

// Make scrolling behaviors smooth on mobile devices
body {
	-webkit-overflow-scrolling: touch;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
span,
ul,
li,
input,
label,
button {
	font-family: $lato;
	font-weight: bold;
	margin: 0;
	display: block;
	color: $default-text-color;
	line-height: 1.25em;
}

// Force our app to have default font size
// So that UI library will not override it
div,
p,
span,
input,
textarea,
label,
ul {
	font-size: $font-size;
	outline: none;
}

// MaterialUI Icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

i {
	cursor: pointer;
}

// General error message
.error {
	color: $red;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	letter-spacing: 0rem;
	// text-transform: uppercase;
	line-height: 1.25em;
	font-family: $lato;
	color: $primary;
	font-weight: bold;
}
h1 {
	font-size: 2.5 * $default-size;
}
h2 {
	font-size: 2 * $default-size;
}
h3 {
	font-size: 1.75 * $default-size;
}
h4 {
	font-size: 1.5 * $default-size;
}
h5 {
	font-size: 1.25 * $default-size;
}
h6 {
	font-size: $default-size;
}

p,
span,
a {
	letter-spacing: $letter-space-min;
}

p {
	margin-bottom: 1rem;
}

a {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
		text-underline-position: under;
	}
}

.fas,
.far,
.fal {
	&::before {
		vertical-align: middle;
	}
}
#app {
	background-color: $background-gray;
}
