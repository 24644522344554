.yes-no-buttons {
	@include container-default(
		$justify-content: center,
		$align-items: center,
		$flex-direction: row
	);
	// width: 100%;
	margin-top: 3rem;
	p {
		margin-bottom: 0;
	}

	.Mui-selected {
		background-color: $dark-blue;
		color: $white;
	}

	&--selected {
		border: 3px $turqoise solid;
		border-radius: $medium-border;
	}
	// &--not-selected {
	// 	border: 2px $light-gray solid;
	// 	border-radius: $medium-border;
	// 	.button-container,
	// 	button {
	// 		background-color: $gray;
	// 		.button-text {
	// 			color: $white;
	// 		}
	// 	}
	// }
}
