.assignee-list-avatar {
	.MuiAvatar-root {
		@include mui-avatar-style();
		margin-right: 0.5rem;
	}
}

.assignee-list-avatar:hover + .assignee-list-remove-button,
.assignee-list-remove-button:hover {
	display: inline-block;
}

.assignee-list-remove-button {
	position: relative;
	top: -15px;
	right: 20px;
	display: none;
	margin-right: -30px;
}
