.customize-report-basic-details {
	width: 100%;
	background-color: $white;
	// max-height: 8rem;
	padding: 1rem;
	p {
		font-size: 1.4rem;
		margin: 0;
	}
	h4 {
		font-size: 2rem;
	}
	@include box-shadow-default($blur: 0.1rem, $spread: 0.1rem);
	&__description {
		@include container-default(
			$flex-direction: row,
			$align-items: center,
			$justify-content: space-between
		);
		flex: 1;
		&__buttons {
			@include container-default($flex-direction: row);
			// margin-left: auto;
			max-width: 40%;
			.button-container {
				padding: 0;
				margin: 0.5rem !important;
				.button-text {
					padding: 0.5rem;
					min-width: 10rem;
				}
			}
		}
	}

	&__container {
		@include container-default(
			$flex-direction: row,
			$align-items: flex-end,
			$justify-content: flex-start
		);
		&.open {
			transition: width 0.5s ease-out;
		}
		&.closed {
			height: 0;
			transition: width 0.5s ease-in;
		}
		flex-wrap: nowrap;
		&__name {
			flex-basis: 20%;
			margin-right: 2rem;
		}
		&__colors {
			flex-basis: 40%;

			&__container {
				margin: 0 1rem;
				@include container-default($flex-direction: row);
			}
		}
		&__url-input {
			margin: 0 0rem;
		}
		&__logo {
			max-width: 200px;
			max-height: 60px;
			&__image {
				margin-left: 3rem;
				height: 100%;
				max-height: 120px;
				max-width: 200px;
				max-height: 60px;
			}
		}
		&__page-selection {
			margin: 0 2rem 0.5rem;
			@include container-default($flex-direction: row);
		}
		&__layout-selection {
			min-width: 15rem;
		}
		&__hide-button {
			margin-left: auto;
			transform: rotate(-90deg);
			position: absolute;
			border-radius: 50%;
			background-color: $white;
			border: 0.5px solid $green;
			right: 6rem;
			.MuiIconButton-root {
				padding: 1px;
			}
		}
	}
}
