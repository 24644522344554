.text-black {
	white-space: break-spaces;
}
.report {
	height: auto;
	h1 {
		font-size: 2.5 * $report-page-size;
	}
	h2 {
		font-size: 2 * $report-page-size;
	}
	h3 {
		font-size: 1.75 * $report-page-size;
	}
	h4 {
		font-size: 1.5 * $report-page-size;
	}
	h5 {
		font-size: 1.25 * $report-page-size;
	}
	h6 {
		font-weight: $font-bold;
		font-size: $report-page-size * 0.6;
	}
	h7 {
		font-weight: $font-bold;
		font-size: $report-page-size * 0.6;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $report-primary-color;
		// font-weight: $font-normal;
	}

	p {
		font-size: 2rem;
	}
	// h1,
	// h2,
	// h3,
	// h4,
	// h5,
	// h6,
	// h7,
	// p,
	// span {
	// 	font-family: $lato;
	// 	letter-spacing: $letter-space-medium;
	// 	font-size: $report-page-size;
	// }
	&__wrapper {
		//width: $report-width;
		background-color: $white;
		position: relative;
		z-index: 10000;
	}
	&__loading-wrapper {
		height: 100%;
		width: 100%;
		position: fixed;
		background-color: rgba($white, 0.7);
		z-index: 1;
		overflow-y: hidden;
		cursor: none;
		.loading-circle-container {
			position: absolute;
			transform: translate(-50%, -50%);
			top: 50%;
			right: 50%;
		}
	}
	&__body {
		--report-primary-color: #0094ff;
		--report-secondary-color: #ffffff;
	}
	&__page {
		width: 210mm;
		height: 337mm;
		display: flex;
		flex-direction: column;
		background: $white;
		padding: 20px 38px;
		//border-bottom: $border-size solid $report-primary-color;
		&.suggestion {
			.report__page-body {
				margin: $padding-medium;
				font-size: $font-size-medium;
				margin-right: 2rem;
			}
		}
		h5 {
			&.report__page-header span {
				font-size: 1.25 * $report-page-size;
				color: $white;
			}
		}
		h6 {
			padding-top: $padding-large;
			font-size: 1 * $report-page-size;
			font-weight: 500;
			color: $report-primary-color;
		}
		.report__page-header-wrapper {
			display: flex;
			//justify-content: space-between;
			//margin-right: 2rem;
			padding-bottom: 20px;
			border-bottom: 1px solid $light-gray-border;
			.report__page-header {
				padding: $padding-large $padding-medium;
				background-color: $report-primary-color;
				width: 75%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 500;
				line-height: 1.25 * $report-page-size;
				height: $report-height/9;
				span {
					margin-right: 1rem;
				}
				&-text {
					@include report-text-font();
					font-weight: 600;
					font-size: 13px;
					line-height: 20px;
					color: #414141;
					border-right: 1px solid $light-gray-border;
					height: 30px;
					text-align: center;
					padding: 5px 15px 5px 0px;
				}
				&-edit-text {
					@include report-text-font();
					font-weight: 600;
					font-size: 13px;
					line-height: 20px;
					color: #414141;
				}
				&-date {
					@include report-text-font();
					font-weight: 300;
					font-size: 13px;
					line-height: 20px;
					text-align: center;
					color: #b0b3ba;
					padding: 5px 0px 5px 15px;
				}
				&-logo {
					height: 25px;
					display: block;
					margin-left: auto;
				}
			}
			img {
				width: 20%;
				object-fit: scale-down;
			}
		}

		&-header {
			max-height: 125px;
		}

		&__sales-prospect {
			@include container-default();
			margin: 0 5%;
			&.small-margin {
				margin: 0 2.5%;
			}
			&-header {
				max-height: 125px;
			}
			&--page-footer {
				max-height: 150px;
				margin-top: auto;
				align-self: flex-end;
				padding: $padding-medium;
				img {
					max-height: 50px;
				}
			}

			&__main {
				&-title {
					.editable-input {
						margin: 6.5rem;
					}
					margin: 0 0 $padding-large 0;
					&--text {
						color: $black-blue;
						font-size: 1.5 * $report-page-size;
						font-weight: 600;
						text-align: center;
						&.smaller-text {
							font-size: 3.5rem;
						}
					}
				}
				&-date {
					color: #b0b3ba;
					text-align: center;
				}
				&-description {
					margin-bottom: 1rem;
					&--text {
						color: $black-blue;
						margin-top: $padding-large;
						font-size: 0.5 * $report-page-size;
						letter-spacing: -0.03em;
						font-weight: 300;
						text-align: center;
					}

					.editable-textarea {
						height: unset;
						max-height: 150px;
					}
				}
				&--divider {
					height: 5rem;
				}
			}
			&__technical-audit-description {
				margin: 2rem;
				padding: 2rem;
				max-height: 200px;
				border: 1px solid $report-primary-color;
				border-radius: $small-border;
				&--text {
					color: $report-primary-color;
					font-size: 0.5 * $report-page-size;
					text-align: center;
				}
				.editable-textarea {
					height: unset;
					width: 100%;
					max-height: 150px;
				}
			}
			&__issues {
				h4 {
					margin-bottom: 1.5rem;
					color: $black-blue;
				}
				width: 100% !important;
				background-image: url('../../assets/images/world-map.png');
				background-repeat: no-repeat;
				/* background-attachment: fixed !important; */
				background-size: 100% 100% !important;
				h6 {
					color: $report-primary-color;
				}

				@include container-default(
					$align-items: flex-start,
					$justify-content: space-around
				);

				margin-top: 2rem;
				width: 90%;
				&__items--container {
					overflow-x: clip;
				}

				h6 {
					margin-bottom: 3rem;
					font-size: 1.4rem;
				}

				//@include box-shadow-light();
				height: 100%;
				max-height: calc(296mm / 2);
				background-color: $white;
				padding: $padding-medium $padding-medium;
				border-radius: $small-border;
				&.tags {
					justify-content: center;
					align-items: flex-start;
					background-image: none;
					max-height: unset;
					padding: 3rem 1.5rem;
				}
				&__items:nth-child(odd) {
					background-color: $light-gray-background;
				}
				&__items {
					border: 1px solid $light-gray-border !important;
					background-color: $white;
					&--container {
						@include container-default(
							$align-items: flex-start,
							$justify-content: none
						);
						width: 100%;
						overflow: clip;
					}
					@include container-default(
						$align-items: flex-start,
						$justify-content: none
					);
					width: 100%;

					border-bottom: 0.5px solid rgba(3, 64, 121, 0.21);
					// &--divider {
					// 	width: 100%;
					// 	color: rgba(3, 64, 121, 0.21);
					// }
					&__item {
						@include container-default(
							$flex-direction: row,
							$justify-content: flex-start
						);
						width: 100%;
						margin-left: 1rem;

						:first-child {
							width: 30%;
						}
						p {
							font-size: 1.2rem;
							margin-top: $padding-smaller;
							padding-bottom: 0;
						}

						&--number {
							font-weight: 700;
							margin-right: 1.5rem;
							width: 5%;
						}

						&--errors {
							margin-right: auto;
							background-color: #af0000;
							padding: 1rem;
							border-radius: $small-border;
							max-width: 50%;
						}
						&--warnings {
							margin-right: auto;
							background-color: #f5dd00;
							padding: 1rem;
							border-radius: $small-border;
							max-width: 50%;
						}
						&--notices {
							margin-right: auto;
							background-color: #a1dcec;
							padding: 0.5rem;
							border-radius: $small-border;
							border: 2px solid #4287a0;
							max-width: 50%;
						}
					}
				}
			}
			&__suggestions-container {
				@include container-default();
				&__item {
					background-color: $light-gray-background;
					width: 100%;
					padding: 2rem;
					margin: 1.5rem 0;
					border: 1px solid $light-gray-border;
					p {
						// color: $report-primary-color;
						font-size: 1.1rem;
						margin-bottom: 0.5rem;
					}
					&--text {
						margin-left: 0.5rem;
						margin-bottom: 0.5rem;
						padding: 0;
						font-size: 1.1rem;
					}
					&--description-list {
						margin: 1rem;
						&--item {
							display: list-item;
							font-size: 1.2rem;
							color: $black-blue;
							font-weight: 500;
						}
					}
				}
			}
		}

		&-body {
			flex: 1;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			&-description {
				margin: 2.5rem;
				margin-bottom: 6rem;
				font-size: 1.9rem;
				font-weight: 300;
			}
			max-height: $report-height - ($report-height / 7);
			&.financial {
				@include container-default(
					$flex-direction: column,
					$justify-content: space-around
				);
				flex-wrap: nowrap;
			}
			span {
				font-size: 1.5rem;
				white-space: break-spaces;
			}
			&--opening-text {
				.editable-textarea {
					height: unset;
				}
				@include container-default($align-items: flex-start);
				max-height: 30%;
				max-width: 60%;
			}
			&__done-actions {
				&__completed {
					@include container-default(
						$flex-direction: row,
						$justify-content: space-between
					);
					border-radius: $medium-border;
					padding: 0 $padding-small;
					&__description {
						margin-right: 20%;
						flex-basis: 50%;
						align-self: flex-start;
						padding: 1.8rem;
						margin: 1.7rem;
						h2 {
							font-size: 2.5rem;
							text-transform: uppercase;
							margin-bottom: 2rem;
						}
						p {
							font-size: 1.2rem;
							word-break: break-word;
						}
					}
					&__suggestion {
						h2 {
							font-size: 2rem;
							margin-bottom: 2rem;
						}
						flex-basis: 50%;
						border-left: $light-gray-border 1px solid;
						padding: 1.8rem;
						margin: 1.7rem;
						min-height: fit-content;
					}
					&__info {
						p {
							font-size: 1.2rem;
							text-align: center;
						}
						&__container {
							@include container-default(
								$flex-direction: column,
								$justify-content: space-between,
								$align-items: unset
							);
							&__item {
								@include container-default(
									$flex-direction: row,
									$justify-content: space-between
								);
								border-bottom: 1px solid $light-gray-border;
								margin-top: 0.5rem;
								&.first {
									flex-basis: 50%;
									text-align: left;
								}
								&--normal {
									flex-basis: 30%;
									&.green {
										color: $green;
									}
									&.title {
										font-weight: 400;
									}
								}
								&--last {
									flex-basis: 30%;
									text-align: end;
									&.title {
										font-weight: 400;
									}
								}
								&.no-border {
									border-bottom: none;
								}
							}
						}
					}
				}
				&--editable--text {
					span {
						font-size: 1.9rem;
						text-transform: initial;
					}
					font-size: 1.5rem;
					margin: 1.7rem;
					font-weight: 300;
				}
				height: 100%;
				strong,
				&--text,
				&--why-it-was-done,
				&--potential-value {
					font-size: 1.5rem;
				}
				strong,
				&--potential-value {
					font-weight: 500;
				}
				&--text,
				&--what-did-we-do {
					font-weight: 300;
				}
				&--what-did-we-do {
					font-size: 1.9rem;
					margin: 1.7rem;
				}

				&--why-it-was-done,
				&--potential-value {
					margin-top: 1.7rem;
					margin-bottom: 1.7rem;
				}
				&--suggestion {
					padding: 1.8rem;
					background-color: $light-gray-background;
					border: 1px solid $light-gray-border;
					margin: 1.7rem;
					min-height: fit-content;
				}
				// &:first-child {
				// 	margin-top: $padding-extra-large;
				// }
			}
			&-reporting-container {
				&-item {
					flex-basis: 47.5%;
					border: 1px solid $light-gray-border;
					margin-top: 1.5rem;
					margin-bottom: 2.5rem;
					.recharts-legend-item-text {
						font-size: 1.2rem !important;
					}
				}
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
				&-item {
					.recharts-cartesian-axis-tick tspan {
						font-size: 0.8rem;
						font-weight: 600;
					}
					&--chart-title {
						position: absolute;
						// text-transform: capitalize;
						margin: -1.7rem;
						margin-left: 2.5rem;
						background-color: $report-primary-color;
						padding: 0.4rem 1.2rem 0.4rem 1.2rem;
						border-radius: 20px;
						color: $report-secondary-color;
					}
					&--chart-details {
						display: flex;
						justify-content: space-around;
						background-color: $light-gray-background;
						border-top: 1px solid $light-gray-border;
						padding: 0.3rem;
						&-values {
							flex-basis: 45%;
							p {
								margin-bottom: 0rem;
							}
							&-value {
								padding-top: 1rem;
								font-size: 2.1rem;
								text-align: center;
								font-weight: 400;
							}
							&-text {
								font-size: 1.3rem;
								text-align: center;
								color: $black-blue;
								font-weight: 300;
								padding-bottom: 1rem;
							}
						}
					}
				}
				h6 {
					margin-left: 1rem;
				}
				&--container {
					width: 100%;
					overflow: hidden;
				}
				&--table {
					overflow: hidden;
					// max-height: 1000px;
					.MuiTable-root {
						display: flex;
						flex-direction: column;
					}
					.MuiTableCell-head span p {
						@include report-text-font();
						margin-bottom: 0;
						font-weight: $font-bold;
						font-size: 11px;
						line-height: 12px;
						color: $black-blue;
						text-transform: capitalize;
					}
					.MuiTableCell-root {
						border-bottom: none;
					}
					.MuiTableCell-body {
						@include report-text-font();
						font-weight: 500;
						line-height: 10px;
						color: #000000;
						font-size: 10px !important;
					}
					.MuiTableSortLabel-root {
						flex-direction: row;
						align-items: flex-start;
						font-size: 1.2rem;
						p {
							margin-right: $padding-tiny;
						}
					}
					.MuiTableRow-root {
						display: flex;
						align-items: center;
						max-height: 55px;
						border-bottom: 2px solid $report-primary-color;

						.MuiTableCell-root {
							flex: 1;
							text-align: left;
						}
						.MuiTableCell-head {
							flex: 1;
							position: relative;
							display: flex;
							align-items: center;
						}
					}
					.MuiTableSortLabel-icon {
						font-size: $font-size-small;
						position: absolute;
						left: -1.75rem;
						top: 50%;
						transform: translateY(-50%);
					}
					margin-top: $padding-medium-small;
					.MuiTableRow-head {
						border-bottom: none;
					}
					.MuiTableRow-root {
						.MuiTableCell-root {
							flex: 1;
							padding: 10px;
						}
						.MuiTableCell-root:first-child {
							flex: 2.5;
							max-width: 300px;
							overflow-wrap: break-word;
							text-overflow: ellipsis;
						}

						.MuiTableCell-body:first-child {
							word-break: break-word;
						}
					}
					.MuiTableBody-root {
						.MuiTableRow-root {
							// .MuiTableCell-root {
							// 	// padding: 1rem 1.3rem;
							// 	// flex: 2.5;
							// }
							// .MuiTableCell-root {
							// 	font-size: 1rem !important;
							// }
							&:nth-child(odd) {
								background-color: $background-gray;
							}
						}
					}
					&--commons {
						.MuiTableCell-body {
							@include report-text-font();
							font-weight: 500;
							line-height: 10px;
							color: #000000;
							font-size: 10px !important;
						}
						.MuiTableRow-root {
							.MuiTableCell-root {
								padding: 8px;
							}
							.MuiTableCell-root:first-child {
								flex: 1;
							}
						}
					}
				}
			}

			&-section {
				margin-bottom: 3rem;
				&--suggestion-chart {
					max-height: $report-height / 5;
				}
				&:first-child {
					margin-top: $padding-large;
				}
				display: flex;
				justify-content: space-between;
				&-next-month-actions {
					margin-top: 5rem;
					margin-bottom: $padding-medium-large;
					font-size: $padding-medium;
					font-weight: bolder;
					text-transform: uppercase;
					letter-spacing: $letter-space-min;
				}
				&-actions {
					font-size: $padding-medium;
					font-weight: bolder;
					text-transform: uppercase;
					letter-spacing: $letter-space-min;
					width: 100%;
					.recharts-wrapper {
						margin-top: 2rem;
						p {
							margin-left: 2rem;
						}
						max-height: 320px;
					}
				}
				&-content {
					font-size: 0.75 * $report-page-size;
					margin-bottom: $padding-extra-large;
					display: flex;
					justify-content: space-around;
					flex-direction: column;
					// height: 100%;
					max-width: 60%;

					h6 {
						padding-top: 3rem;
					}

					&-subtitle {
						max-width: 60%;
					}

					&.financial {
						width: 100%;
						max-width: 100%;
						margin-bottom: 0;
					}
					h7 {
						color: $black;
					}
					hr {
						border: 2px solid $report-primary-color;
					}
					&-financial {
						&-box {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							p {
								font-size: 2.5rem;
								margin: 1.5rem 1rem;
							}
							&-total {
								p {
									font-size: 2.5rem;
								}
								font-weight: bold;
							}
							&-total-values {
								color: $report-primary-color;
								font-size: 2.5rem;
							}
						}
					}
					&--marketing-details {
						// margin: 2rem;

						&.quota {
							max-width: 50%;
						}

						&__section-title {
							&--container {
								// margin-left: 3rem;
								@include container-default(
									$justify-content: center,
									$align-items: center
								);
								height: 100%;
								h6 {
									font-size: 4rem;
								}
								h4 {
									font-size: 2rem;
									color: $black-blue;
									text-transform: none;
									align-self: flex-start;
									font-weight: 300;
								}
							}
						}
						p {
							margin: 0;
						}
						&--headers {
							@include container-default($flex-direction: row);

							&.quota-header {
								margin-top: -3rem;
							}
						}
						&--first-item {
							flex: 2;
							padding: 1rem;
							font-weight: 300;
							&.quota {
								flex: 4;
							}
						}

						&--second-item,
						&--other-item {
							flex: 1;
							padding: 1.25rem;
							font-weight: 300;
							&.title {
								color: $black-blue;
							}
							&.quota {
								flex: 1;
							}
						}
						&--second-item {
							background-color: $light-gray;
							border: 1px solid $light-gray-border;

							font-weight: 500;

							&.title {
								border: none;
								background-color: unset;
								font-weight: 500;
							}
						}

						&-box {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							border: 1px solid $light-gray-border;
							p {
								font-size: 1.6rem;
							}
						}
					}
				}
			}
			&-chart-group {
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				&-title {
					@include report-text-font();
					@include report-header-text();
					margin-top: 10px;
				}
				&-subtitle {
					max-width: 100%;
				}
				&-chart-wrapper {
					padding: 12px;
					border: 1px solid $light-gray-border;
					box-sizing: border-box;
				}
				&-text-wrapper {
					padding: 12px;
					border: 1px solid $light-gray-border;
					border-top: none;
					box-sizing: border-box;
					@include report-text-font();
					font-weight: 300;
					font-size: 11px;
					color: #797979;
				}
			}
			&-table-title {
				@include report-text-font();
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: $black-blue;
				margin-top: 25px;
			}
			&-conversion {
				display: flex;
				justify-content: space-between;
				gap: 20px;
				&-container {
					display: flex;
					flex-direction: column;
					flex-basis: 100%;
					max-height: 175px;
				}
				&-container:last-child {
					background-color: $light-gray-background;
				}
				&-box {
					display: flex;
					flex-direction: column;
					border: 1px solid $light-gray-border;
					flex-basis: 100%;
					padding: 12px;
					min-height: 140px;
					&.no-min-height {
						min-height: unset;
					}
					&.space-between {
						justify-content: space-between;
					}
					&-title {
						@include report-text-font();
						font-weight: 400;
						font-size: 11px;
						line-height: 14px;
						color: $black-blue;
						margin: 5px 0px 5px 0px;
					}
					&-numbers {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						&-value {
							@include report-text-font();
							font-weight: 400;
							font-size: 21px;
							line-height: 28px;
							color: #797979;
							margin: 5px 0px 10px 0px;
							white-space: nowrap;
						}
						&-difference {
							align-self: center;
							@include report-text-font();
							font-weight: 300;
							font-size: 17px;
							line-height: 22px;
							color: #797979;
						}
					}

					&-percent {
						@include report-text-font();
						font-weight: 300;
						font-size: 12px;
						line-height: 12px;
						color: #747474;
						margin: 5px 0px 5px 0px;
					}
					&-bar-container {
						max-height: 12px;
						background: $light-gray-background;
						border: 2px solid $light-gray-border;
						box-sizing: border-box;
						border-radius: $small-border;
					}
					&-bar {
						padding: 2px;
						box-sizing: border-box;
						border-radius: $small-border;
						max-width: 100%;
						&.minus {
							border-color: #ce2c29;
							background: #d87675;
						}
						&--span {
							text-align: center;
							margin-top: -2.3rem;
							position: relative;
							color: $dark-gray;
							font-size: 1.4rem !important;
						}
					}
				}
				&-description {
					@include report-text-font();
					font-weight: 300;
					font-size: 9px;
					line-height: 10px;
					color: #797979;
					border: 1px solid $light-gray-border;
					border-top: none;
					padding: 12px;
				}
			}
			&-search-term-text {
				@include report-text-font();
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;
				color: $black-blue;
				margin-top: 25px;
			}
			&-marketing-overview {
				&-header {
					@include report-text-font();
					@include report-header-text();
				}
				&-content {
					display: flex;
				}
			}
			&-free-text-page {
				&-header {
					@include report-text-font();
					@include report-header-text();
					font-size: 1.9rem !important;
					font-weight: 300 !important;
				}
				&-content {
					@include report-text-font();
					font-weight: 300;
					font-size: 1.5rem;
					line-height: 20px;
					color: $black-blue;
				}
			}
		}
	}
	&__cover {
		background-color: $white;
		width: 210mm;
		height: 296mm;
		display: flex;
		flex-direction: column;
		background: $white;
		&-wrapper {
			height: 100%;
		}
		&-header {
			// font-size: 2 * $report-page-size;
			// font-weight: 500;
			// color: $report-primary-color;
			// text-transform: none;
			// padding: $padding-large;
			max-width: 100%;
			@include report-text-font();
			font-weight: $font-bold;
			font-size: 37px;
			line-height: 50px;
			letter-spacing: -0.07em;
			color: $black-blue;
			text-align: center;
			margin-top: 160px;
		}
		&-date {
			// font-size: 1.25 * $report-page-size;
			// color: $report-primary-color;
			// font-weight: 400;
			// text-transform: capitalize;
			// padding: 0 $padding-large $padding-extra-large;
			display: flex;
			justify-content: center;
			&__text {
				@include date-text();
				&__vertical-line {
					@include date-text();
					border-right: 1px solid $light-gray-border;
					height: 30px;
					text-transform: capitalize;
				}
			}
		}
		&__image {
			width: 100%;
			margin-top: 110px;
		}
		&__logo {
			height: 25px;
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-top: 19px;
		}
		&__project-name {
			@include report-text-font();
			font-weight: 300;
			font-size: 22px;
			line-height: 27px;
			text-align: center;
			letter-spacing: -0.03em;
			color: $black-blue;
			margin-top: 35px;
		}
	}
}
.blue-bar {
	background: #a1dcec;
	border: 2px solid #4287a0;
}
.grey-bar {
	background: #e2e2e2;
	border: 2px solid #c2c2c2;
}
.yellow-bar {
	background: #fedca4;
	border: 2px solid #efab3c;
}
.green-bar {
	background: #bcec4b;
	border: 2px solid #34a853;
}
@media print {
	.report {
		&__page {
			width: 100%;
			height: 296mm;
			min-height: 29.9cm;

			&-body {
				p {
					font-size: 1.6rem;
				}
			}
		}
	}
}
.report-empty-page {
	width: 210mm;
	&--audit-rows {
		p {
			word-break: break-word;
		}
	}
	h6 {
		text-align: center;
		margin-bottom: 1rem;
	}

	&--main-page-tags {
		padding-left: 0;
		h5 {
			font-size: 2rem;
			text-transform: none;
			margin: 1rem 0;
		}
		li {
			margin-left: 4rem;
			list-style: circle;
			display: list-item;
		}
	}
}
