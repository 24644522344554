.keywords-table {
	th,
	td {
		font-size: $small-font-size;
	}

	.MuiDataGrid-root {
		display: flex;
		min-height: 400px;
		max-height: 600px;
		border: none;
	}
	.MuiDataGrid-columnHeaderTitle {
		color: $dark-gray;
		font-size: 1.1rem;
	}
	.MuiButton-label {
		font-weight: bold;
		color: $dark-blue;
		font-size: 1.2rem;
		text-transform: none;
	}

	.MuiDataGrid-cell {
		font-size: 1.2rem;
		font-weight: 400;
	}
}
