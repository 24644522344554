.sidenav {
	max-height: 100vh;
	.menu-item-holder {
		width: 100%;
	}
	.MuiDivider-root {
		background-color: $dark-border;
		width: 100%;
		margin: 1rem 0;
	}
	button {
		background-color: $blue;
	}
	&.open {
		.MuiPaper-root {
			transition: width 0.5s ease-out;
			width: 28rem;
			padding: 2rem;
		}
	}
	&.closed {
		.MuiPaper-root {
			transition: width 0.5s ease-in;
			width: 7rem;
		}
		.custom-menu-container {
			// margin: 1rem 0;
			margin: unset;
		}
		.custom-menu-container__item {
			justify-content: center;
		}
		.MuiSvgIcon-root {
			width: 70%;
			font-size: 2rem;
			margin-right: 0;
		}
	}
	&--close-switch {
		position: absolute;
		@include box-shadow-default();
		border-radius: 50%;
		button {
			background-color: $primary;
		}
		border: 0.5px solid $white;
		.MuiIconButton-root:hover {
			background-color: $turqoise;
		}
		&.open {
			transition: left 0.5s ease-out;
			left: 26.7rem;
			top: 2rem;
			.MuiIconButton-label {
				transform: rotate(180deg);
				transition: transform 0.5s ease-in-out;
			}
		}
		&.closed {
			transition: left 0.5s ease-in;
			left: 5.75rem;
			top: 2rem;
			.MuiIconButton-label {
				transform: rotate(360deg);
				transition: transform 0.5s ease-in-out;
			}
			.MuiSvgIcon-root {
				width: 1em;
			}
		}
	}
	.MuiAvatar-colorDefault {
		background-color: $dark-yellow;
		font-weight: lighter;
		font-size: 1.2rem;
		color: $primary;
	}

	.MuiDrawer-paper {
		position: unset;
	}
	.MuiPaper-root {
		width: 28rem;
		max-width: 28rem;
		background-image: url('../../assets/images/side-nav-background.svg');
		background-size: cover;
		background-position: bottom;
		background-color: $background-darkblue;
	}
	.MuiListItemText-root {
		span {
			text-align: center;
		}
	}
	.MuiListItem-button svg {
		margin-left: 1.5rem;
	}
	.MuiListItem-button:hover {
		background-color: white;
		span {
			color: #3b5eda;
		}
		svg {
			color: #3b5eda;
		}
	}
	.MuiButton-label {
		color: $black;
		svg {
			color: black;
		}
	}

	.MuiTypography-displayBlock {
		font-size: $big-font-size;
		color: $white;
	}
	.MuiSvgIcon-root {
		color: $white;
	}
	.MuiButtonGroup-contained {
		box-shadow: none;
	}
	.MuiButton-containedSecondary {
		background-color: $white;
	}
	.MuiButton-containedSecondary:hover {
		color: #3b5eda;
	}
	.selected {
		background-color: $white;
		color: $black;
	}
	h5 {
		text-align: center;
		margin: 2rem auto 1rem;
		color: $white;
		font-family: $spacemono;
		font-weight: 700;
		font-size: 4rem;
		font-style: normal;
		line-height: 35px;
		text-transform: none;
	}
	.sidenav-title {
		padding: 1rem;
		padding-left: 0;
		color: $dark-text;
		font-family: $lato;
		font-size: 1.2rem;
		font-weight: 300;
		text-transform: uppercase;
		&.manage {
			margin-top: auto;
		}
	}
	&-logo {
		@include container-default(
			$justify-content: center,
			$align-items: flex-start
		);
		max-width: 270px;
		max-height: 20px;
		margin: 2rem 0;
		img {
			width: 60%;
		}
		&.small {
			padding: 0.7rem;
			margin-bottom: 10rem;
			max-height: 50px;
			align-items: center;
		}
		cursor: pointer;
	}
	.logo-button {
		display: flex;
		p {
			padding-left: 2rem;
			color: $white;
			margin-bottom: 0;
			font-size: 1.6rem;
		}
	}
	.sidenav-project {
		.button-container {
			margin: 2rem 0 1rem;
		}
		width: 100%;
		margin: 0 auto;
		&--max-projects {
			margin-bottom: 0.25;
			font-size: 1rem;
			color: $white;
		}
		&--trial-ends {
			margin-bottom: 0.25;
			font-size: 1.2rem;
			color: $white;
			text-align: center;
		}
	}

	.sidenav-tools {
		&.no-items {
			margin: auto 0;
		}
	}
	.sidenav-settings {
		border-radius: $small-border;
		width: 22.5rem;

		.logout-button {
			margin: 0;
			margin-top: 0.5rem;
			border-radius: $small-border;
			margin-top: 2rem;

			.MuiSvgIcon-root p {
				background-color: $turqoise;
				:hover {
					background-color: #ffffff00;
				}
			}
			:hover {
				background-color: #ffffff00;
			}
			text-decoration: none;
			text-underline-position: unset;
			cursor: pointer;
		}
	}
	.privacy-policy {
		&.sidenav {
			margin-top: 2rem;
			a {
				text-decoration: none;
				cursor: pointer;
			}
			a:hover {
				text-decoration: none;
				cursor: pointer;
			}
			p {
				text-align: center;
				font-size: 1.6rem;
				color: $dark-text;
				font-weight: normal;
			}
		}
	}
}

/*
$header-font-size: 2rem;
$big-font-size: 1.8rem;
$normal-font-size: 1.6rem;
$small-font-size: 1.4rem;
*/
.project-settings-navigator {
	display: flex;
	color: #3b5eda;
	font-size: 1.4rem;
	p {
		color: #3b5eda;
	}
}
