.topnav {
	@include box-shadow-default();
	padding: 1rem 1rem 0rem 5rem;
	width: 100%;
	display: flex;
	background-color: $white;

	&__help-button {
		max-width: 8rem;
		.button-container {
			margin: unset;
			.button-text {
				min-width: unset;
			}
		}
	}
	.right {
		flex: 1;
		&__inner-container {
			@include container-default(
				$align-items: center,
				$justify-content: flex-end,
				$flex-direction: row
			);
			min-width: 100%;
			margin-bottom: 1rem;
			flex-wrap: wrap;
			margin-left: auto;
			min-height: 4rem;
		}
		.topnav-buttons {
			display: flex;
		}
		.button-container {
			margin: 0.5rem 2.5%;
		}
		&__language-selector {
			width: 75px;
			margin-left: 2rem;
			.c-f-o-dropdown-content {
				background-color: $dark-blue;
				border-radius: $small-border;
				p {
					margin: 0;
					color: $white;
					width: 100%;
					&:hover {
						color: $dark-blue;
					}
				}
			}

			.c-f-o-dropdown-btn {
				background-color: $dark-blue;
				border-radius: $small-border;

				padding: 1.25rem;
				p {
					width: 100%;
					font-size: 1.2rem;
					color: $white;
					margin-bottom: 0;
				}
			}
		}
	}

	&--languages {
		padding: 1.25rem;
		font-size: 1.2rem;
		cursor: pointer;
		&:hover {
			background-color: $white;

			color: $dark-blue;
		}
	}
	.left {
		@include container-default(
			$align-items: flex-start,
			$justify-content: space-between
		);
		// flex-basis: 90%;
		.title {
			margin-bottom: 1rem;
			h3 {
				font-family: $lato;
				text-transform: capitalize;
				margin-bottom: 0;
				font-weight: 700;
				color: $primary;
			}
		}
		.nav {
			@include nav-style();
		}
	}
	.button-container {
		// width: 25rem;
		button {
			border-radius: $small-border;
			height: 4rem;
			div {
				font-weight: lighter;
			}
		}
	}
}
