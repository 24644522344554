//
// Navigation stylesheet
//

nav {
	height: $logo-size;
	display: flex;
	align-items: center;
	a {
		@include navOnHover();
		font-family: $lato;
		list-style-type: none;
		text-decoration: none;
		color: $default-text-color;
		margin-right: 10px;
		font-size: $font-size;
	}
	.nav {
		z-index: 3;
		display: flex;
		align-items: center;
		flex: 1;
		justify-content: space-between;
		height: inherit;
		.nav-item {
			@include navOnHover();
			font-family: $lato;
			cursor: pointer;
		}
		&__item {
			width: auto;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			height: inherit;

			&:last-child {
				justify-content: flex-end;
			}
			&.is-active {
				.nav-item {
					font-weight: $font-bold;
					&::before {
						position: absolute;
						height: $border-size;
						width: 100%;
						content: '';
						bottom: -$padding-small;
						background-color: $primary;
					}
				}
			}
		}
		&__project-description {
			flex: 2;
		}
		&__logo {
			width: $logo-size;
			margin-right: $padding-large;
			&-image {
				width: inherit;
			}
		}
		&__project {
			cursor: pointer;
			align-items: unset;
			height: auto;
			flex-direction: column;
			justify-content: center;
			flex: 2.5;
			&.show {
				height: 100%;
				position: relative;
				.nav__project-item-current {
					display: none;
				}
			}
			.project-time {
				font-size: $font-size-small;
			}
			&.hide {
				padding-left: $padding-medium-small;
				.nav__project-items-container {
					display: none;
				}
			}
			&-item {
				&-container {
					background-color: $white;
					padding: $padding-small;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				&.selected {
					font-weight: $font-bold;
				}
				&,
				&-current {
					@include navOnHover();
				}
			}
			&-items-container {
				@include box-shadow-default(0, 0, 5rem, 0.1rem);
				padding: $padding-medium-small;
				width: 100%;
				position: absolute;
				top: 0;
				background-color: $white;
				border-radius: $small-border;
				overflow: hidden;
			}
		}
		&__language-selector,
		&__logout {
			@include navOnHover();
			cursor: pointer;
		}
		&__language-selector {
			&.selected {
				font-weight: $font-bold;
			}
		}
	}
}
