.customize-report-holder {
	background-color: white;
	// width: 1200px;
	// height:900px;
	position: relative;
	&--test {
		position: absolute;
		height: 50px;
		width: 50px;
		background-color: red;
	}
	&__item {
		// border-radius: 5px;
		// border: black solid 2px;
		// padding: 1rem;
		cursor: move;
	}
	&__total-box {
		@include container-default();
		&__total {
			&.positive {
				color: $green;
			}
			&.negative {
				color: $red;
			}
		}
	}
	&__resize-container {
		padding: 1rem;
		border: 0.5px solid $dark-blue;
		z-index: 2;
		&--resizer {
			height: 8px;
			width: 8px;
			background-color: $white;
			border: 0.25px solid $dark-blue;
			border-radius: 50%;
			position: absolute;
			z-index: 2;

			&.top {
				top: -3px;
			}
			&.bottom {
				top: calc(100% - 4px);
			}
			&.right {
				left: calc(100% - 4px);
			}
			&.left {
				left: -3px;
			}
			&.center {
				left: calc(50% - 2px);
			}
			&.middle {
				top: calc(50% - 2px);
			}
			&.resize-cursor-e {
				cursor: e-resize;
			}
			&.resize-cursor-n {
				cursor: n-resize;
			}
			&.resize-cursor-s {
				cursor: s-resize;
			}
			&.resize-cursor-w {
				cursor: w-resize;
			}
			&.resize-cursor-ne {
				cursor: ne-resize;
			}
			&.resize-cursor-nw {
				cursor: nw-resize;
			}
			&.resize-cursor-se {
				cursor: se-resize;
			}
			&.resize-cursor-sw {
				cursor: sw-resize;
			}
		}
		&--move {
			width: 100%;
			height: 100%;
			cursor: move;
			z-index: 2;
		}
	}
}
