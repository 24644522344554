.draggable-task-view {
	&__modal {
		min-width: 50vw;
		padding: 1.5rem 2rem;
	}
	&__container {
		@include container-default(
			$align-items: flex-start,
			$flex-direction: row,
			$justify-content: space-around
		);
		width: 100%;
		&__droppable {
			background-color: $background-gray;
			flex: 1;
			height: 76vh;
			border-radius: $medium-border;
			margin: 0.5rem;
			overflow-x: hidden;
			padding: 0.5rem;

			&__header {
				@extend .report-page-template__content__container--selection__header;
				@include container-default(
					$flex-direction: row,
					$align-items: center,
					$justify-content: flex-start
				);
				margin-bottom: 0rem !important;
				min-height: 4rem;
				p {
					font-size: 1.4rem;
					margin-bottom: 0;
				}
				&--icon-holder {
					margin-right: 1rem;
					margin-bottom: -0.5rem;
				}
				&--ball {
					width: 1.6rem;
					height: 1.6rem;
					min-width: 1.6rem;
					border-radius: $round-border;
					background-color: $black;
					margin: 0 1rem;
					&.yellow {
						background-color: transparent;
						border: 2px solid $yellow;
					}
					&.light-blue {
						background-color: transparent;
						border: 2px solid $light-blue;
					}
					&.green {
						background-color: $green;
					}
					&--half-circle {
						background-color: $light-blue;
						width: 57.5%;
						min-width: 55%;
						margin-left: -5%;
						margin-top: -2.5%;
						height: 105%;
						overflow: hidden;
						border-radius: 50% 0 0 50%;
					}
				}
				&--number {
					margin-left: 2rem;
					font-weight: 400;
				}
				&__icon-container {
					@include container-default($flex-direction: row);
				}
			}
			&__item {
				@include droppable-item-container();
				background-color: $white;
				padding: 1.5rem 1.5rem 0rem 1.5rem;
				p {
					font-size: 1.2rem;
					font-weight: bold;
					word-break: break-word;
					margin-bottom: 0;
				}
				&__title {
					margin-bottom: 0.25rem;
				}
				&__options {
					@include container-default(
						$flex-direction: row,
						$justify-content: flex-start,
						$align-items: center
					);
					width: 100%;
					margin-top: 0.5rem;
					p {
						font-size: 1.2rem;
					}
					&__priority {
						background-color: $background-gray;
						border-radius: $small-border;
						margin-right: 0.75rem;
						padding: 0.2rem 0.5rem;
					}
				}
				&__lower-container {
					@include container-default(
						$flex-direction: row,
						$justify-content: space-between
					);
					padding: 0.75rem 0;
					border-top: 1px solid #e5e5ea;
					margin-top: 0.75rem;
					&__assignees {
						flex-basis: 35%;
						.MuiAvatarGroup-root {
							justify-content: flex-end;
							.MuiAvatar-root {
								@include mui-avatar-style();
							}
						}
					}
					&__date,
					&__comments {
						@include container-default($flex-direction: row);
						flex-basis: 30%;
						background-color: $background-gray;
						border-radius: $small-border;
						padding: 0.5rem;
						.MuiSvgIcon-root {
							color: $dark-black-grey;
						}
					}
					&__date {
						margin-left: auto;
						justify-content: space-between;
						flex-basis: 45%;
						max-width: 12rem;
						background-color: $white;
						.dropdown-with-icon {
							.MuiButtonBase-root {
								background-color: $background-gray;
							}
							.MuiSvgIcon-root {
								color: $black;
								font-size: 14px;
							}
						}
						p {
							font-size: 1.1rem;
							color: $dark-black-grey;
							font-weight: bold;
							margin-bottom: -0.2rem;
						}
					}
					&__comments {
						margin-left: 0.5rem;
						max-width: 4rem;
						flex-basis: 10%;
						justify-content: space-between;

						p {
							color: $dark-gray;
							font-weight: bold;
						}
						&.black {
							flex-basis: 20%;
							p {
								color: $dark-black-grey;
								font-weight: bold;
							}
							.MuiSvgIcon-root {
								color: $dark-black-grey;
							}
						}
					}
				}
			}
			&__archived {
				@include container-default(
					$flex-direction: row,
					$align-items: center,
					$justify-content: space-between
				);
				background-color: $white;
				border-radius: $medium-border;
				padding: 1rem;
				transform: rotate(90deg);
				margin-top: 8rem;
				&.drag-over {
					background-color: #03a9f41c;
				}
				p {
					margin-bottom: 0;
				}
				&--icon {
					transform: rotate(-90deg);
				}
			}
			&__todo {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				&--icon {
					@include container-default($flex-direction: row);
					margin-left: auto;
					background-color: $white;
					border-radius: $small-border;
					padding-left: 1rem;
					cursor: pointer;
					p {
						font-weight: 600;
					}
					.MuiIconButton-root {
						padding: 0.8rem;
					}
				}
				&--ball-and-number {
					display: flex;
					&--ball {
						width: 1.6rem;
						height: 1.6rem;
						border-radius: $round-border;
						background-color: $black;
						margin: 0 1rem;
						&.yellow {
							background-color: transparent;
							border: 2px solid $yellow;
						}
					}
					&--number {
						margin-left: 2rem;
						font-weight: 300;
					}
				}
			}
		}
	}
	&__create-custom-task-modal {
		@include container-default(
			$flex-direction: row,
			$align-items: center,
			$justify-content: center
		);

		&__selectors {
			.MuiInputBase-root {
				background-color: white;
			}
		}
		flex-wrap: wrap;
		p {
			width: 100%;
			margin-bottom: 0;
			font-weight: 600;
			font-size: 2.5rem;
		}
		&--input-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			&--button {
				width: 30%;
				margin-right: auto;
			}
			textarea {
				width: 100%;
				min-height: 10rem;
				border: 0.25rem solid $light-gray-border;
				border-radius: $medium-border;
				padding: 0.5rem;
				resize: none;
				margin: 0 1rem;
				font-family: $lato;
			}
		}
		&--drop-down {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			flex-wrap: wrap;
			width: 100%;
			margin: 1rem 0;
			> p {
				font-size: $small-font-size;
			}
			&--dropdown-menu-with-icon {
				margin: 0.25rem;
				border-radius: $medium-border;
				.MuiButtonBase-root {
					background-color: $light-gray-border;
				}
				text-transform: none;
				margin: 0.25rem;
				border-radius: $medium-border;
				&--avatar {
					.MuiAvatar-root {
						@include mui-avatar-style(
							$width: 22px,
							$height: 20px,
							$border-radius: 0.7rem
						);
						margin-right: 0.5rem;
					}
				}
			}
			&--buttons {
				@include container-default(
					$flex-direction: row,
					$justify-content: space-between
				);
				width: 100%;
				.button-text {
					font-size: $small-font-size;
				}
				.button-blue {
					width: 160px;
					background-color: $turqoise;
				}
				.button-container {
					margin: 1rem;
				}
			}
		}
		&--tracking-selector {
			@include container-default(
				$flex-direction: row,
				$justify-content: flex-start
			);
			width: 100%;
			&--item {
				flex-basis: 50%;
			}
		}
	}
}
